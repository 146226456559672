//
// Author: Lionel D.
// Description: This is an HOC (Higher-Order Component) designed to cross-cut the main component and its subcomponents (children)
// the main idea/purpose is to be able to share code/logic across components. Zero-effect to the wrapped components
//

import React, { Component } from 'react'
import Notifier, { showMessage } from '../components/Notifier/Notifier'
import ConfirmationDialog, { showConfirmation, hideConfirmation } from '../components/Dialog/ConfirmationDialog'
import Util from '../utils/Util'

const withSharedFeatures = (WrappedComponent) => {
    class HOC extends Component {

        constructor(props) {
            super(props)
            props.history && props.history.listen((location, action) => {
                Util.sendAnalyticsData(location.pathname)
            })

            // set title for 508 compliance
            if (props.title) {
                document.title = props.title;
            }
        }

        render() {
            return (
                <div>
                    <WrappedComponent
                        showMessage={showMessage}
                        showConfirmation={showConfirmation}
                        hideConfirmation={hideConfirmation}
                        {...this.props}
                    />
                    <Notifier {...this.props} />
                    <ConfirmationDialog />
                </div>
            )
        }
    }
    return HOC
  }
  
  export default withSharedFeatures