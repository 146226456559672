import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import BaseComponent from '../_Base'
import withSharedFeatures from '../../views/withSharedFeatures'
import CsvLoader from './CsvLoader'
// jss
import { root } from '../../assets/jss/index'


const styles = (theme) => ({
    root: {
        ...root,
        margin: 0,
        padding: '0px 0px 10px 0px',
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar: theme.mixins.toolbar,
    flexNone: {
        flex: 'none'
    },
    flexAuto: {
        flex: 1,
    },
})

class EventDataAndCsvs extends BaseComponent {
    render() {
        const { event, classes } = this.props
        const csvs = event.csv
        return (
            <div className={classes.root}>
                {csvs.map((csvUrl, idx) => (
                    <CsvLoader key={idx} urlResource={csvUrl}></CsvLoader>
                ))}
            </div>
        )
    }
}

EventDataAndCsvs.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withSharedFeatures(withStyles(styles)(EventDataAndCsvs))
