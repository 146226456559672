import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

const styles = theme => ({
    root: {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'white',
        zIndex: 1500,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0,
        cursor: 'progress'
    }
})

let blockUIFunction
let unblockFunction

class BlockUI extends React.Component {

    blockUI = () => {
        this.setState({
            show: true
        })
    }
    unblock = () => {
        this.setState({
            show: false
        })
    }

    state = {
        show: false
    }

    componentDidMount() {
        blockUIFunction = this.blockUI.bind(this)
        unblockFunction = this.unblock.bind(this)
    }

    render() {
        const { classes } = this.props
        const { show } = this.state
        if (!show) return null

        return (
            <div className={classes.root}>
            </div>
        )
    }
}

BlockUI.propTypes = {
    classes: PropTypes.object.isRequired,
}

export function blockUI () {
    blockUIFunction()
}

export function unblock() {
    unblockFunction()
}

export default withStyles(styles)(BlockUI)