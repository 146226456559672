import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
// jss
import { root, container } from '../assets/jss/index'

import Globals from '../variables/globals'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    color: 'black',
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content-page": {
      flex: 1,
      overflowY: 'auto',
      padding: '40px 40px',
      '& div.table-wrap': {
        background: 'none'
      }

    }
  },
  paperRoot: {
    maxWidth: 430,
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'whitesmoke',
    }
  },
  cardHeader: {
    paddingBottom: 0,
    '& .MuiAvatar-root': {
      color: 'white',
      backgroundColor: '#3f51b5',
    }
  },
  cardContentText: {
    color: 'rgba(0,0,0,0.8)',
    '& a': {
      fontWeight: 'bold',
      color: 'blue'
    }
  },
  toolbar: theme.mixins.toolbar,
})

const Info = (props) => {
  const { classes, ...rest } = props;
  const viewName = 'Info';
  Globals.viewsAnimationTimeoutDict[viewName] = Globals.viewsAnimationTimeoutDict[viewName] === undefined ? 2000 : 0;
  const aniTimeout = Globals.viewsAnimationTimeoutDict[viewName];
  document.title = props.title;

  return (
    <div className={classNames('full-screen', classes.root)}>
      <div className={classes.container}>
        <div className='app-bar'>
          <MainAppBar showBackButton={true} {...rest} />
        </div>
        <div className={classes.toolbar}></div>
        <Fade in={true} timeout={aniTimeout}>

          <div className='content-page' style={{ padding: '10px 15px' }}>

            <Box 
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                p: 1,
                m: 1
              }}>

              <Card className={classes.paperRoot}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar aria-label="about">
                        A
                      </Avatar>
                    }
                    title={
                      <Typography gutterBottom variant="h1" component="h1">
                        About GLM &amp; Bolides
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography className={classes.cardContentText} variant="body2" color="textSecondary" component="p">
                      The Geostationary Lightning Mapper, aboard the GOES East and GOES West satellites, is designed to capture natural lightning activity, but can also detect bright meteors, called bolides (e.g. Jenniskens et al 2018, Rumpf et al 2019).
                      These two space-based remote sensing systems are operated by the National Oceanic and Atmospheric Administration (NOAA) and became operational in December 2017 (GOES 16), in February 2019 (GOES 17), and in January 2023 (GOES 18).
                      GLM's large coverage area allows it to capture unprecedented numbers of meteors.  GLM data is publicly accessible.
                      The bolides are detected by the GLM pipeline (Smith et al 2021). The published light curves and other data products are generated using the standard GLM lightning data acquisition and processing pipeline, which makes several assumptions that are not valid for bolides, so additional corrections maybe necessary for some purposes (Morris et al 2022). More details can be found <Link to="/info/about">here</Link>.
                      <br />
                      <br />
                      <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        {/* <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="fontSize medium" 
                            style={{fontSize: '1.5rem', color: 'rgba(0, 0, 0, 0.54)', position: 'relative', top: 7}}><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
                        </svg> */}
                        <span style={{ fontSize: '1.5em' }}>&#9993;</span>
                        <span style={{ fontSize: '1em' }}>&nbsp;&nbsp;Contact Us</span>
                      </span>
                      

                      <br /><br />
                      <a href="mailto:ARC-bolide-data@mail.nasa.gov" rel="nofollow">ARC-bolide-data@mail.nasa.gov</a> 
                    </Typography>
                  </CardContent>
              </Card>

              <Card className={classes.paperRoot}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar aria-label="about">
                        H
                      </Avatar>
                    }
                    title={
                      <Typography gutterBottom variant="h1" component="h1">
                        How to use this website
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography className={classes.cardContentText} variant="body2" color="textSecondary" component="p">
                      The website provides a variety of interfaces to examine, search and download the data.   
                      The three primary interfaces are the home page, the event list page and the event detail page.  
                      It is possible to search on a variety of parameters including date and location.  
                      In addition the GLM data for each event can be downloaded. More details are available <Link to="/info/howto">here</Link>.
                    </Typography>
                  </CardContent>
              </Card>

              <Card className={classes.paperRoot}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar aria-label="about">
                        N
                      </Avatar>
                    }
                    title={
                      <Typography gutterBottom variant="h1" component="h1">
                        Latest News
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h2" component="h2" style={{margin: 0}}>
                      May 11, 2020
                    </Typography>
                    <Typography className={classes.cardContentText} variant="body2" color="textSecondary" component="p">
                      We've released a new version of <a href="https://neo-bolide.ndc.nasa.gov" target="_blank" rel="noopener noreferrer">neo-bolide.ndc.nasa.gov</a>.
                    </Typography>
                    <Typography className={classes.cardContentText} variant="body2" color="textSecondary" component="p">
                      Changes in this version include:
                    </Typography>
                    <ul>
                      <li>New maps and charts to examine the data set</li>
                      <li>Advanced search capabilities</li>
                      <li>A bug fix to correct the satellite location specified in the downloadable files for stereo events.</li>
                    </ul>
                    <Typography className={classes.cardContentText} variant="body2" color="textSecondary" component="p">
                      All past news items are available <Link to="/info/latestnews">here</Link>.
                    </Typography>
                  </CardContent>
              </Card>


            </Box>
          </div>
        </Fade>
      </div>
    </div>
  )
}

Info.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(Info))
