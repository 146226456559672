export const BRIGHTNESS_CATEGORIES = {
  "Faint": {
    max: 1e-14
  },
  "Fairly Bright": {
    max: 1e-13
  },
  "Bright": {
    max: 1e-12
  },
  "Very Bright": {
    max: Number.POSITIVE_INFINITY
  }
}

/**
 * Calculate the median brightness value
 * @param {*} values A list of energies (brightness values)
 * @param {*} count The count of greatest energies from which to calculate the median
 * @returns The median brightness value
 */
function getMedianBrightness(values, count) {
  // Grab the highest values
  const sorted = values.sort((a, b) => (b - a)).slice(0, count);

  const mid = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[mid - 1] + sorted[mid]) / 2;
  }

  return sorted[mid];
}

/**
 * Determine the brightness level and value for the given GeoData attachments
 * @param {*} attachments The GeoData attachment files
 * @returns The brightness object containing level and value per source
 */
export function generateBrightness(energies) {
  // Grab the median value of the greatest X number of energy values
  const median = getMedianBrightness(energies, 5);

  // Filter all brightness levels (faint, fairly bright, bright, very bright) 
  // which are greater than the median, and take the lowest one (lowest energy level)
  const category = Object.keys(BRIGHTNESS_CATEGORIES)
    .filter(level => median < BRIGHTNESS_CATEGORIES[level].max)
    .shift();

  return {
    category: category,
    value: median
  };
}