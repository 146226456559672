import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

const styles = theme => ({
    root: {
        fontSize: '100%',
        position: 'relative',
        border: '1px solid #bbbbbb',
        borderRadius: 5,
        minWidth: 100,
        margin: '8px 8px 4px 8px',
        padding: '13px 15px',
        '&:hover': {
          border: '1px solid #212121',
        }
    },
})


const GroupContainer = (props) => {
    let { className, style, classes, children, label, labelStyle, error } = props
    const labelCss = Object.assign({}, {
      color: 'rgba(0, 0, 0, 0.54)',
      position: 'absolute',
      top: -8,
      left: 5,
      backgroundColor: 'white',
      padding: '0 5px',
      fontSize: '0.75rem',
    }, labelStyle)
    if (error) {
      style = Object.assign({}, style, {
        border: '1px solid red'
      })
    }
    return (
      <div className={classNames(className, classes.root)} style={style}>
        <span style={labelCss}>{label}</span>
        {children}
      </div>
    )
  }

GroupContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
}

export default withStyles(styles)(GroupContainer)
