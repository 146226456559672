import React, { useState, useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import ArtTrackIcon from '@mui/icons-material/ArtTrack'
import classNames from 'classnames'
import AppState from '../../managers/AppStateManager'
import Util from '../../utils/Util'
import { C_LEGEND_LABEL_MAP, C_GLM_COLOR } from '../../variables/common'

const useStyles = makeStyles((theme) => ({
    paper: {
        color: 'whitesmoke',
        backgroundColor: '#131313',
        // padding: '15px',
        fontSize: '0.75rem',
        border: '1px solid #444',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        padding: theme.spacing(1),
        // backgroundColor: theme.palette.background.paper,
    },
    titleBar: {
        fontSize: '0.8rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 5,
        '& .text': {
            flex: 1,
            textAlign: 'center',
            fontWeight: 'bold',
            marginLeft: -25,
        },
    },
    content: {
        maxHeight: 245,
        overflowY: 'auto',
        '& .sub-title': {
            fontWeight: 'bold',
            borderBottom: '1px dotted darkgray',
            marginTop: 10,
        }
    },
    tableDetail: {
        fontSize: '0.7rem',
        '& td': {
            padding: '3px 3px 0 10px'
        }
    },
    dot: {
        height: 25,
        width: 25,
        backgroundColor: 'white',
        border: '3px solid white',
        borderRadius: '50%',
        display: 'inline-block',
    },
    button: {
        color: '#cccccc',
        padding: '0px 10px',
        '&:hover': {
            color: 'white',
        },
        textTransform: 'capitalize',
        '&.show-details-button': {
            backgroundColor: 'slategray',
            border: '1px solid slategray',
            '&:hover': {
                border: '1px solid white'
            }
        }
    },
}))

const platformMap = {
    'G16': 'GLM-16',
    'G17': 'GLM-17',
    'G18': 'GLM-18'
}

export default function EventsPopper({targetEl, style, className, events, selectedEventId, location, history, onMouseLeave}) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(targetEl)

    const handleOnShowDetail = () => {
        const pl = { pathname: '/eventdetail/' + selectedEventId + '?ref=main', state: { prevPath: location.pathname } }
        history.push(pl)
    }
    
    const handleMouseEnter = (e) => {
        // console.log('DEBUG: EventsPopper - handleMouseEnter', AppState.suppressPopperClosing)
        AppState.suppressPopperClosing = true
    }

    let event = null
    if (events && events.length > 0 && selectedEventId) {
        event = events.find(e => e._id === selectedEventId)
    }

    const cKey = event ? event.detectedBy + ',' + event.confidenceRating : ''
    const titleText = C_LEGEND_LABEL_MAP[cKey]
    const glmColor = C_GLM_COLOR[cKey]

    let showTableTitle = false
    if (event) {
        //showTableTitle = (event.detectedBy === "GLM-16,GLM-17")
        //if `detectedBy` includes ",", means this event is detected by multiple satellites.
        showTableTitle = (event.detectedBy.includes(","))
    }

    useEffect(() => {
        setAnchorEl(targetEl)
        // console.log('DEBUG: EventsPopper - useEffect', events, event, selectedEventId)

    }, [targetEl, anchorEl, selectedEventId])

    return (
        <div style={style} className={className}>
            <Popper 
                style={{zIndex:1300}} 
                open={Boolean(anchorEl)} 
                anchorEl={anchorEl} 
                placement='right-end' 
                onMouseLeave={onMouseLeave}
                onMouseEnter={handleMouseEnter}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                        options: {
                          altBoundary: true,
                          rootBoundary: 'document',
                          padding: 8,
                        },
                      },
                      {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                          altAxis: true,
                          altBoundary: true,
                          tether: true,
                          rootBoundary: 'document',
                          padding: 8,
                        },
                      }
                ]}
                >
                {/* {({ TransitionProps }) => ( 
                    <Fade {...TransitionProps} timeout={500}>  */}
                        {event &&
                            <div className={classes.paper} style={{borderColor: glmColor.stroke}}>
                                <div className={classes.titleBar}>
                                    <span className={classes.dot} style={{backgroundColor: glmColor.fill, borderColor: glmColor.stroke}}></span>
                                    <span className='text'>{titleText}</span>
                                </div>
                                <Divider style={{borderColor: glmColor.stroke}} />
                                <div className={classes.content}>
                                    {event.attachments.map((prop, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                            {showTableTitle && 
                                                <div className='sub-title'>{platformMap[prop.platformId]}</div>
                                            }
                                            <table className={classes.tableDetail} key={key}>
                                                <tbody>
                                                <tr>
                                                    <td>Start Time:</td>
                                                    <td>{Util.formatDate(prop.startTime)}</td>
                                                </tr>
                                                <tr>
                                                    <td>End Time:</td>
                                                    <td>{Util.formatDate(prop.endTime)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Duration:</td>
                                                    <td>{prop.duration.toFixed(3)} seconds</td>
                                                </tr>
                                                <tr>
                                                    <td>Longitude:</td>
                                                    <td>{prop.location.coordinates[0].toFixed(1)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Latitude:</td>
                                                    <td>{prop.location.coordinates[1].toFixed(1)}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </React.Fragment>
                                        )}
                                    )}
                                </div>
                                <div style={{ marginTop: 5, textAlign: 'right', paddingTop: 10, borderTop: '1px dotted whitesmoke' }}>
                                    <Button
                                        onClick={handleOnShowDetail}
                                        variant="contained"
                                        color="primary"
                                        className={classNames(classes.button, 'show-details-button')}
                                        startIcon={<ArtTrackIcon />}
                                        >Show more info...</Button>
                                </div>
                            </div>
                         }
                         <span></span>
                    {/* </Fade>
                )} */}
            </Popper>
        </div>
    )
}
