import React, { Component } from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress'

// core components

import bolideLogo from "../../assets/img/nasa-logo.svg"

const styles = theme => ({
    root: {
        fontSize: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    progress: {
        margin: 0,
        color: '#103f8f',
    },
    logo: {
        height: 150,
        width: 150,
        position: 'absolute',
        zIndex: 1,
        top: 16,
        left: 20,
    }
});


class Splash extends Component {
    render() {
        const { classes, showLogo } = this.props
        const progressSize = showLogo ? 177 : 50
        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    {showLogo && <img className={classes.logo} src={bolideLogo} alt="" /> }
                    <CircularProgress className={classes.progress} size={progressSize} thickness={3} disableShrink />
                    <div style={{marginTop:20, textAlign: 'center'}}>please wait...</div>
                </div>
            </div>
        )
    }
}

Splash.propTypes = {
    classes: PropTypes.object.isRequired,
    showLogo: PropTypes.bool
}

Splash.defaultProps = {
    showLogo: true
}

export default withStyles(styles)(Splash)
