import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import BaseComponent from '../components/_Base'
import Splash from '../components/Splash/Splash'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import AppState from '../managers/AppStateManager'
// jss
import { root, container } from '../assets/jss/index'
import EventDetailForm from '../components/Public/EventDetailForm'
import { C_MODULE_NAME } from '../variables/common'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: "100%"
  },
})

class EventDetail extends BaseComponent {

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }
    const { eventId } = props.match.params

    AppState.currentPublicEventId = eventId
    let hasGeoData = false 
    if (AppState.selectedPublicEvent && 
        AppState.selectedPublicEvent.attachments[0].geoData && 
        AppState.selectedPublicEvent.attachments[0].geoData.length > 0) {
      hasGeoData = true
    }

    // extend base state....
    this.state = {
      ...baseState,
      isReady: hasGeoData,
      eventId: eventId,
      event: AppState.selectedPublicEvent,
    }
    AppState.currentModuleName = C_MODULE_NAME.C_PUBLIC_PAGE

  }

  componentWillMount() {
    this.props.changeTheme('baseTheme')
  }

  componentDidMount() {
    super.componentDidMount()

    const { isReady, eventId } = this.state

    if (!isReady) {
      AppState.getEvent(eventId, (res) => {
        if (res.success) {
          const event = res.data
          AppState.updateEventAttachments(event)
          this.setState({
            isReady: true,
            event: event
          })
        } else {
          console.log('DEBUG:error', res.error)
        }
      })
    }
  }

  render() {
    const { classes, ...rest } = this.props
    const { isReady, event } = this.state

    return (
      <div className={classNames('full-screen', classes.root)}>
          <div className={classes.container}>
            <div className='app-bar'>
              <MainAppBar showBackButton={true} {...rest} />
            </div>
            <div className='content'>
              {!isReady && (
                <Splash showLogo={false} />
              )}
              {isReady && (
                <EventDetailForm event={event} allowRegenerate={false} {...rest} />
              )}
            </div>
          </div>
      </div>
    )
  }
}

EventDetail.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(EventDetail))
