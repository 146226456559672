import React from 'react'
import { PatternFormat } from 'react-number-format';
import PropTypes from 'prop-types'

const TextFieldPatternFormatExtender = React.forwardRef(function TextFieldFormatExtender(
  props,
  ref,
) {

  const { onChange, ...other } = props;
  
  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        const { formattedValue } = values
        onChange({
          target: {
            value: formattedValue,
          },
        });
      }}
    />
  );
})

TextFieldPatternFormatExtender.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default TextFieldPatternFormatExtender