import React from 'react'
import ReactDOM from 'react-dom'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import classNames from 'classnames'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import UserIcon from '@mui/icons-material/SupervisedUserCircle'
import CalendarIcon from '@mui/icons-material/CalendarToday'

import BaseComponent from '../components/_Base'
import Splash from '../components/Splash/Splash'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import AppState from '../managers/AppStateManager'
// jss
import { root, container } from '../assets/jss/index'

import UserList from '../components/Settings/UserList'
import EventStatus from '../components/Settings/EventStatus'
import ServiceAccountList from '../components/Settings/ServiceAccountList'
import TagList from "../components/Settings/TagList";


import { C_MODULE_NAME, C_ROLE } from '../variables/common'

const DRAWER_WIDTH = 200 // C_DEFAULTS.C_DRAWER_WIDTH

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      marginLeft: DRAWER_WIDTH,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down(1300)]: {
        justifyContent: 'flex-start',
      },
      alignItems: 'flex-start',
      overflow: 'auto',
      "& > div:first-child": {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '0px 20px 20px 20px',
      }
    }
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer - 2
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  verticalSpacer: {
    height: 20
  },
  toolbar: theme.mixins.toolbar,
})

class Settings extends BaseComponent {

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }
    // extend base state....
    this.state = {
      ...baseState,
      isReady: false,
    }
    AppState.currentModuleName = C_MODULE_NAME.C_DATA_ENTRY

  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleShowSection = name => event => {
    const currentDOM = ReactDOM.findDOMNode(this[name])
    const padding = -64
    if ((currentDOM.offsetHeight + 128) < this._scrollableContainerNodeDOM.offsetHeight) {
      currentDOM.style.height = this._scrollableContainerNodeDOM.offsetHeight + 'px'
    }
    this._scrollableContainerNodeDOM.scrollTop = currentDOM.offsetTop  + padding
  }

  componentWillMount() {
    this.props.changeTheme('adminTheme')
  }

  componentDidMount() {
    super.componentDidMount()

    AppState.preInit((error, data) => {

      if (error) {
        return console.log('DEBUG:error', error)
      }

      AppState.checkSession((err, session) => {
        if (!AppState.currentUser ||
            (AppState.selectedRole &&
             AppState.selectedRole.toUpperCase() !== C_ROLE.C_ADMIN)) {
          window.location.href = '/admin'
          return
        }
        // get a list of users....
        this.setState({
          isReady: true
        })
      })
    })
  }

  render() {
    const { classes, ...rest } = this.props
    const { isReady } = this.state
    const  contentStyle = !isReady ? { justifyContent: 'center', alignItems: 'center' } : {}
    return (
      <div className={classNames('full-screen', classes.root)}>
        <div className={classes.container}>
          <div className='app-bar'>
            <MainAppBar {...rest} />
          </div>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}>
            <div className={classes.toolbar} />
            <List style={{paddingTop: 15}} component="nav">
              <ListItemButton onClick={this.handleShowSection('_userListNode')}>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
              <ListItemButton onClick={this.handleShowSection('_jwtListNode')}>
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="Service Accounts" />
              </ListItemButton>
              <ListItemButton onClick={this.handleShowSection('_eventStatusNode')}>
                <ListItemIcon>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary="Event" />
              </ListItemButton>
            </List>

          </Drawer>

          <div className={classes.toolbar}></div>

          <div className='content' style={contentStyle} ref={ node => this._scrollableContainerNodeDOM = node}>
            {!isReady && (
              <Splash showLogo={false} />
            )}
            {isReady && (
              <div>
                <UserList ref={ node => this._userListNode = node} {...rest} />
                <div className={classes.verticalSpacer}>&nbsp;</div>
                <ServiceAccountList ref={ node => this._jwtListNode = node} {...rest}/>
                <div className={classes.verticalSpacer}>&nbsp;</div>
                <TagList tags={AppState._adminTags} ref={ node => this._tagListNode = node} {...rest}/>
                <div className={classes.verticalSpacer}>&nbsp;</div>
                <EventStatus ref={ node => this._eventStatusNode = node} {...rest} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(Settings))
