import React, { useState } from 'react'

// @mui/material components
import { withStyles } from '@mui/styles'
import { darken, lighten } from '@mui/material/styles'
import withSharedFeatures from '../../views/withSharedFeatures'
import classNames from 'classnames'
import AppState from '../../managers/AppStateManager'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button'
import TextField from "@mui/material/TextField";
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from "@mui/icons-material/Close";


// jss
import { root, container } from '../../assets/jss/index'
import { Typography } from '@mui/material';
import GroupBox from '../GroupBox/GroupBox';

const styles = (theme) => ({
    root: {
        ...root,
        fontSize: '0.85rem',
        width: 960,
    },
    container: {
        ...container,
        display: 'flex',
        flexDirection: 'column',
    },
    titleText: {
        fontSize: '1rem',
        marginBottom: 15,
        marginTop: 25,
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + darken('#f8f8ff', 0.065),
        padding: 15,
    },
    group: {
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1),
        width: 100,
    },
    groupBox: {
        height: 'auto',
        padding: 0,
    },
    table: {
        '& .table-row': {
            width: '100%'
        },
        '& .table-row:hover': {
            backgroundColor: lighten('#f8f8ff', 0.075),
        }
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            padding: 0,
        }
    },
    colHeader: {
        fontSize: '0.9em',
        fontWeight: 'bold',
        color: 'gray',
    },
    userNameCol: {
        flex: 'none',
        width: 80,
        marginRight: "25%"
    },
    firstNameCol: {
        flex: 'none',
        width: 110,
        textTransform: 'capitalize',
    },
    lastNameCol: {
        flex: 'none',
        width: 110,
        textTransform: 'capitalize',
    },
    emailCol: {
        flex: '1 1 auto',
        maxWidth: 250,
    },
    rolesCol: {
        flex: 'none',
        width: 170,
    },
    actionButtonsCol: {
        flex: 'none',
        width: 165,
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: 19,
    }
})

const formatTags = (taglist) => {
    //console.log(taglist);
    return taglist.map((tag) => tag.name);
}

const TagList = (props) => {
    const { classes } = props;
    const [taglist, setTagList] = useState(formatTags(props.tags));

    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [newTag, setNewTag] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [snackbarMessage, setSnackBarMessage] = useState('');
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const handleRemoveTag = (idx) => {
        const updated = taglist.filter((tag, i) => {
            return i !== idx;
        })

        setTagList(updated);

    }

    const handleEditTag = (idx) => {
        setEditIndex(idx);
        setShowEditDialog(true);
    }

    const handleAddTagDialog = () => {
        setShowAddDialog(true)
    }

    const handleDialogClose = (type) => {
        if (type === "add") {
            setShowAddDialog(false);
        }
        else if (type === "edit") {
            setShowEditDialog(false);
        }
    }

    const handleAddTag = () => {
        taglist.push(newTag);
        setTagList(taglist);
        setNewTag('');
        setShowAddDialog(false);
    }

    const handleUpdateTag = () => {
        taglist[editIndex] = newTag;
        setNewTag('')
        setEditIndex(null);
        setShowEditDialog(false);
    }

    const handleSaveTags = () => {
        AppState.saveAdminTags(taglist, (err, data) => {
            if (err) {
                alert("Something went wrong!", err)
            } else {
                setSnackBarMessage("Tags Successfully saved");
                setIsSnackbarOpen(true)
            }
        })
    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsSnackbarOpen(false);
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography key={1} className={classes.titleText}>Other Information Tags</Typography>
                <GroupBox key={2} withHeader={false} className={classes.groupBox} cardContent={classes.cardContent}>
                    <div className={classes.table}>
                        <div className={classes.flexItemRow} style={{ backgroundColor: darken('#f8f8ff', 0.075) }}>
                            <div className={classNames(classes.userNameCol, classes.colHeader)}>Tag Name</div>
                            <div className={classNames(classes.actionButtonsCol, classes.colHeader)}>&nbsp;</div>
                        </div>
                        {taglist.map((tag, idx) => {
                            return (
                                <div key={idx} className={classNames(classes.flexItemRow, 'table-row')} >
                                    {(
                                        [
                                            <div key={1} className={classes.userNameCol}>{tag}</div>,
                                            <div key={2} className={classes.actionButtonsCol}>
                                                <Button variant="outlined" onClick={() => handleEditTag(idx)}>Edit</Button>
                                                <Button variant="outlined" onClick={() => handleRemoveTag(idx)}>Remove</Button>
                                            </div>
                                        ]
                                    )}
                                </div>
                            )
                        })}
                        <div className={classes.flexItemRow} style={{ backgroundColor: '#f8f8ff', justifyContent: 'flex-end', paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                            <Button variant="outlined" onClick={() => handleAddTagDialog()} style={{ marginRight: 17 }}>Add</Button>
                            <Button variant="outlined" onClick={() => handleSaveTags()} style={{ marginRight: 34 }}>Save</Button>
                        </div>
                    </div>
                </GroupBox>
                <div className="add-dialog-container">
                    <Dialog
                        open={showAddDialog}
                        onClose={() => handleDialogClose('add')}
                    >
                        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                id="tag-name"
                                label="Tag Name"
                                value={newTag}
                                onChange={e => {
                                    setNewTag(e.target.value)
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleDialogClose('add')}>Cancel</Button>
                            <Button onClick={handleAddTag} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <div className="edit-dialog-container">
                    <Dialog
                        open={showEditDialog}
                        onClose={() => handleDialogClose('edit')}
                    >
                        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TextField
                                id="tag-name"
                                label="Tag Name"
                                value={newTag}
                                onChange={e => {
                                    setNewTag(e.target.value)
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleDialogClose('edit')}>Cancel</Button>
                            <Button onClick={handleUpdateTag} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={5000}
                    onClose={handleSnackBarClose}
                    message={snackbarMessage}
                />
            </div>
        </div>
    )
};


export default withSharedFeatures(withStyles(styles)(TagList))