import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import purple from '@mui/material/colors/purple'
import amber from '@mui/material/colors/amber'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import WarningIcon from '@mui/icons-material/Warning'
import { withStyles } from '@mui/styles'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        // backgroundColor: green[600],
        backgroundColor: purple[400],
    },
    error: {
        backgroundColor: amber[800],
    },
    info: {
        backgroundColor: theme.palette.secondary.main,
    },
    warning: {
        backgroundColor: amber[500],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const MySnackbarContent = React.forwardRef((props, ref) => {
    const { classes, className, message, showAction, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];
    const messageContent = (
        <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            <span dangerouslySetInnerHTML={{ __html: message }}>
            </span>
        </span>
    )
    const actionNode = showAction ? [
        <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
            size="large">
            <CloseIcon className={classes.icon} data-activityaction="Notification" />
        </IconButton>,
    ] : []

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={messageContent}
            action={actionNode}
            {...other}
        />
    );
})

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    showAction: PropTypes.bool,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

MySnackbarContent.defaultProps = {
    showAction: false
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
})


let showMessageFunction
let showStickyMessageFunction

class Notifier extends React.Component {
    queue = []
    state = {
        open: false,
        message: '',
        variant: 'info',
        showAction: false
    }
    timerId = null

    processQueue = (showAction = false) => {
        if (this.queue.length > 0) {
            let msg = this.queue.shift()
            if (!showAction) {
                this.props.blockUI()
            }
            this.setState({
                message: msg.message,
                variant: msg.variant,
                open: true,
                showAction: showAction,
            })
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        this.props.unblock()
        this.setState({ open: false });
    }

    handleExited = () => {
        const { showAction } = this.state
        this.processQueue(showAction)
    }

    showStickyMessage = (message, variant = 'info') => {
        if (!message || message.length === 0) {
            this.props.unblock()
            this.setState({ open: false, variant: variant })
            return
        }
        this.setState({ message: message, variant: variant })
    }

    showMessage = (message, variant = 'info', showAction = false) => {
        if (message.length === 0) {
            this.props.unblock()
            this.setState({ open: false, variant: variant })
            return
        }
        // remove existing message and re-add (put on top of the queue)
        let idx = this.queue.findIndex((i) => {
            return i.message === message
        })
        if (idx !== -1) {
            this.queue.splice(idx, 1)
        }

        this.queue.push({ message: message, variant: variant })

        if (this.state.open) {
            // immediately begin dismissing current message
            // to start showing new one
            this.props.unblock()
            this.setState({ open: false })
        } else {
            this.processQueue(showAction)
        }
    }

    componentDidMount() {
        showMessageFunction = this.showMessage.bind(this)
        showStickyMessageFunction = this.showStickyMessage.bind(this)
    }

    render() {
        // const { classes } = this.props;
        const { showAction } = this.state
        const duration = showAction ? 6000 : 3000
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.open}
                    autoHideDuration={duration}
                    onClose={this.handleClose}
                    TransitionProps={{
                        onExited: this.handleExited
                }}>
                    <div>
                        <MySnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={this.state.variant}
                            message={this.state.message}
                            showAction={this.state.showAction}
                        />
                    </div>
                </Snackbar>
            </div>
        );
    }
}

Notifier.propTypes = {
    classes: PropTypes.object.isRequired,
};

export function showMessage(message, variant = 'info', showAction = false) {
    showMessageFunction(message, variant, showAction)
}

export function showStickyMessage(message, variant = 'info') {
    showStickyMessageFunction(message, variant)
}

export default withStyles(styles2)(Notifier)