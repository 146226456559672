
import React, { useRef, useEffect, useState } from "react"
import { makeStyles } from '@mui/styles'
import useResizeObserver from "../UseResizeObserver/UseResizeObserver"
import moment from 'moment'
import Util from '../../utils/Util'
import { C_GLM_COLOR } from '../../variables/common'

import * as d3 from 'd3'

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '100%',
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%', 
        position: 'relative',
        '& .year-dropdown': {
            width: 75,
            fontSize: '0.75rem',
            color: 'whitesmoke',
            backgroundColor: '#111'
        },
        '& .chart-legend, text.text': {
            userSelect: 'none',
        }
    }
}))

const { select, sum, max, scaleLinear, scaleBand, scaleOrdinal } = d3
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const legendColors = [C_GLM_COLOR['GLM-16,high'].fill, C_GLM_COLOR['GLM-16,GLM-17,high'].fill, C_GLM_COLOR['GLM-17,GLM-18,high'].fill]
const legendStrokeColors = [ C_GLM_COLOR['GLM-16,high'].stroke, C_GLM_COLOR['GLM-16,GLM-17,high'].stroke, C_GLM_COLOR['GLM-17,GLM-18,high'].stroke]

const margin = {top: 35, bottom: 10, left: 40, right: 0}

let prevParent = null
let timeoutId = null
    
const itemGroupKeys = ["GOES-East", "Stereo", "GOES-West"]

const countDataSource = (arr) => { 
    const tmpMap = new Map([
        ['GOES-East', 0],['Stereo', 0],['GOES-West', 0]
    ])
    arr.forEach(a => {
        // For each detection event, check detectedBy and increment count for relevant category
        const dby = a.detectedBy
        const key = dby === "GLM-16" ? "GOES-East" : (dby === "GLM-17" || dby === "GLM-18" || dby === "GLM-17,GLM-18") ? "GOES-West" : "Stereo";
        let t = tmpMap.get(key)
        tmpMap.set(key,t += 1)
    })
    return tmpMap
}

const generateChartData = (events, keyFormat='YYYY-MMM') => {
    let tmpData = new Map()
    const _events = [...events]
    _events.forEach(e => {
        const ed = moment.utc(e.datetime)
        const key = ed.format(keyFormat)
        let d = tmpData.get(key)
        if (d) {
            d.push(e)
        } else {
            d = tmpData.set(key, [e])
        }
        // d = d ? d.push(e) : tmpData.set(key, [e])
    })
    // console.log("DEBUG: tmpData", tmpData)
    let eventData = []
    tmpData.forEach((value, key) => {
        const t = countDataSource(value)
        const a = key.split('-')
        const total = t.get('GOES-East') + t.get('GOES-West') + t.get('Stereo')
        eventData.push({
            date: key,
            year: a[0],
            month: a[1],
            'GOES-East': t.get('GOES-East'),
            'GOES-West': t.get('GOES-West'),
            'Stereo': t.get('Stereo'),
            total: total
        })
    })
    return eventData
}


function EventsChart({ events }) {
    const classes = useStyles()
    const svgRef = useRef()
    const wrapperRef = useRef()
    const dimensions = useResizeObserver(wrapperRef)
        
    // ---------------------------------------------------------------------------------------------
    // useState & useEffect for events
    const [chartData, setChartData] = useState([])
    const [chartDataAllYears, setChartDataAllYears] = useState([])
    const [years, setYears] = useState([])
    useEffect(() => {
        // console.log('DEBUG: useEffect 1', '|'+year)
        const _chartData = generateChartData(events)
        const _chartDataAllYears = generateChartData(events, 'YYYY')
        let _years = [...new Set(_chartData.map(d => d.year))].sort((a, b) => { return b - a })
        
        if (_years.length > 1) {
            _years.unshift('All Years')
        }
        // console.log('DEBUG:UI ', _years)
        
        // initialize year dropdown
        const _yearDropDown = select('.year-dropdown')
            .on("change", function () {
                setYear(this.value)
            })

        _yearDropDown.selectAll("option")
            .remove()

        _yearDropDown.selectAll("option")
            .data(_years)
            .enter().append('option')
            .text(d => d)
        
        const _year = _years.length > 0 ? _years[0] : ''
        const _filterData = _year === 'All Years' ? _chartDataAllYears : _chartData.filter(f => f.year === _year)
        
        setChartData(_chartData)
        setChartDataAllYears(_chartDataAllYears)
        setYear(_year)
        _years.shift()
        _years.sort()
        setYears(_years)
        setFilteredData(_filterData)
        setTotalEvents(sum(_filterData, k => +k.total))
    }, [events])

    const [year, setYear] = useState('')
    useEffect(() => {
        // console.log('DEBUG: useEffect 2', '|'+year)
        const _filterData = (year === 'All Years') ? chartDataAllYears : chartData.filter(f => f.year === year)
        setFilteredData(_filterData)
        setTotalEvents(sum(_filterData, k => +k.total))
    }, [year, chartData, chartDataAllYears])


    // ---------------------------------------------------------------------------------------------
    // useState & useEffect for year
    const [width, setWidth] = useState(400)
    const [height, setHeight] = useState(300)
    const [viewBox, setViewBox] = useState('0 0 400 300')
    const [speed, ] = useState(0)
    const [filteredData, setFilteredData] = useState([])
    const [totalEvents, setTotalEvents] = useState(0)
    useEffect(() => {
        // console.log('DEBUG:UI useEffect 3 filteredData', chartData, filteredData)
        const _boundingRect = dimensions || wrapperRef.current.getBoundingClientRect()
        const _view = `0 0 ${_boundingRect.width} ${_boundingRect.height}`

        setWidth(_boundingRect.width)
        setHeight(_boundingRect.height)
        setViewBox(_view)

        const svg = select(svgRef.current)
		const _chartWidth = width - margin.left - margin.right
        const _chartHeight = height - margin.top - margin.bottom

        // Draw legend
        svg.selectAll(".chart-legend").remove()

        let _legend = svg.selectAll(".chart-legend")
            .data(legendColors)
            .enter().append("g")
            .attr("class", "chart-legend")
            .attr("transform", function (d, i) {
                const left = (width - 220) / 2
                let x = left + (i * 60) + i * i * 10 + (i === 0 ? 0 : 40);
                return `translate(${x},10)`
            })
        //.attr("transform", function (d, i) { return "translate(30," + i * 19 + ")"; });

        _legend
            .append("rect")
            .attr("width", 10)
            .attr("height", 10)
            .style('stroke', (d, i) => legendStrokeColors[i])
            .style("fill", (d, i) =>  legendColors[i])

        _legend
            .append("text")
            .attr("y", 3)
            .attr("dy", "0.6em")
            .attr("dx", "1.5em")
            .style("text-anchor", "start")
            .style('font-size', '0.7em')
            .style('fill', 'white')
            .text( (d, i) => itemGroupKeys[i])            
     
        const _x = scaleBand()
            .range([margin.left, _chartWidth - margin.right])
            .padding(0.1)
            .paddingOuter(0.2)

        const _y = scaleLinear()
            .range([_chartHeight - margin.bottom, margin.top])

        const _z = scaleOrdinal()
            .domain(itemGroupKeys)
            .range(legendColors)
        
        const _z2 = scaleOrdinal()
            .domain(itemGroupKeys)
            .range(legendStrokeColors)

        const _xAxis = g => g
            .attr('color', 'white')
            .attr("transform", `translate(-5, ${_chartHeight - margin.bottom + 5})`)
            .call(d3.axisBottom(_x).tickSizeOuter(0))

        const _yAxis = g => g
            .attr('color', 'white')
            .attr("transform", `translate(${margin.left},5)`)
            .call(d3.axisLeft(_y))


        let _yMax = max(filteredData, d => d3.sum(itemGroupKeys, k => d[k] ? +d[k] : 0))
        const yThreshold = 100
        _yMax = _yMax < yThreshold ? yThreshold : _yMax
        _y.domain([0, _yMax]).nice()
        svg.selectAll(".y-axis").transition().duration(speed)
            .call(_yAxis)

        _x.domain(year === 'All Years' ? years : months)
        svg.selectAll(".x-axis").transition().duration(speed)
            .call(_xAxis)
            
        let _stackData = d3.stack().keys(itemGroupKeys)(filteredData)
        // add the key (G16,G17,Stereo) as part of the items...
        _stackData.forEach(d => d.forEach(a => a.push(d.key)))
        let _group = svg.selectAll("g.layer")
            .data(_stackData, d => d.key)
			// .data(d3.stack().keys(itemGroupKeys)(filteredData), d => d.key)
        
        _group.exit().remove()

		_group.enter().append("g")
			.classed("layer", true)
            .attr("fill", d => _z(d.key))
            .attr('stroke', d => _z2(d.key))

        let _bars = svg.selectAll("g.layer").selectAll("rect")
            //.data(d => d)
            .data(d => d, e => e.data.month)
            
		_bars.exit().remove()

        svg.on('mousemove', function() {
            if (timeoutId) {
                window.clearTimeout(timeoutId)
                timeoutId = null
            }
            timeoutId = window.setTimeout(() => {
                prevParent && prevParent.classList.remove('chart-rect-hover')    
                prevParent = null
            }, 500)
        })

        const getYearOrMonth = (d) => {
            return year === 'All Years' ? d.year : d.month
        }
        _bars.enter().append("rect")
            .attr("width", _x.bandwidth())
            .on("mouseover",  function() { 
                _tooltip && _tooltip.style("display", null) 
                if (timeoutId) {
                    window.clearTimeout(timeoutId)
                    timeoutId = null
                }
                const t = this.parentElement
                if (t !== prevParent) {
                    prevParent && prevParent.classList.remove('chart-rect-hover') 
                    prevParent = t
                    t.classList.add('chart-rect-hover')
                }
            })
            .on("mouseout",  function() { 
                _tooltip && _tooltip.style("display", "none") 
            })
            .on("mousemove",  function()  {
                d3.event.stopPropagation()
                prevParent = this.parentElement
                const xPosition = d3.mouse(this)[0] - 15
                const yPosition = d3.mouse(this)[1] - 25
                _tooltip && _tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")")
                _tooltip && _tooltip.select("text").text(select(this).attr('data-count'))
            })
			.merge(_bars)
		    .transition().duration(speed)
			.attr("x", d => _x(getYearOrMonth(d.data)))
			.attr("y", d => _y(d[1] - 1.5))
            .attr("height", d => _y(d[0]) - _y(d[1]))
            .attr('data-count', d => Math.abs(d[0] - d[1]))
            .attr('data-month', (d,e) => getYearOrMonth(d.data))
            .attr('data-height-d0', d => _y(d[0]))
            .attr('data-height-d1', d => _y(d[1]) )


		let _text = svg.selectAll(".text")
			.data(filteredData, d => d.month)

		_text.exit().remove()

		_text.enter().append("text")
			.attr("class", "text")
            .attr("text-anchor", "middle")
            .attr("fill", 'white')
            .style("font-size", '10px')
			.merge(_text)
		    .transition().duration(speed)
			.attr("x", d => _x(getYearOrMonth(d)) + _x.bandwidth() / 2 )
            .attr("y", d => _y(d.total))
            .text(d => d.total)

        let _tooltip = null
        Util.debounce(() => {
            _tooltip = svg.selectAll('g.tooltip')
                .data([1])
                .enter()
                .append("g")
                .attr("class", "tooltip")
                .style("display", "none");

            _tooltip.append("rect")
                .attr("width", 30)
                .attr("height", 20)
                .attr('rx', 3)
                .attr("fill", "black")
                .attr('stroke', 'white')
                .attr("opacity", 1)

            _tooltip.append("text")
                .attr("x", 15)
                .attr("dy", "1.2em")
                .attr('stroke', 'white')
                .style("text-anchor", "middle")
                .style("font-size", "11px")
                .style("font-weight", "normal")

            _tooltip = svg.selectAll('g.tooltip')
        }, 500)()
    }, [filteredData, totalEvents, dimensions, width, height, year, years, speed])

    return (
        <div className={classes.root}>
            <div className="select-none" style={{padding:'10px 10px 0px 15px', display: 'flex', justifyContent: 'space-evenly', color: 'white'}}>
                <div style={{width: 56, zIndex: 2}}><select className="year-dropdown" aria-label="year-select"></select></div>
                <div style={{flex:1, textAlign:'center', fontSize: '0.75rem', fontWeight: 'bold', marginLeft:-56}}>{`${totalEvents} Bolides`}</div>
            </div>
            <div ref={wrapperRef} style={{ flex: 1, position: 'relative' }}>
                <svg ref={svgRef} width={width} height={height} viewBox={viewBox} style={{ display: 'block' }}>
                    <g className="x-axis select-none"></g>
                    <g className="y-axis select-none"></g>
                </svg>
            </div>
        </div>
    )
}

export default EventsChart
