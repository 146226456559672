import 'date-fns'
import React, { useRef, useEffect, useState } from "react"
import { makeStyles, withStyles } from '@mui/styles'
import Slider from '@mui/material/Slider'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArtTrackIcon from '@mui/icons-material/ArtTrack'
import SendIcon from '@mui/icons-material/Send'
import ClearIcon from '@mui/icons-material/Clear'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import classNames from 'classnames'
import moment from 'moment'
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ButtonProgress from '../ButtonProgress/ButtonProgress'

import TextFieldFormat from '../TextFieldFormat/TextFieldFormat'
import Util from '../../utils/Util'
import AppState from '../../managers/AppStateManager'
import GroupContainer from '../GroupContainer/GroupContainer'
import { C_EVENT_IDS } from '../../variables/common'
import * as d3 from 'd3'


const CssTextField = withStyles({
    root: {
        margin: '6px 5px 5px 0px',
        fontSize: '100%',
        '& label': {
            fontSize: '100%',
            color: '#dedede',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            height: 45,
            color: 'white',
            fontSize: '100%',
            '& fieldset': {
                borderColor: 'gray',
            },
            '& input': {
                color: '#dedede',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                borderWidth: 1,
            },
        },
    },
})(TextField)

const CssBasicTextField = withStyles({
    root: {
        position: 'absolute',
        top: 9,
        margin: 0,
        fontSize: '100%',
        color: 'white',
        '& label.Mui-focused': {
          color: 'white',
        },
        '& .MuiInput-underline:after, .MuiInput-underline:before': {
            borderBottomColor: 'white',
        },
        '& .MuiInputBase-root': {
            color: 'white',
            fontSize: '1em',
        },
      },
})(TextField)

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: '100%',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        userSelect: 'none',
    },
    sliderTrack: {
        color: 'white'
    },
    sliderRail: {
        color: 'gray'
    },
    sliderThumb: {
        color: 'slategray'
    },
    labelColor: {
        color: '#999999',
    },
    button: {
        color: '#cccccc',
        '&:hover': {
            color: 'white'
        },
        textTransform: 'capitalize',
        '&.apply-button': {
            backgroundColor: 'slategray',
            border: '1px solid slategray'
        },
        '&.show-details-button': {
            border: '1px solid #3f51b5'
        },
        '&.apply-button:hover, &.show-details-button:hover': {
            border: '1px solid white'
        },
    },
    dateInputRoot: {
        '& .MuiInputBase-root': {
            fontSize: '100%',
            color: '#dedede',
            height: 45,
            border: '1px solid #686868',
            '&:hover': {
                border: '1px solid white',
            },
            '& input:hover, & input:focus': {
                color: 'white'
            },
        },
        '& .Mui-error': {
            border: '1px solid red',
        },
        '& .MuiButtonBase-root': {
            color: '#dedede',
            '&:hover': {
                color: 'white'
            }
        },
        // '& .MuiInput-underline::before': {
        //     borderBottomColor: 'grey',
        // },
        // '& .MuiInput-underline::after': {
        //     borderBottom: '1px solid #dedede'
        // },
        // '& .MuiInput-underline::after:hover': {
        //     borderBottomColor: 'yellow'
        // }
    },
    groupContainer: {
        width: 270,
        height: 45,
        border: '1px solid #7f7f7f',
        '&:hover': {
            border: '1px solid white'
        },
        '& .MuiFormControlLabel-label': {
            color: 'whitesmoke',
            fontSize: '0.95em',
            fontWeight: 'normal',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.3rem'
        }
    },
}))

let __initialized = false

function EventsFilterControl({ onShowDetail, onDateRangeChanged, onSearchResult, onShowHideAdvSearch, onPolygonChange }) {

    __initialized = false

    const classes = useStyles()
    const scopeRef = useRef()

    const minDate = AppState.eventMinDate // eventMinMaxDate[0] // d3.min(events, e => moment.utc(e.datetime).startOf('day').valueOf())
    const maxDate = AppState.eventMaxDate // eventMinMaxDate[1] // d3.max(events, e => moment.utc(e.datetime).startOf('day').valueOf())
    const nDays = moment.duration(maxDate - minDate).asDays()
    const roundCeilToThousand = (value) => (value < 1000) ? 1000 : Math.ceil(value / 1000) * 1000
    const maxScale = roundCeilToThousand(nDays)
    const scaleFactor = maxScale / 100

    const rangeScale = d3.scaleLinear()
        .domain([0, maxScale])
        .range([minDate, maxDate])

    const rangeScaleDate = d3.scaleLinear()
        .domain([minDate, maxDate])
        .range([0, maxScale])

    const sliderValueToDateText = (sValue) => moment.utc(rangeScale(sValue * scaleFactor)).format('MM/DD/YYYY')
    const sliderDateToValue = (sDate) => rangeScaleDate(moment.utc(sDate).valueOf()) / scaleFactor
    const toPlainUTCDateMs = (dt) => moment.utc(dt).startOf('day').valueOf()
    const toPlainDateText = (dt) => moment.utc(dt).format('MM/DD/YYYY')
    const toAddDay = (dt, nDays) => moment.utc(dt).startOf('day').add(nDays, 'day').toDate()

    const [startDateConstraintMessageNode, setStartDateConstraintMessageNode] = useState(null);
    const [endDateConstraintMessageNode, setEndDateConstraintMessageNode] = useState(null);
    const searchEventsPayload = { ...AppState.searchEventsPayload }

    const dateRangeStartText = toPlainDateText(searchEventsPayload.dateStart)
    const dateRangeEndText = toPlainDateText(searchEventsPayload.dateEnd)
    
    useEffect(() => {
        // reset values everytime dates change
        setStartDateConstraintMessageNode(null);
        setEndDateConstraintMessageNode(null);

        // conditions to check date interval for correct error message
        let start = new Date(searchEventsPayload.dateStart);
        let end = new Date(searchEventsPayload.dateEnd);
        let today = new Date();

        // catch both if they occur
        if (end > today) {
            // endDate hasn't occured yet
            setEndDateConstraintMessageNode(() => (
                <span style={{ color: 'red', fontSize: '0.5rem' }}>
                    Please use a date before or<br/> 
                    {`equal to today: ${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`}
                </span>
            ))
        }
        if (start > end) {
            // start date is further than end
            setStartDateConstraintMessageNode(() => (
                <span style={{ color: 'red', fontSize: '0.5rem' }}>
                    {`Start Date must be`}<br />
                    {`before or equal to End Date`}
                </span>
            ))
        }
    }, [searchEventsPayload.dateStart, searchEventsPayload.dateEnd]);

    const [inputMapValue, setInputMapValue] = useState({
        latitude: searchEventsPayload.latitude,
        longitude: searchEventsPayload.longitude,
        latitudeDelta: searchEventsPayload.latitudeDelta,
        longitudeDelta: searchEventsPayload.longitudeDelta,
        latitudeError: false,
        longitudeError: false,
        latitudeDeltaError: false,
        longitudeDeltaError: false,
        keywords: searchEventsPayload.keywords,
        keywordsError: false,
        duration1: searchEventsPayload.duration1,
        duration1Error: false,
        durationOperator: searchEventsPayload.durationOperator,
        duration2: searchEventsPayload.duration2,
        duration2Error: false,
    })
    const [dateRange, setDateRange] = useState([dateRangeStartText, dateRangeEndText])
    const [sliderValue, setSliderValue] = useState([sliderDateToValue(dateRangeStartText), sliderDateToValue(dateRangeEndText)])
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(searchEventsPayload.advancedSearch)
    const [allEventsFlag, setAllEventsFlag] = useState(true)
    const [isReset, setIsReset] = useState(false)
    const [detectedByMap, setDetectedByMap] = useState(searchEventsPayload.detectedByMap)
    const [confidenceRatingMap, setConfidenceRatingMap] = useState(searchEventsPayload.confidenceRatingMap)

    //
    // event handlers... 
    //
    const handleDetectedByCheckChange = name => event => {
        let _detectedByMap = { ...detectedByMap }
        switch (name){
            case 'GOES-EAST':
                _detectedByMap['GLM-16'] = event.target.checked
                setDetectedByMap(_detectedByMap)
                break;
            case 'GOES-WEST':
                _detectedByMap['GLM-17'] = event.target.checked
                _detectedByMap['GLM-18'] = event.target.checked
                setDetectedByMap(_detectedByMap)
                break;
            default:
                _detectedByMap[name] = event.target.checked
                setDetectedByMap(_detectedByMap)
        } 

    }
    const handleConfidenceRatingCheckChange = name => event => {
        let _confidenceRatingMap = { ...confidenceRatingMap }
        _confidenceRatingMap[name] = event.target.checked
        setConfidenceRatingMap(_confidenceRatingMap)
    }
    const handleDateRangeStartChange = (date) => {
        if (date + '' === 'Invalid Date') return

        const tmpDate = new Date(date) + ''
        if (tmpDate === 'Invalid Date') return
        const formattedDate = moment(tmpDate).format('MM/DD/YYYY');

        const sStartValue = sliderDateToValue(formattedDate)
        const _sliderValue = [sStartValue, sliderValue[1]]
        const _dateRange = [formattedDate, dateRange[1]]

        setSliderValue(_sliderValue)
        setDateRange(_dateRange)
        updateAllEventsFlag(_dateRange)
    }
    const handleDateRangeEndChange = (date) => {
        if (date + '' === 'Invalid Date') return

        const tmpDate = new Date(date) + ''
        if (tmpDate === 'Invalid Date') return
        const formattedDate = moment(tmpDate).format('MM/DD/YYYY');

        const sEndValue = sliderDateToValue(formattedDate)
        const _sliderValue = [sliderValue[0], sEndValue]
        const _dateRange = [dateRange[0], formattedDate]

        setSliderValue(_sliderValue)
        setDateRange(_dateRange)
        updateAllEventsFlag(_dateRange)
    }
    const handleSliderChange = (event, newValue) => {
        const _dateRange = [sliderValueToDateText(newValue[0]), sliderValueToDateText(newValue[1])]
        setSliderValue(newValue)
        setDateRange(_dateRange)
        updateAllEventsFlag(_dateRange)
    }
    const handleShowDetailsClick = (e) => {
        onShowDetail && onShowDetail()
    }
    const handleShowHideAdvSearchClick = (event) => {
        setShowAdvancedSearch(!showAdvancedSearch)
        onShowHideAdvSearch && onShowHideAdvSearch(!showAdvancedSearch)
    }
    const handleKeyUp = name => event => {
        const { keyCode } = event
        const ARROW_KEY_UP = 38
        const ARROW_KEY_DOWN = 40
        let tmpValue = event.target.value.trim()

        if (tmpValue.length > 0 && ['latitude', 'longitude', 'latitudeDelta', 'longitudeDelta'].indexOf(name) !== -1) {
            tmpValue = parseFloat(tmpValue)
            if (keyCode === ARROW_KEY_UP) {
                tmpValue = tmpValue + 1
            } else if (keyCode === ARROW_KEY_DOWN) {
                tmpValue = tmpValue - 1
            }
            tmpValue = (name === 'latitude' || name === 'longitude') ? tmpValue.toFixed(3) : tmpValue.toFixed(1)
        }

        const value = tmpValue
        const valid = validateEntry(name, value)
        let objKeyValue = { [name + 'Error']: !valid }
        let _inputMapValue = { ...inputMapValue, ...objKeyValue }
        if (valid === true &&
            (value === '' || keyCode === ARROW_KEY_DOWN || keyCode === ARROW_KEY_UP)) {
            objKeyValue = { [name]: value }
            _inputMapValue = { ...inputMapValue, ...objKeyValue }
        }
        setInputMapValue(_inputMapValue)
    }
    // const handleBlur = name => event => {
    //     const value = event.target.value.trim()
    //     if (value === '' && (name === 'longitudeDelta' || name === 'latitudeDelta')) {
    //         let objKeyValue = { [name + 'Error']: false }
    //         let _inputMapValue = { ...inputMapValue, ...objKeyValue }

    //         objKeyValue = { [name]: '1.0' }
    //         _inputMapValue = { ...inputMapValue, ...objKeyValue }
    //         setInputMapValue(_inputMapValue)
    //     }
    // }
    const handleChange = name => event => {
        //console.log('DEBUG: handleChange name', name, event.target.value)
        const objKeyValue = { [name]: event.target.value }
        const _inputMapValue = { ...inputMapValue, ...objKeyValue }
        setInputMapValue(_inputMapValue)
    }
    const handleAllEventsClick = (event) => {
        selectAllEvents()
    }
    const applySearch = (cb) => {

        Util.debounce2(() => {
            const { keywords, latitude, latitudeDelta, longitude, longitudeDelta, duration1, durationOperator, duration2 } = inputMapValue
            const payload = {
                keywords,
                dateStart: dateRange[0],
                dateEnd: dateRange[1],
                latitude,
                latitudeDelta,
                longitude,
                longitudeDelta,
                duration1,
                durationOperator,
                duration2,
                detectedByMap,
                confidenceRatingMap,
                publicOnly: true,
                advancedSearch: showAdvancedSearch
            }
            AppState.searchEvents(payload, (res) => {
                if (!res.success) {
                    cb && cb()
                    return console.log('DEBUG:error', res.error)
                }
                onSearchResult && onSearchResult(res.data)
                setTimeout(() => {
                    cb && cb()
                }, 1000)
            })
        }, 1000)

    }
    const handleApplySearchClick = (event, button) => {
        applySearch(() => {
            button.reset()
        })
    }
    const handleResetClick = (event, button) => {
        const resetPayload = { ...AppState.resetSearchEventsPayload }
        const dr = [resetPayload.dateStart, resetPayload.dateEnd]
        setIsReset(true)
        setSliderValue([0, 100])
        setDateRange(dr)
        setDetectedByMap(resetPayload.detectedByMap)
        setConfidenceRatingMap(resetPayload.confidenceRatingMap)
        setAllEventsFlag(true)
        setInputMapValue({
            latitude: '',
            longitude: '',
            latitudeDelta: '1.0',
            longitudeDelta: '1.0',
            latitudeError: false,
            longitudeError: false,
            latitudeDeltaError: false,
            longitudeDeltaError: false,
            duration1: '',
            duration1Error: false,
            durationOperator: '>',
            duration2: '',
            duration2Error: false,
            keywords: '',
            keywordsError: false,
        })
        setTimeout(() => {
            button.reset()
        }, 1000)
    }

    const handleOnFilterChanged = (filter) => {
        // console.log('DEBUG: EventsFilterControl handleOnFilterChanged', filter)

        const searchEventsPayload = { ...AppState.searchEventsPayload }

        // up to date values
        const _inputMapValue = {
            latitude: searchEventsPayload.latitude,
            longitude: searchEventsPayload.longitude,
            latitudeDelta: searchEventsPayload.latitudeDelta,
            longitudeDelta: searchEventsPayload.longitudeDelta,
            latitudeError: false,
            longitudeError: false,
            latitudeDeltaError: false,
            longitudeDeltaError: false,
            duration1: searchEventsPayload.duration1,
            duration1Error: false,
            durationOperator: searchEventsPayload.durationOperator,
            duration2: searchEventsPayload.duration2,
            duration2Error: false,
            keywords: searchEventsPayload.keywords,
            keywordsError: false,
        }
        const _dateRange = [searchEventsPayload.dateStart, searchEventsPayload.dateEnd]
        const _detectedByMap = searchEventsPayload.detectedByMap
        const _confidenceRatingMap = searchEventsPayload.confidenceRatingMap

        setDateRange(_dateRange)
        setInputMapValue(_inputMapValue)
        setDetectedByMap(_detectedByMap)
        setConfidenceRatingMap(_confidenceRatingMap)
    }

    const handleOnOpenMainDrawer = () => {
        setShowAdvancedSearch(true)
        onShowHideAdvSearch && onShowHideAdvSearch(true)
    
    }

    //
    // functions ...
    //
    const selectAllEvents = () => {
        const _dateRange = [toPlainDateText(AppState.eventMinDate), toPlainDateText(AppState.eventMaxDate)]
        setSliderValue([0, 100])
        setDateRange(_dateRange)
        updateAllEventsFlag(_dateRange)
    }
    const updateAllEventsFlag = (dRange = dateRange) => {
        const _start = toPlainUTCDateMs(dRange[0])
        const _end = toPlainUTCDateMs(dRange[1])
        const _minDate = toPlainUTCDateMs(minDate)
        const _maxDate = toPlainUTCDateMs(maxDate)
        const _allEventsFlag = (_start <= _minDate && _end >= _maxDate)
        if (allEventsFlag !== _allEventsFlag) {
            setAllEventsFlag(_allEventsFlag)
        }
    }

    const validateEntry = (name, value) => {
        if (value.trim() === '') return true
        let retValue = false
        let tmpValue
        switch (name) {
            case 'latitude':
                tmpValue = parseFloat(value)
                retValue = (tmpValue >= -90.0 && tmpValue <= 90.0)
                break
            case 'longitude':
                tmpValue = parseFloat(value)
                retValue = (tmpValue >= -180.0 && tmpValue <= 180.0)
                break
            case 'latitudeDelta':
                tmpValue = parseFloat(value)
                retValue = (tmpValue >= 0.0 && tmpValue <= 90.0)
                break
            case 'longitudeDelta':
                tmpValue = parseFloat(value)
                retValue = (tmpValue >= 0.0 && tmpValue <= 180.0)
                break
            default:
                retValue = true
        }
        return retValue
    }

    updateAllEventsFlag()

    const generatePolyIfSpecified = (inputMapValue) => {
        const _inputMapValue = { ...inputMapValue }
        const { latitude, longitude, latitudeDelta, longitudeDelta, latitudeError, longitudeError, latitudeDeltaError, longitudeDeltaError } = _inputMapValue
        if (!latitudeError && !longitudeError && !latitudeDeltaError && !longitudeDeltaError) {
            let poly = []
            if (!isNaN(parseFloat(latitude)) && !isNaN(parseFloat(longitude)) && !isNaN(parseFloat(latitudeDelta)) && !isNaN(parseFloat(longitudeDelta))) {
                poly = Util.generatePolygon({
                    latitude,
                    latitudeDelta,
                    longitude,
                    longitudeDelta
                })
            }
            onPolygonChange && onPolygonChange(poly)
        }
    }

    useEffect(() => {
        // console.log('DEBUG: EventsFilterControl - searchEventsPayload, useEffect, __initialized', __initialized, searchEventsPayload, inputMapValue)
        if (__initialized === true) {
            if (isReset === true || showAdvancedSearch === false) {
                applySearch()
                if (isReset === true) {
                    setIsReset(false)
                }
            }
        }

        generatePolyIfSpecified(inputMapValue)
       
        // console.log('DEBUG: EventsFilterControl - isFullscreen', AppState.isFullscreen)

        AppState.addEventListener(C_EVENT_IDS.C_ON_FILTER_CHANGED, handleOnFilterChanged)
        AppState.addEventListener(C_EVENT_IDS.C_ON_OPEN_MAIN_DRAWER, handleOnOpenMainDrawer)

        return () => {
            __initialized = true
            AppState.removeEventListener(C_EVENT_IDS.C_ON_FILTER_CHANGED, handleOnFilterChanged)
            AppState.removeEventListener(C_EVENT_IDS.C_ON_OPEN_MAIN_DRAWER, handleOnOpenMainDrawer)
        }
    }, [sliderValue, dateRange, allEventsFlag, inputMapValue])

    return (
        <div className={classes.root} ref={scopeRef}>
            <div style={{ borderLeft: '1px solid #454545', borderRight: '1px solid #454545' }}>
                <Slider
                    color="secondary"
                    value={sliderValue}
                    min={0}
                    max={100}
                    step={scaleFactor / maxScale}
                    onChange={handleSliderChange}
                    getAriaLabel={() => "range-slider"}
                    classes={{ track: classes.sliderTrack, rail: classes.sliderRail, thumb: classes.sliderThumb }}
                />
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', flexWrap: 'wrap' }}>
                {dateRange && 
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className="select-none" style={{ flex: 'none', paddingTop: 6, marginRight: 8, display: 'flex', justifyContent: 'center' }}>
                        <div className={classes.labelColor} style={{ flex: 'none', marginTop: 8, marginRight: 10 }}>
                            <Checkbox
                                onClick={handleAllEventsClick}
                                disabled={allEventsFlag}
                                checked={allEventsFlag}
                                style={{ color: 'white', padding: '0px 5px 0px 0px' }}
                                size="small"
                                inputProps={{ 'aria-label': 'checkbox with small size' }}
                            />
                            Reset all dates
                        </div>
                        <div className={classes.labelColor} style={{ flex: 'none', marginTop: 8, marginLeft: 5, marginRight: 10 }}>|</div>
                        <label className={classes.labelColor} htmlFor='mui-1' style={{ flex: 'none', marginTop: 8, ariaLabel: 'Start_Date' }}>Start Date:&nbsp;</label>
                        <div style={{ flex: 1 }} aria-label="date-calender-picker">
                            <DatePicker 
                                className={classes.dateInputRoot}
                                views={['year', 'month', 'day']}
                                label=''
                                id='mui-1'
                                aria-label="date-calender-start"
                                format="MM/dd/yyyy"
                                minDate={dateRange[0] === dateRange[1] ? toAddDay(minDate, 0) : toAddDay(minDate, 1)}
                                maxDate={toAddDay(dateRange[1], 1)}
                                value={new Date(dateRange[0])}
                                onChange={handleDateRangeStartChange}
                                inputProps={{ 'aria-label': 'startDate' }}
                                slotProps={{ popper: { placement: "top" } }}
                            />
                        </div>
                        <label className={classes.labelColor} htmlFor='mui-2' style={{ flex: 'none', marginTop: 8, marginLeft: 10 }}>End Date:&nbsp;</label>
                        <div style={{ flex: 1, marginRight: 5 }} aria-label="date-calender-picker">
                            <DatePicker  
                                className={classes.dateInputRoot}
                                views={['year', 'month', 'day']}
                                label=''
                                id='mui-2'
                                aria-label="date-calender-end"
                                format="MM/dd/yyyy"  
                                minDate={toAddDay(dateRange[0], 1)}
                                maxDate={toAddDay(maxDate, 1)}     
                                value={new Date(dateRange[1])}   
                                onChange={handleDateRangeEndChange}
                                inputProps={{ 'aria-label': 'endDate' }}
                                slotProps={{ popper: { placement: "top" } }}
                            />
                        </div>
                    </div>     
                </LocalizationProvider>}
                {showAdvancedSearch &&
                    <React.Fragment>
                        <GroupContainer style={{ width: 630, marginTop: 6, marginLeft: 0, marginRight: 8 }} className={classes.groupContainer} labelStyle={{ backgroundColor: '#232323', color: 'white', fontSize: '0.75em' }} label='Detected By'>
                            <FormGroup style={{ flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={detectedByMap['GLM-16']} onChange={handleDetectedByCheckChange('GOES-EAST')} value="GOES-EAST" />
                                    }
                                    label="GOES-EAST"
                                    style={{ marginRight: 15 }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={detectedByMap['GLM-17']&&detectedByMap['GLM-18']} onChange={handleDetectedByCheckChange('GOES-WEST')} value="GOES-WEST" />
                                    }
                                    label="GOES-WEST"
                                    style={{ marginRight: 15 }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={detectedByMap['Stereo']} onChange={handleDetectedByCheckChange('Stereo')} value="Stereo" />
                                    }
                                    label="Stereo"
                                    style={{ marginRight: 15 }}
                                />
                                <Divider orientation="vertical"  flexItem style={{ borderColor: 'white', marginRight: 15 }}/>
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={detectedByMap['GLM-16']} onChange={handleDetectedByCheckChange('GLM-16')} value="GLM-16" />
                                    }
                                    label="GLM-16"
                                    style={{ marginRight: 15}}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={detectedByMap['GLM-17']} onChange={handleDetectedByCheckChange('GLM-17')} value="GLM-17" />
                                    }
                                    label="GLM-17"
                                    style={{ marginRight: 15 }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={detectedByMap['GLM-18']} onChange={handleDetectedByCheckChange('GLM-18')} value="GLM-18" />
                                    }
                                    label="GLM-18"
                                    style={{ marginRight: 0}}
                                />
                            </FormGroup>
                        </GroupContainer>
                        <GroupContainer style={{ width: 230, marginTop: 6, marginLeft: 0, marginRight: 8 }} className={classes.groupContainer} labelStyle={{ backgroundColor: '#232323', color: 'white', fontSize: '0.75em' }} label='Confidence Rating'>
                            <FormGroup style={{ flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={confidenceRatingMap['low']} onChange={handleConfidenceRatingCheckChange('low')} value="low" />
                                    }
                                    label="Low"
                                    style={{ marginRight: 10, paddingLeft: 5, fontSize: '0.85rem', fontWeight: 'normal' }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={confidenceRatingMap['medium']} onChange={handleConfidenceRatingCheckChange('medium')} value="medium" />
                                    }
                                    label="Medium"
                                    style={{ marginRight: 10 }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox style={{ padding: '0px 5px', color: 'whitesmoke' }} checked={confidenceRatingMap['high']} onChange={handleConfidenceRatingCheckChange('high')} value="high" />
                                    }
                                    label="High"
                                    style={{ marginRight: 0 }}
                                />
                            </FormGroup>
                        </GroupContainer>
                        <GroupContainer style={{ width: 'auto', marginTop: 6, marginLeft: 0, marginRight: 8 }} className={classes.groupContainer} labelStyle={{ backgroundColor: '#232323', color: 'white', fontSize: '0.75em' }} label='Duration (seconds)'>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 'none', width: 65 }}>
                                    <CssBasicTextField
                                        style={{ width: 65, margin: 0, fontSize: '1em' }}
                                        label={null}
                                        error={inputMapValue.duration1Error}
                                        value={inputMapValue.duration1}
                                        onKeyUp={handleKeyUp('duration1')}
                                        onChange={handleChange('duration1')}
                                        margin="dense"
                                        variant='standard'
                                        InputProps={{
                                            inputProps: { maxLength: 7, decimalScale: 3 },
                                            inputComponent: TextFieldFormat,
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 'none', width: 65, marginLeft:5, marginRight:15 }}>
                                    <select onChange={handleChange('durationOperator')} value={inputMapValue.durationOperator} className="operator" style={{backgroundColor: '#343434', border: 'none', height: 27, color: 'white'}}>
                                        <option value='>'>&gt;</option>
                                        <option value='>='>&gt;&#61;</option>
                                        <option value='<'>&lt;</option>
                                        <option value='<='>&lt;&#61;</option>
                                        <option value='range'>Range</option>
                                    </select>
                                </div>
                                {inputMapValue.durationOperator === 'range' &&
                                    <div style={{ flex: 'none', width: 65 }}>
                                        <CssBasicTextField
                                            style={{ width: 65, margin: 0 }}
                                            label={null}
                                            error={inputMapValue.duration2Error}
                                            value={inputMapValue.duration2}
                                            onKeyUp={handleKeyUp('duration2')}
                                            onChange={handleChange('duration2')}
                                            margin="dense"
                                            variant='standard'
                                            InputProps={{
                                                inputProps: { maxLength: 7, decimalScale: 3 },
                                                inputComponent: TextFieldFormat,
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </GroupContainer>
                        <CssTextField
                            style={{ width: 90, marginBottom: 10 }}
                            label="Latitude"
                            error={inputMapValue.latitudeError}
                            value={inputMapValue.latitude}
                            onKeyUp={handleKeyUp('latitude')}
                            onChange={handleChange('latitude')}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                inputProps: { maxLength: 6, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                        <CssTextField
                            style={{ width: 90, marginBottom: 10 }}
                            label="Longitude"
                            error={inputMapValue.longitudeError}
                            value={inputMapValue.longitude}
                            onKeyUp={handleKeyUp('longitude')}
                            onChange={handleChange('longitude')}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                inputProps: { maxLength: 6, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                        <CssTextField
                            style={{ width: 90, marginBottom: 10 }}
                            label="Δ&nbsp;Latitude"
                            error={inputMapValue.latitudeDeltaError}
                            value={inputMapValue.latitudeDelta}
                            onKeyUp={handleKeyUp('latitudeDelta')}
                            onChange={handleChange('latitudeDelta')}
                            // onBlur={handleBlur('latitudeDelta')}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                inputProps: { maxLength: 5, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                        <CssTextField
                            style={{ width: 90, marginBottom: 10 }}
                            label="Δ&nbsp;Longitude"
                            error={inputMapValue.longitudeDeltaError}
                            value={inputMapValue.longitudeDelta}
                            onKeyUp={handleKeyUp('longitudeDelta')}
                            onChange={handleChange('longitudeDelta')}
                            // onBlur={handleBlur('longitudeDelta')}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                inputProps: { maxLength: 5, decimalScale: 1 },
                                inputComponent: TextFieldFormat,
                            }}
                        />
                        <CssTextField
                            style={{ width: 150, marginBottom: 10 }}
                            label="Keywords"
                            error={inputMapValue.keywordsError}
                            value={inputMapValue.keywords}
                            onKeyUp={handleKeyUp('keywords')}
                            onChange={handleChange('keywords')}
                            margin="dense"
                            variant="outlined"
                        />
                        <ButtonProgress
                            onClick={handleApplySearchClick}
                            variant="contained"
                            color="secondary"
                            className={classNames(classes.button, 'apply-button')}
                            style={{ marginRight: 5, width: 100, marginBottom: 10, marginTop: 6 }}
                            startIcon={<SendIcon />}>Apply</ButtonProgress>
                        <ButtonProgress
                            onClick={handleResetClick}
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            style={{ marginBottom: 10, marginTop: 6}}
                            startIcon={<ClearIcon />}>Reset</ButtonProgress>
                    </React.Fragment>
                }
                {!showAdvancedSearch &&
                    <React.Fragment>
                        <div style={{ flex: 1, paddingBottom: 5, }}>&nbsp;</div>
                        <div style={{ flex: 'none', paddingTop: 5, }}>
                            <Button
                                onClick={handleShowHideAdvSearchClick}
                                variant="text"
                                className={classes.button}
                                style={{ marginRight: 5 }}
                                endIcon={showAdvancedSearch ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>{showAdvancedSearch ? 'Hide' : 'Show'} Advanced Search</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classNames(classes.button, 'show-details-button')}
                                endIcon={<ArtTrackIcon />}
                                onClick={handleShowDetailsClick}>Show Details</Button>
                        </div>
                    </React.Fragment>
                }
            </div>
            {showAdvancedSearch &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                    <div style={{ flex: 'none', paddingBottom: 5, }}>
                        <Button
                            onClick={handleShowHideAdvSearchClick}
                            variant="text"
                            className={classes.button}
                            style={{ marginRight: 5 }}
                            endIcon={showAdvancedSearch ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}>{showAdvancedSearch ? 'Hide' : 'Show'} Advanced Search</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classNames(classes.button, 'show-details-button')}
                            endIcon={<ArtTrackIcon />}
                            onClick={handleShowDetailsClick}>Show Details</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default EventsFilterControl
