
import React from 'react'

export default class _Base extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false
        this.viewName = ''
        this.validateGlobalObjects = []
        this.state = {
            isReady: false
        }
        this.imageServerUrl = window.BOLIDES_APP_IMAGE_SERVER_URL
      
        // delegate showMessage from props (coming from (HOC) withSharedFeatures)
        this.showMessage = this.props.showMessage
        this.showStickyMessage = this.props.showStickyMessage

        // set title for 508 compliance
        if (props.title) {
            document.title = props.title;
        }
    }
 
    componentDidMount() {
        this._isMounted = true
        if (this.props.getInstance) {
            this.props.getInstance(this)
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }
}