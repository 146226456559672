import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'

// jss
import { root, container } from '../assets/jss/index'
import figure1 from '../assets/img/readme_figure1.jpg'
import Globals from '../variables/globals'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    color: 'black',
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content-page": {
      flex: 1,
      overflowY: 'auto',
      padding: '40px 40px',
      '& div.table-wrap': {
        background: 'none'
      },
      '& a': {
        color: 'blue',
      }
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: "100%"
  },
  title: {
    fontSize: 24,
    fontWeight: 'normal',
    lineHeight: 1.25,
  },
  toolbar: theme.mixins.toolbar,
  bc_link: {
    width: 100,
    fontSize: '1rem',
    marginBottom:10,
    display: 'flex',
    color: '#rgba(0,0,0,0.6)',
    '&:hover': {
      color: 'black'
    }
  },
  bc_icon: {
    marginRight: theme.spacing(0.3),
    width: 20,
    height: 20,
  },
})

const Info = (props) => {
  const { classes, ...rest } = props
  const viewName = 'About'
  Globals.viewsAnimationTimeoutDict[viewName] = Globals.viewsAnimationTimeoutDict[viewName] === undefined ? 2000 : 0
  const aniTimeout = Globals.viewsAnimationTimeoutDict[viewName]

  
  return (
    <div className={classNames('full-screen', classes.root)}>
      <div className={classes.container}>
        <div className='app-bar'>
          <MainAppBar showBackButton={true} {...rest} />
        </div>
        <div className={classes.toolbar}></div>
        <Fade in={true} timeout={aniTimeout}>
          <div className='content-page' style={{ padding: '10px 28px' }}>

            <Link to="/info" className={classes.bc_link}>
              <ArrowBackIcon className={classes.bc_icon} />
               Back
            </Link>

            <h1>Bolide Detections from Geostationary Lightning Mapper</h1>
            <p>
              The Geostationary Lightning Mapper, aboard the GOES East (GLM-16) and GOES West (GLM-17, GLM-18) satellites, is designed to capture natural lightning activity, but can also detect bright meteors, called bolides (e.g. Jenniskens et al 2018, Rumpf et al 2019).   
              These two space-based remote sensing systems are operated by the National Oceanic and Atmospheric Administration (NOAA) and became operational in December 2017 (GOES 16), in February 2019 (GOES 17), and in January 2023 (GOES 18).   
              GLM's large coverage area allows it to capture unprecedented numbers of bolides.  GLM data is publicly accessible.  
              The bolides are detected by the GLM pipeline (Smith et al 2021). The published light curves and other data products are generated using the standard GLM lightning data acquisition and processing pipeline, which makes several assumptions that are not valid for bolides, so additional corrections may be necessary for some purposes (Morris et al 2022).
            </p>

            <h2>GLM</h2>
            <p>GLM provides continuous coverage from geostationary orbit and covers approximately half of the Earth with the Americas as the primary land-mass focus. Its spatial resolution ranges from 8-14 km. Currently, three GLM sensors are in space: GOES East (GLM-16) occupies the geostationary orbital slot at 75.2 degree west and GOES West (GLM-17, GLM-18) are located at 137.2 degree west. Figure 1 shows the geographical coverage for both GLM sensors over a map of lightning intensity. Parts of the United States and the Pacific have overlapping fields of view of the two GLM sensors potentially enabling stereo bolide detections.</p>
            <p>GLM has a staring CCD imager (1372 x 1300 pixels) with a narrow 1.1 nm pass-band centered at 777.4 nm, a wavelength associated with the neutral atomic oxygen emission (OI) line of the lightning spectrum (GOES-R Data Book 2019). Transient light events are sampled at a rate of 500 frames per second (2 ms).</p>
            <p>
              <span className="image-container">
                <img src={figure1} alt='' height={250}></img>
              </span>
            </p>

            <p>
              Figure 1. Combined field of view GOES 16 (75.2W) and GOES 17 (137.2W) superimposed on 10 years of lightning observations from the NASA Lightning Imaging Sensor on board the Tropical Rainfall Measuring Mission and Optical Transient Detector.  (Goodman et al. 2013).
            </p>

            <h2>Bolide Signals</h2>
            <p>GLM’s meteor detection range starts at approximately -14 visual magnitude (Jenniskens et al. 2018). This visual magnitude is about twice as bright as the full moon and corresponds to an entering body of approximately decimeter-size (in diameter).   Meteoroids larger than a meter may saturate GLM's sensor.  The NASA Asteroid Threat Assessment Project (ATAP) has developed the capability to find bolide signatures in the GLM Level 2 (L2) data product. Candidate bolide signatures are found as documented in Rumpf et al. 2019.  All candidate events are subsequently vetted by a subject matter expert in order to eliminate false positives.</p>
            <p>The bolide signals reported here are group values extracted from GLM L2 netCDF files.  A description of the original GLM netCDF file format can be found in the GOES-R Series Product Definition and User's Guide (PUG) volume 5.   The values available on the website and where they originated in the GLM L2 netCDF files are specified below.  No corrections or further processing has been performed on the reported signals.</p>  

            <div className="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>quantity</th>
                    <th>netCDF label</th>
                    <th>units</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>time</td>
                    <td>group_time_offset</td>
                    <td>seconds since 2016-10-05 13:12:44.839&nbsp;</td>
                  </tr>
                  <tr>
                    <td>longitude</td>
                    <td>group_lon</td>
                    <td>degrees east</td>
                  </tr>
                  <tr>
                    <td>latitude</td>
                    <td>group_lat</td>
                    <td>degrees north</td>
                  </tr>
                  <tr>
                    <td>energy</td>
                    <td>group_energy</td>
                    <td>joules</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2>Additional Information</h2>
            <p>
              When possible, our subject matter experts tag each candidate bolide event with additional info to provide context.  These items include information on how we identified the signal, other data sources we're aware of for each event, and a confidence level.   Most of the listed events were found using an algorithm we are running at NASA Ames Research Center based on Rumpf et al 2019.  The confidence level is a qualitative assessment provided by a subject matter expert of how likely the candidate event is due to a bolide.  The subject matter experts make this assessment based on additional available data (such as stereo detections, detections by other systems, and the presence of lightning in the area) as well as the properties of the observed GLM light curve and ground track.
            </p>

            <h2>Caveats</h2>
            <p>
              The instrument, on-board data processing, and on-ground data analysis pipeline were designed and developed to facilitate the identification of and study of lightning.  Several of the assumptions made during that process are not appropriate for the signals from bolides.  As a result, for detailed, quantitative studies of bolides, corrections may be necessary to the energies and locations reported here.  (Jenniskens et al. 2018).    Known issues with the reported quantities are:
            </p>
            <ul>
              <li>
                GLM implements an onboard, dynamic background subtraction.  Drift in this background level can induce errors in the reported bolide energy.
              </li>
              <li>
                The reported energy is in the narrow GLM bandpass (Edgington 2019), not the total energy emitted by the bolide.
              </li>
              <li>
                The spectral response of the instrument depends on view angle.  The reported energies are corrected for this assuming all the flux is emitted in the 777nm Oxygen lines.  That assumption may not be appropriate for bolides.
              </li>
              <li>
                The data from bright and long bolides can suffer from a variety of instrumental effects including saturation, blooming, rebound, and first-in first-out overflows.
              </li>
              <li>
                The reported latitude, longitude, and time assumes that the light was emitted at an altitude appropriate for lightning.  The assumed altitude depends on location on the globe and is represented by an ellipsoid whose altitude is 16km at the equator and 6km at the poles.   Bolides usually emit light at much higher altitudes, and as a result the reported latitude, longitude, and timing may suffer from parallax errors.
              </li>
            </ul>
            <p>
              The data provided here does not currently include corrections for any of these effects. 
            </p>

            <h2>Additional Websites of Interest</h2>
            <ul>
              <li><span style={{ color: 'rgb(34,34,34)' }}>The original netCDF files for GLM data can be obtained from the NOAA Comprehensive&nbsp;Large&nbsp;</span>Array-Data Stewardship System (<a target="_blank" href="https://www.class.noaa.gov/" className="external-link" rel="noopener noreferrer nofollow">CLASS</a>).</li>
              <li>The Satellite Loop Interactive Data Explorer in Real-Time (<a target="_blank" href="http://rammb-slider.cira.colostate.edu/" className="external-link" rel="noopener noreferrer nofollow">SLIDER</a>) provides an interactive visual interface to GLM group energy, GLM group flash density data, and GOES Advanced Baseline Imager (ABI) data.</li>
              <li><span style={{ color: 'rgb(34,34,34)' }}>The <a target="_blank" href="https://cneos.jpl.nasa.gov/fireballs/" className="external-link" rel="noopener noreferrer nofollow">JPL Fireball website</a>&nbsp;provides location and total energy about fireballs and bolides detected by U. S. Government sensors.&nbsp; This data set is complementary to the GLM bolide data which has less spatial coverage but does provide insight into the evolution of the bolide's brightness during atmospheric entry.</span></li>
              <li><span style={{ color: 'rgb(34,34,34)' }}>An interactive bolide and meteor visualizer can be found at <a target="_blank" href="https://bolides.seti.org" className="external-link" rel="noopener noreferrer nofollow">https://bolides.seti.org</a>. This tool can view bolides detected using GLM, U.S. Government Sensors, the Global Meteor Network and the IAU Meteor Data Center</span></li>
            </ul>

            <p><span style={{ fontSize: 20, letterSpacing: '-0.008em' }}>Sponsors</span></p>
            <p>The NASA Asteroid Threat Assessment Project (ATAP) and this website is sponsored by the Planetary Defense Coordination Office (PDCO) at NASA HQ.&nbsp;</p>

            <h2>Acknowledging the Website</h2>
            <p>Please include the following standard acknowledgment in any published material that makes use of the neo-bolide website.</p>
            <blockquote style={{margin: '10px 50px'}}>
              "This research has made use of the neo-bolide.ndc.nasa.gov website, which was developed and operated by NASA's Asteroid Threat Assessment Project thanks to funding from NASA's Planetary Defense Coordination Office."
            </blockquote>

            <p><span style={{ fontSize: 20, letterSpacing: '-0.008em' }}>References</span></p>
            
            <p><span style={{letterSpacing: 0}}><span className="sb_contribution" style={{ color: 'rgb(0,0,0)' }}><span className="sb_authors aug" style={{ color: 'rgb(34,34,34)' }}><span className="sb_author au"><span className="ce_surname snm">GOES-R Series Product Definition and User's Guide (PUG),&nbsp;<a target="_blank" className="external-link" href="https://www.goes-r.gov/products/docs/PUG-L2+-vol5.pdf" rel="noopener noreferrer nofollow">volume 5</a></span></span></span></span></span></p>
            <p><span style={{letterSpacing: 0}}><span className="sb_contribution" style={{ color: 'rgb(0,0,0)' }}><span className="sb_authors aug" style={{ color: 'rgb(34,34,34)' }}><span className="sb_author au"><span className="ce_surname snm"><a target="_blank" href=" https://www.goes-r.gov/downloads/resources/documents/GOES-RSeriesDataBook.pdf" className="external-link" rel="noopener noreferrer nofollow">GOES-R Series Data Book</a></span></span><span className="x" style={{color: 'black'}}>,&nbsp;</span></span><span className="sb_title atl"><span className="sb_maintitle">CDRL PM-14 Rev A. May 2019. NOAA-NASA</span><span className="x" style={{ color: 'rgb(0,0,0)'}}>,&nbsp;</span></span></span><span className="sb_comment" style={{color: 'rgb(34,34,34)'}}>Available at</span><span className="sb_host" style={{ color: 'rgb(0,0,0)' }}><span className="x" style={{ color: 'rgb(0,0,0)'}}>.&nbsp;<a target="_blank" href="https://www.goes-r.gov/downloads/resources/documents/GOES-RSeriesDataBook.pdf" className="external-link" rel="noopener noreferrer nofollow">https://www.goes-r.gov/downloads/resources/documents/GOES-RSeriesDataBook.pdf.</a></span></span></span></p>

            <p><span style={{letterSpacing: 0}}><span className="sb_host" style={{color: 'rgb(0,0,0)'}}><span className="x" style={{color: 'black'}}><a target="_blank" href="https://doi.org/10.1117/12.2536063" className="external-link" rel="noopener noreferrer nofollow">Edgington, Tillier, &amp; Anderson 2019</a>; "Design, calibration, and on-orbit testing of the geostationary lightning mapper on the GOES-R series weather satellite" I<em>nternational Conference on Space Optics&nbsp;– ICSO 2018</em>, Proceedings Volume 1180</span></span></span></p>
            <p className="p1"><a target="_blank" href="https://doi.org/10.1016/j.atmosres.2013.01.006" className="external-link" rel="noopener noreferrer nofollow">Goodman et al. 2013</a>; "The GOES-R Geostationary Lightning Mapper (GLM)"&nbsp; <em>Atmospheric Research</em> 125/126:34–49</p>
            <p><a target="_blank" href="http://doi.org/10.1111/maps.13137" className="external-link" rel="noopener noreferrer nofollow">Jenniskens et al, 2018</a>; "Detection of meteoroid impacts by the Geostationary Lightning Mapper on the GOES-16 satellite", <em>Meteoritics &amp; Planetary Science</em>,&nbsp; 53(12), 2445</p>
            <p><a target="_blank" href="https://doi.org/10.3390/s19051008" className="external-link" rel="noopener noreferrer nofollow">Rumpf et al. 2019</a>; “An Algorithmic Approach for Detecting Bolides with the Geostationary Lightning Mapper”, <em>Sensors,</em>&nbsp;<em>19</em>(5), 1008</p>
            <p><a target="_blank" href="https://www.sciencedirect.com/science/article/pii/S0019103521002451" className="external-link" rel="noopener noreferrer nofollow">Jeffrey C. Smith, et al.</a>, "An automated bolide detection pipeline for GOES GLM", <em>Icarus,</em>&nbsp;Vol. 368, 1 November 2021.</p>
            <p><a target="_blank" href="https://onlinelibrary.wiley.com/doi/full/10.1111/maps.13926" className="external-link" rel="noopener noreferrer nofollow">Robert L. Morris, et al.</a>, "Correction and calibration of atmospheric impact observations in GOES GLM data", <em>Meteoritics &amp; Planetary Science,</em>&nbsp;Volume57, Issue12, pp. 2229-2247 December 2022.</p>

            <p><span style={{ fontSize: 20, letterSpacing: '-0.008em' }}>Contact Us</span>&nbsp;<span style={{ fontSize: '1.5rem' }}>&#9993;</span></p>
            <p>
              <a href="mailto:ARC-bolide-data@mail.nasa.gov" className="external-link" rel="noopener noreferrer nofollow">ARC-bolide-data@mail.nasa.gov</a>&nbsp;
            </p>
            <p><br /></p>
            <p><br /></p>
            <p><br /></p>
          </div>
        </Fade>
      </div>

    </div>
  )
}

Info.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(Info))
