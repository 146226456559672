import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import BaseComponent from '../_Base'
import withSharedFeatures from '../../views/withSharedFeatures'

// jss
import { root } from '../../assets/jss/index'
import { Typography } from '@mui/material'

import AppState from '../../managers/AppStateManager'


const styles = (theme) => ({
    root: {
        ...root,
        margin: 0,
        padding: '0px 0px 10px 0px',
        display: 'flex',
        flexDirection: 'column',
    },
    flexNone: {
        flex: 'none'
    },
    flexAuto: {
        flex: 1,
    },
})

class CsvLoader extends BaseComponent {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            csvContent: ''
        }
        this.updating = false
    }

    _getContent = (cb) => {
        const { urlResource } = this.props
        AppState.getServerResource(urlResource, (res) => {
            let content = ''
            if (!res.success) {
                content = res.error.message
            } else {
                content = res.data.data
            }
            cb(content)
        })
    }

    componentDidUpdate(previousProps, previousState) {
        const { csvContent } = this.state
        this._getContent((content) => {
            if (csvContent !== content) {
                this.setState({
                    csvContent: content
                })
            }
            
        })
    }

    componentDidMount() {
        super.componentDidMount()
        this._getContent((content) => {
            this.setState({
                isReady: true,
                csvContent: content, 
            })
        })
    }

    render() {
        const { classes, urlResource } = this.props
        const { isReady, csvContent } = this.state
        return (
            <div className={classes.root}>
                {!isReady && 
                    <Typography>Loading...</Typography>
                }
                {isReady && 
                    <div>
                        <Typography style={{color: 'white'}}>{urlResource}</Typography>
                        <textarea rows="10" readOnly value={csvContent} style={{width: '100%'}} />
                        <br />
                    </div>
                }
            </div>
        )
    }
}

CsvLoader.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withSharedFeatures(withStyles(styles)(CsvLoader))
