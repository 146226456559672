import React, { Component } from 'react'

class Help extends Component {
  render() {
    return (
      <div>
        <h1>Help component</h1>
      </div>
    )
  }
}

export default Help
