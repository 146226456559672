import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import Popover from '@mui/material/Popover'

const styles = theme => ({
    root: {
        fontSize: '100%',
        width: '100%',
    },
    popoverPaper: {
        padding: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1),
    },
    icon: {
        margin: theme.spacing(1),
    },
})

const EventAttachmentsDownload = (props) => {
    const { classes, attachments, open, anchorEl, onClose } = props
    
    return (
        <Popover
            classes={{ paper: classes.popoverPaper }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {attachments.map((a, key) => {
                    let filenameMap = a.netCdfFilename.map((f, idx) => {
                        const csvUrl = `${window.BOLIDES_APP_CSV_SERVER_URL}${f}.csv`
                        let tmp = f.split('_')
                        tmp.shift()
                        const csvFile = tmp.join('_') + '.csv'
                        return (
                            <a key={`${key}-${idx}`} href={csvUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" className={classes.button}>
                                    {csvFile}
                                    <CloudDownloadIcon className={classes.icon} />
                                </Button>
                            </a>
                        );
                    })
                    return filenameMap;
                })}
            </div>
        </Popover>  
    );
}
 
EventAttachmentsDownload.propTypes = {
    classes: PropTypes.object.isRequired,
}

EventAttachmentsDownload.defaultProps = {
    attachments: []
}
export default withStyles(styles)(EventAttachmentsDownload)
