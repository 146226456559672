class Globals {

    constructor() {
        this._viewsAnimationTimeoutDict = {}
    }

    get viewsAnimationTimeoutDict() {
        return this._viewsAnimationTimeoutDict
    }
}

const globals = new Globals()

export default globals