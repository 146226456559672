import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import _Base from '../_Base'
import Util from '../../utils/Util'
import AppState from '../../managers/AppStateManager'
import ButtonProgress from '../ButtonProgress/ButtonProgress'
import GroupContainer from '../GroupContainer/GroupContainer'

import {
    C_MODE,
} from '../../variables/common'

const styles = theme => ({
    root: {
        fontSize: '100%',
        backgroundColor: '#f8f8ff',
        width: '100%',
    },
    margin: {
        margin: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    container: {
        padding: '10px 10px 10px 10px',
        width: '100%',
        display: 'flex',
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 5,
    },
    button: {
        margin: theme.spacing(1),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
    },
    inputLabel: {
        backgroundColor: '#f8f8ff',
        paddingLeft: 3,
        paddingRight: 3,
    },
    chip: {
        margin: theme.spacing(1),
    },

    formControl: {
        flex: 1,
        alignItems: 'normal',
        marginLeft: 8,
        cursor: 'default',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.8rem',
    },
    actionButtonsCol: {
        flex: 'none',
        width: 165,
        display: 'flex',
        justifyContent: 'space-around',
    }
})

const fieldsToValidate = [
    'userName',
    'email',
    'roles'
]

class UserFormEntry extends _Base {

    getFormData = () => {
        let formData = {}
        fieldsToValidate.forEach((f) => {
            let obj = { [f]: this.state[f] }
            formData = Util.assign({}, formData, obj)
        })
        return formData
    }

    areEntriesValid = () => {
        let invalidEntriesDetected = false
        const ret = this.validateEntries()
        Object.keys(ret).forEach((key) => {
            if (ret[key]) {
                invalidEntriesDetected = true
                return
            }
        })
        if (invalidEntriesDetected) {
            this.setState(ret)
        }
        return !invalidEntriesDetected
    }

    validateEntries = () => {
        let tmpErrorState = {}
        fieldsToValidate.forEach((f) => {
            let obj = { [f + 'Error']: !this._validateEntry(f, this.state[f]) }
            tmpErrorState = Util.assign({}, tmpErrorState, obj)
        })
        return tmpErrorState
    }

    _validateEntry = (name, value) => {
        let retValue = false
        let tmpValue
        switch (name) {
            case 'userName':
                tmpValue = value.trim()
                retValue = tmpValue.length > 0
                break
            case 'email':
                tmpValue = value.trim()
                retValue = (tmpValue.length > 0) && Util.isValidEmail(tmpValue)
                break
            case 'roles':
                retValue = value.length > 0
                break
            default:
                retValue = true
        }
        return retValue
    }

    saveUserData = (cb) => {
        const {
            user,
            userName,
            email,
            roles,
            firstName,
            lastName,
            fullName,
            editMode } = this.state

        const payload = {
            isNew: editMode === C_MODE.C_ADD_MODE,
            id: user ? user._id : null,
            userName: userName,
            email: email,
            roles: roles,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
        }
        AppState.saveUser(payload, cb)
    }

    _userNameExist = (userName, cb) => {
        const { user, editMode } = this.state
        AppState.getUserByUsername(userName, (resp) => {
            if (!resp.success) {
                // fails consider not exist
                cb(false)
            }
            const returnedUser = resp.data
            if (editMode === C_MODE.C_ADD_MODE) {
                cb(returnedUser.hasOwnProperty('_id'))
            } else if (editMode === C_MODE.C_EDIT_MODE) {
                // edit mode
                if (returnedUser.hasOwnProperty('_id')) {
                    if (user &&
                        user._id !== returnedUser._id) {
                        cb(true)
                    } else {
                        cb(false)
                    }
                } else {
                    cb(false)
                }
            } else {
                cb(false)
            }
        })
    }

    _emailExist = (email, cb) => {
        const { user, editMode } = this.state
        AppState.getUserByEmail(email, (resp) => {
            if (!resp.success) {
                cb(false)
            }
            const returnedUser = resp.data
            if (editMode === C_MODE.C_ADD_MODE) {
                cb(returnedUser.hasOwnProperty('_id'))
            } else if (editMode === C_MODE.C_EDIT_MODE) {
                // edit mode
                if (returnedUser.hasOwnProperty('_id')) {
                    if (user &&
                        user._id !== returnedUser._id) {
                        cb(true)
                    } else {
                        cb(false)
                    }
                } else {
                    cb(false)
                }
            } else {
                cb(false)
            }
        })
    }

    handleKeyUp = name => event => {
        const valid = this._validateEntry(name, event.target.value)
        const value = event.target.value

        if (valid && value.length > 2 &&
            (name === 'userName' || name === 'email')) {

            this.setState({
                [name + 'Error']: true
            }, () => {
                const funcExist = name === 'userName' ? this._userNameExist : this._emailExist
                clearTimeout(this._timerId)
                this._timerId = setTimeout(() => {
    
                    funcExist(value, (found) => {
                        this.setState({
                            [name + 'Error']: found
                        }, () => {
                            if (found) {
                                const message = (name === 'userName') 
                                                ? `The user name (${value}) you have entered already exists.`
                                                : `The email (${value}) you have entered already exists.`
                                this.showMessage(message, 'error', true)
                            }
                        })
                    })
                }, 2000)
            })
        } else {
            this.setState({
                [name + 'Error']: !valid
            })
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    handleRoleCheckChange = name => event => {
        let { roles } = this.state
        if (event.target.checked) {
            roles.push(name)
        } else {
            const idx = roles.findIndex(r => r === name)
            if (idx !== -1) {
                roles.splice(idx, 1)
            }
        }
        this.setState({
            roles: roles,
            rolesError: roles.length === 0
        })
    }

    onClickButtonCancel = (event) => {
        const { onCancelEntry } = this.props
        this.setState({
            ...this._generateInitialState()
        }, () => {
            onCancelEntry && onCancelEntry()
        })
    }

    onClickButtonSave = (event, button) => {
        const { userName } = this.state
        const { onSaveEntry } = this.props

        const save = () => {
            if (this.areEntriesValid() === false) {
                button.reset()
                return
            }
            this.saveUserData((res) => {
                if (!res.data.success) {
                    this.showMessage(res.error, 'error', true)
                    return
                }
                const user = res.data.data
                // this.showMessage('User has been successfully saved', 'info', true)
                onSaveEntry && onSaveEntry(user)
            })
        }

        const invalidEntryFound = fieldsToValidate.some((f) => {
            const key = f + 'Error'
            return this.state[key]
        })

        if (invalidEntryFound) {
            button.reset()
            return
        }

        this._userNameExist(userName, (found) => {
            this.setState({
                userNameError: found
            }, () => {
                if (found) {
                    this.showMessage(`The user name (${userName}) you have entered already exists.`, 'error', true)
                } else {
                    save()
                }
            })
        })

    }

    _generateInitialState = () => {
        const selectedUser = this.props.user
        const editMode = selectedUser ? C_MODE.C_EDIT_MODE : C_MODE.C_ADD_MODE

        let userName
        let email
        let roles
        let firstName
        let lastName
        let fullName

        if (editMode && selectedUser) {
            userName = selectedUser.username
            email = selectedUser.email
            roles = selectedUser.roles
            firstName = selectedUser.firstname
            lastName = selectedUser.lastname
            fullName = selectedUser.fullname
        } else {
            userName = ''
            email = ''
            roles = ['SME']
            firstName = ''
            lastName = ''
            fullName = ''
        }
        const state = {
            user: selectedUser,
            editMode: editMode,
            userName: userName,
            email: email,
            roles: roles,
            firstName: firstName,
            lastName: lastName,
            fullName: fullName
        }
        return state
    }

    constructor(props) {
        super(props)
        this.state = this._generateInitialState()

        this._timerId  = null
    }

    render() {
        const { classes } = this.props
        let {
            userName,
            email,
            roles,
            firstName,
            lastName,
            fullName,
            userNameError,
            emailError,
            rolesError,
        } = this.state

        const userRoleMap = {}
        const roleList = ['SME', 'REVIEWER', 'ADMIN']
        roleList.forEach(r => userRoleMap[r] = false)
        roles.forEach(r => userRoleMap[r] = true)
        
        const buttonDisabled = userNameError || emailError || rolesError

        return (
            <div className={classes.root}>
                <div className={classes.container} style={{ flexDirection: 'column' }}>
                    <div className={classes.flexItemRow}>
                        <TextField
                            autoFocus={true}
                            style={{ flex: 'none', width: 120 }}
                            label="Agency UID"
                            className={classes.textField}
                            error={userNameError}
                            value={userName}
                            onKeyUp={this.handleKeyUp('userName')}
                            onChange={this.handleChange('userName')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 25 }
                            }}
                        />
                        <TextField
                            style={{ flex: 1 }}
                            label="Email"
                            className={classes.textField}
                            error={emailError}
                            value={email}
                            onKeyUp={this.handleKeyUp('email')}
                            onChange={this.handleChange('email')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 50 },
                            }}
                        />
                        <GroupContainer label='Roles' error={rolesError} labelStyle={{backgroundColor: '#f8f8ff'}} style={{ flex: 1 }}>
                            <FormGroup style={{ flexDirection: 'row' }}>
                                {roleList.map((key, prop) => {
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={
                                                <Checkbox style={{ padding: '0px 5px' }} checked={userRoleMap[key]} onChange={this.handleRoleCheckChange(key)} value={key} />
                                            }
                                            label={key}
                                            style={{ paddingRight: 10, paddingLeft: 5 }}
                                        />
                                    )
                                })}
                            </FormGroup>
                        </GroupContainer>

                    </div>
                    <div className={classes.flexItemRow}>
                        <TextField
                            style={{ flex: 1 }}
                            label="First Name"
                            className={classes.textField}
                            value={firstName}
                            onKeyUp={this.handleKeyUp('firstName')}
                            onChange={this.handleChange('firstName')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 50 },
                            }}
                        />

                        <TextField
                            style={{ flex: 1 }}
                            label="Last Name"
                            className={classes.textField}
                            value={lastName}
                            onKeyUp={this.handleKeyUp('lastName')}
                            onChange={this.handleChange('lastName')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 50 },
                            }}
                        />

                        <TextField
                            style={{ flex: 2 }}
                            label="Full Name"
                            className={classes.textField}
                            value={fullName}
                            onKeyUp={this.handleKeyUp('fullName')}
                            onChange={this.handleChange('fullName')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 100 },
                            }}
                        />
                    </div>
                    <div className={classes.flexItemRow}></div>
                    <div className={classes.flexItemRow} style={{marginRight: 8, justifyContent: 'flex-end'}}>
                        <ButtonProgress variant="outlined" disabled={buttonDisabled} onClick={this.onClickButtonSave}>Save</ButtonProgress>
                        <Button variant="outlined" style={{marginLeft: 5}} onClick={this.onClickButtonCancel}>Cancel</Button>
                    </div>
                </div>
            </div>
        )
    }
}

UserFormEntry.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UserFormEntry)
