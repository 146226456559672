import axios from 'axios'
import Base from './_Base'
import CommManager from './CommManager'
import Util from '../utils/Util'
import { C_EVENT_IDS, C_MODE } from '../variables/common'
import moment from 'moment'
import * as d3 from 'd3'

class AppStateManager extends Base {

    constructor() {
        super()

        this._appInitialized = false
        this._config = null
        this._token = null
        this._sessionObj = null
        this._currentModuleName = ''
        this._viewsDict = {}
        
        this._mode = C_MODE.C_VIEW_MODE
        this._previousEventId = ''
        this._currentEventId = ''
        this._currentPublicEventId = ''
        this._eventList = []
        this._publicEventList = []
        this._detectList = []
        this._userList = []
        this._serviceAccountList = []

        this._eventMinDate = moment().add(-30,'day').startOf('day').valueOf()
        this._eventMaxDate = moment().startOf('day').valueOf()
       
        this._displayLayout = 'grid' // 'list' // 'grid'
        this._eventAttachmentMetaDataMap = new Map()

        this._lastSortOption = { field: 'datetime', order: 'desc' }
        this._searchEventsPayload = {}
        this._serverConfig = null

        this._ajaxTimerId = null
        this._timeoutId = null
        this._eventsTableState = null
        this._isFullscreen = false
        this._suppressPopperClosing = false
        this._totalNumberOfEvents = 0
        this._currentScale = null
        this._adminTags = []

        // Add a request interceptor
        axios.interceptors.request.use( this.axiosRequestInterceptor, this.axiosErrorInterceptor)

        // Add a response interceptor
        axios.interceptors.response.use( this.axiosResponseInterceptor, this.axiosErrorInterceptor )

        window.addEventListener("resize", this.handleWindowResize.bind(this))
    }

    axiosRequestInterceptor = (config) => {
        // Do something before request is sent
        let pathname = window.location.hash || '/'
        pathname = pathname.replace(/#/g, '')
        Util.sendAnalyticsData(pathname)
        this.dispatchEvents(C_EVENT_IDS.C_ON_AJAX_REQUEST_STARTED)
        return config
    }

    axiosResponseInterceptor = (config) => {
        // Do something before request is sent
        window.clearTimeout(this._ajaxTimerId)
        this._ajaxTimerId = window.setTimeout(() => {
            this.dispatchEvents(C_EVENT_IDS.C_ON_AJAX_REQUEST_ENDED)
        }, 1000)
        return config
    }

    axiosErrorInterceptor = (error) => {
        this.dispatchEvents(C_EVENT_IDS.C_ON_AJAX_REQUEST_ERROR)
        return Promise.reject(error)
    }

    preInit = (cb) => {
        if (this._config && this._token) {
            return cb(null, {
                config: this._config,
                token: this._token,
                tags: this._adminTags
            })
        }
        Promise.all([CommManager.getServerConfig(), CommManager.getToken(), CommManager.getTags()]).then((values) => {
            this._config = values[0].data
            this._token = values[1] //  === 'NA' ? values[1] : values[1].data.csrf_token
            this._adminTags = values[2].data.data;
            cb && cb(null, {
                config: this._config,
                token: this._token,
                tags: this._adminTags
            })
          }).catch(error => {
              console.log('DEBUG:error', error)
              cb && cb(error)
          })
    }

    login = (opts, cb) => {
        CommManager.login(opts).then(res => {
            const sessionObj = res.data.response
            this._sessionObj = sessionObj
            cb(null, sessionObj)
            this.dispatchEvents(C_EVENT_IDS.C_ON_USER_LOGGED_IN, sessionObj)
        }).catch(err => {
            console.log('DEBUG:error', err)
            this._sessionObj = null
            cb(err)
        })
    }

    selectUser = (opts, cb) => {
        CommManager.selectUser(opts).then(res => {
            const sessionObj = res.data.response
            this._sessionObj = sessionObj
            cb(null, sessionObj)
        }).catch(err => {
            this._sessionObj = null
            cb(err)
        })
    }

    selectRole = (opts, cb) => {
        CommManager.selectRole(opts).then(res => {
            const sessionObj = res.data.response
            this._sessionObj = sessionObj
            cb(null, sessionObj)
        }).catch(err => {
            console.log('DEBUG:error', err)
            this._sessionObj = null
            cb(err)
        })
    }

    logout = (cb) => {
        CommManager.checkSession().then(res => {
            CommManager.logout().then(res => {
                this._sessionObj = null
            }).catch(err => {
                this._sessionObj = null
            })
            this.clearEventAttachmentMetaData()
            cb && cb()
            this.dispatchEvents(C_EVENT_IDS.C_ON_USER_LOGGED_OUT)
        }).catch(err => {
            this._sessionObj = null
            this.clearEventAttachmentMetaData()
            cb && cb()
            this.dispatchEvents(C_EVENT_IDS.C_ON_USER_LOGGED_OUT)
        })
    }

    changeRole = (cb) => {
        this.selectRole({selectedRole: ''}, () => {
            cb && cb()
            this.dispatchEvents(C_EVENT_IDS.C_ON_USER_CHANGE_ROLE)
        })
    }

    getUsers = (useCache, cb) => {
        if (useCache && this._userList.length > 0) {
            return cb(null, this._userList)
        }
        CommManager.getUsers().then(res => {
            this._userList = res.data.data
            cb && cb(null, res.data.data)
        }).catch(err => {
            cb && cb(err)
        })
    }

    checkSession = (cb) => {
        CommManager.checkSession().then((res) => {
            this._sessionObj = res.data.response
            cb(null, this._sessionObj)
        }).catch((err) => {
            this._sessionObj = null
            cb(err)
        })
    }

    getServerConfig = (cb) => {
        if (this._serverConfig) {
            return cb(null, this._serverConfig)
        }
        CommManager.getServerConfig().then((res) => {
            this._serverConfig = res.data
            cb(null, this._serverConfig)
        }).catch((err) => {
            this._serverConfig = null
            cb(err)
        })
    }

    // 
    // initialize app
    //
    initialize = (cb) => {
        let dt = []
        this.preInit((error, data) => {
            if (error) {
                return cb(error)
            }
            dt.push(data)
            this.getPublicEvents((res) => {
                if (res.success) {
                    this._appInitialized = true
                    dt.push(res.data)
                    this._totalNumberOfEvents = res.data.length
                    // extend base state....
                    this._eventMinDate = d3.min(res.data, e => moment.utc(e.datetime).startOf('day').valueOf())
                    this._eventMaxDate = d3.max(res.data, e => moment.utc(e.datetime).startOf('day').valueOf())
                    this._searchEventsPayload = { ...this.resetSearchEventsPayload }
                    this._detectList = [ 'GLM-16', 'GLM-17', 'GLM-18', 'Stereo' ]
                   cb(null, dt)
                } else {
                    return cb(res.error)
                }
            })
        })
    }

    setAddMode = (sendMessage=false, cb) => {
        if (this._mode !== C_MODE.C_ADD_MODE) {
            this._mode = C_MODE.C_ADD_MODE
            if (sendMessage) {
                this.dispatchEvents(C_EVENT_IDS.C_ON_SET_ADD_MODE)    
            }
            cb && cb()
        }
    }

    setEditMode = (sendMessage=false, cb) => {
        if (this._mode !== C_MODE.C_EDIT_MODE) {
            this._mode = C_MODE.C_EDIT_MODE
            if (sendMessage) {
                this.dispatchEvents(C_EVENT_IDS.C_ON_SET_EDIT_MODE)
            }
            cb && cb()
        }
    }

    setViewMode = (sendMessage=false, cb) => {
        if (this._mode !== C_MODE.C_VIEW_MODE) {
            this._mode = C_MODE.C_VIEW_MODE
            if (this._previousEventId.length > 0) {
                this._currentEventId = this._previousEventId
                this._previousEventId = ''
            }
            if (sendMessage) {
                this.dispatchEvents(C_EVENT_IDS.C_ON_SET_VIEW_MODE)    
            }
            cb && cb()
        }
    }

    _generateEventList = (eventList) => {
        return eventList
    }

    searchEvents = (payload, cb) => {
        this._searchEventsPayload = payload

        this.dispatchEvents(C_EVENT_IDS.C_ON_FILTER_CHANGED, this._searchEventsPayload)
        
        console.log('DEBUG: searchEvents', payload)
        CommManager.searchEvents(payload).then((res) => {
            if (res.data.success) {
                const data = res.data.data
                const detectedList = res.data.detectList
                this.publicEventList = data // this._generateEventList(data)
                this.detectList = detectedList

                this.dispatchEvents(C_EVENT_IDS.C_ON_SEARCH_EVENTS, this.publicEventList)

                cb && cb({ success: true, data: this.publicEventList })
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getEventByDate = (dateTextValue, cb) => {
        CommManager.getEventByDate(dateTextValue).then((res) => {
            if (res.data.success) {
                const data = res.data.data
                const event = data.length > 0 ? data[0] : {}
                cb && cb({ success: true, data: event })
            } else {
                cb && cb({ success: false, error: res.data.error})
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getUserByUsername = (userName, cb) => {
        CommManager.getUserByUsername(userName).then((res) => {
            if (res.data.success) {
                const data = res.data.data
                const user = data.length > 0 ? data[0] : {}
                cb && cb({ success: true, data: user })
            } else {
                cb && cb({ success: false, error: res.data.error})
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getUserByEmail = (email, cb) => {
        CommManager.getUserByEmail(email).then((res) => {
            if (res.data.success) {
                const data = res.data.data
                const user = data.length > 0 ? data[0] : {}
                cb && cb({ success: true, data: user })
            } else {
                cb && cb({ success: false, error: res.data.error})
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getAllEvents = (cb) => {
        CommManager.getAllEvents().then((res) => {
            if (res.data.success) {
                const data = res.data.data
                this.eventList = data // this._generateEventList(data)
                cb && cb({ success: true, data: this.eventList })
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getSmeEvents = (cb) => {
        CommManager.getSmeEvents().then((res) => {
            if (res.data.success) {
                const data = res.data.data
                this.eventList = data // this._generateEventList(data)
                cb && cb({ success: true, data: this.eventList })
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getReviewerEvents = (cb) => {
        CommManager.getReviewerEvents().then((res) => {
            if (res.data.success) {
                const data = res.data.data
                this.eventList = data // this._generateEventList(data)
                cb && cb({ success: true, data: this.eventList })
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getPublicEvents = (cb) => {
        CommManager.getPublicEvents().then((res) => {
            if (res.data.success) {
                const data = res.data.data
                this.publicEventList = data // this._generateEventList(data)
                cb && cb({ success: true, data: this.publicEventList })
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    getEvent = (eventId, cb) => {
        CommManager.getEvent(eventId).then((res) => {
            if (res.data.success) {
                let event = res.data.data.length > 0 ? res.data.data[0] : {}
                // this._augmentEvent(event)
                this.updateEventList(event)
                this.dispatchEvents(C_EVENT_IDS.C_ON_EVENT_DATA_LOADED, event)
                cb && cb({ success: true, data: event})
            } else {
                cb && cb({ success: false, error: res.data.error })    
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, error: error })
        })
    }

    submitEvent = (payload, cb) => {
        CommManager.submitEvent(payload).then((response) => {
            const res = response.data
            if (res.success) {
                cb && cb({ success: true, data: res.data})
            } else {
                cb && cb({ success: false, error: res})
            }
        }).catch((error) => {
            cb && cb({ success: false, error: error})
        })
    }

    rejectEvent = (payload, cb) => {
        CommManager.rejectEvent(payload).then((response) => {
            const res = response.data
            if (res.success) {
                cb && cb({ success: true, data: res.data})
            } else {
                cb && cb({ success: false, error: res})
            }
        }).catch((error) => {
            cb && cb({ success: false, error: error})
        })
    }

    publishEvent = (payload, cb) => {
        CommManager.publishEvent(payload).then((response) => {
            const res = response.data
            if (res.success) {
                cb && cb({ success: true, data: res.data})
            } else {
                cb && cb({ success: false, error: res})
            }
        }).catch((error) => {
            cb && cb({ success: false, error: error})
        })
    }


    changeEventStatus = (payload, cb) => {
        CommManager.changeEventStatus(payload).then((response) => {
            const res = response.data
            if (res.success) {
                // this._augmentEvent(res.data)
                cb && cb({ success: true, data: res.data})
            } else {
                cb && cb({ success: false, error: res})
            }
        }).catch((error) => {
            cb && cb({ success: false, error: error})
        })
    }

    saveEvent = (payload, cb) => {
        CommManager.saveEvent(payload).then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data })
            } else {
                cb && cb({ success: false, error: response })
            }
        }).catch((error) => {
            cb({ success: false, error: error })
        })
    }

    regenerateEventFiles = (payload, cb) => {
        CommManager.regenerateEventFiles(payload).then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data })
            } else {
                cb && cb({ success: false, error: response })
            }
        }).catch((error) => {
            cb({ success: false, error: error })
        })
    }

    saveUser = (payload, cb) => {
        CommManager.saveUser(payload).then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data })
            } else {
                cb && cb({ success: false, error: response })
            }
        }).catch((error) => {
            cb({ success: false, error: error })
        })
    }

    getEventGeoData = (payload, cb) => {
        CommManager.getEventGeoData(payload).then((response) => {
            if (response.data) {
                if (response.data.success === true) {
                    cb && cb({ success: true, data: response.data.data })
                } else {
                    cb && cb({ success: false, error: response.data.error })
                }
            }
        }).catch((error) => {
            cb({ success: false, error: error })
        })
    }

    getServerResource = (url, cb) => {
        CommManager.getServerResource(url).then((response) => {
            cb && cb({ success: true, data: response })
        }).catch((error) => {
            cb && cb({ success: false, error: error })
        })
    }

    deleteEvent = (eventId, cb) => {
        CommManager.deleteEvent(eventId).then((response) => {
            if (response.data) {
                if (typeof cb === 'function') {
                    cb({
                        success: true,
                        data: response.data
                    })
                }
                const tmpList = Util.cloneArray(this._eventList)
                let idx = tmpList.findIndex((e) => { return e._id === eventId})
                if (idx !== -1) {
                    tmpList.splice(idx, 1)
                    this._eventList = tmpList
                    this.dispatchEvents(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this._eventList)
                }
            } else {
                console.log('DEBUG:error', response)
                if (typeof cb === 'function') {
                    cb({
                        success: false,
                        message: response
                    })
                }
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            if (typeof cb === 'function') {
                cb({
                    success: false,
                    message: error
                })
            }
        })
    }

    deleteUser = (userId, cb) => {
        CommManager.deleteUser(userId).then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data })
            } else {
                console.log('DEBUG:error', response)
                cb && cb({ success: false, message: response })
            }
        }).catch((error) => {
            console.log('DEBUG:error', error)
            cb && cb({ success: false, message: error })
        })
    }

    updateEventList = (event, updateEventId=true) => {
        const eventId = event._id
        const idx = this._eventList.findIndex(e => e._id === eventId)
        if (idx !== -1) {
            this._eventList[idx] = event
        }
        if (updateEventId) {
            this._currentEventId = eventId
        }
    }

    updateEventAttachments = (event, updateEventId=true) => {
        const eventId = event._id
        const idx = this._publicEventList.findIndex(e => e._id === eventId)
        if (idx !== -1) {
            this._publicEventList[idx].attachments = event.attachments
        }
        if (updateEventId) {
            this._currentPublicEventId = eventId
        }
    }

    sortEventList = (option) => {
        this._lastSortOption = option
        let tempList = Util.cloneDeep(this._eventList)
        tempList = Util.orderBy(tempList, [option.field], [option.order])
        this._eventList = tempList
        this.dispatchEvents(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, this._eventList)
    }

    clearEventAttachmentMetaData() {
        this._eventAttachmentMetaDataMap.clear()
    }
    
    toggleScreenMode() {
        const fs = !this._isFullscreen
        if (fs) {
            // const rootElem = document.querySelector('div')
            const rootElem = document.querySelector('body')
            if (rootElem.requestFullscreen) {
                rootElem.requestFullscreen()
            } else if (rootElem.mozRequestFullScreen) { /* Firefox */
                rootElem.mozRequestFullScreen()
            } else if (rootElem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                rootElem.webkitRequestFullscreen()
            } else if (rootElem.msRequestFullscreen) { /* IE/Edge */
                rootElem.msRequestFullscreen()
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen()
            }
            return window.location.reload()
        }
        this._isFullscreen = fs
        this.dispatchEvents(C_EVENT_IDS.C_ON_SCREEN_MODE_CHANGED, fs)
        return fs
    }

    handleWindowResize() {
        const appState = this
        Util.debounce2(() => {
            const fs = (window.screenTop === 0 && window.screenY === 0)
            console.log('DEBUG: AppState.window.resize, fs, isFullscreen', fs, appState._isFullscreen)
            if (fs !== appState._isFullscreen) {
                appState._isFullscreen = fs
                if (fs === false) {
                    window.location.reload()
                } else {
                    appState.dispatchEvents(C_EVENT_IDS.C_ON_SCREEN_MODE_CHANGED, fs)
                }
            }
            appState.dispatchEvents(C_EVENT_IDS.C_ON_WINDOW_RESIZED)
        }, 500)
    }

    //
    // getters / setters
    //
    get selectedPublicEvent() {
        let event = this._publicEventList.find(e => e._id === this._currentPublicEventId)
        return event
    }
    get selectedEvent() {
        let event = this._eventList.find(e => e._id === this._currentEventId)
        return event
    }

    get selectedEventIndex() {
        let index = this._eventList.findIndex(e => e._id === this._currentEventId)
        return index
    }

    get currentModuleName() {
        return this._currentModuleName
    }
    set currentModuleName(value) {
        this._currentModuleName = value
        this.dispatchEvents(C_EVENT_IDS.C_ON_SET_MODULE_NAME, value)
    }

    get session() {
        return this._sessionObj
    }
    get uid() {
        return this._sessionObj ? this._sessionObj.user._id : ''
    }
    get currentUser() {
        return this._sessionObj ? this._sessionObj.user : null
    }
    get selectedRole() {
        return this._sessionObj ? this._sessionObj.selectedRole : ''
    }
    get currentEventId() {
        return this._currentEventId
    }
    set currentEventId(value) {
        if (!value || value.trim().length === 0) {
            this._previousEventId = this._currentEventId
        }
        this._currentEventId = value
    }
    get currentPublicEventId() {
        return this._currentPublicEventId
    }
    set currentPublicEventId(value) {
        this._currentPublicEventId = value
    }
    get previousEventId() {
        return this._previousEventId
    }
    set previousEventId(value) {
        this._previousEventId = value
    }
    get displayLayout() {
        return this._displayLayout
    }
    set displayLayout(value) {
        if (this._displayLayout !== value) {
            this._displayLayout = value
            this.dispatchEvents(C_EVENT_IDS.C_ON_CHANGE_LAYOUT, value)
        }
    }
    get mode() {
        return this._mode
    }
    get eventList() {
        return this._eventList
    }
    set eventList(value) {
        if (!Util.isEqualByValue(this._eventList, value)) {
            const { field, order } = this._lastSortOption
            this._eventList = Util.orderBy(value, [field], [order])
            this.dispatchEvents(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, value)
        }
    }
    get publicEventList() {
        return this._publicEventList
    }
    set publicEventList(value) {
        if (!Util.isEqualByValue(this._publicEventList, value)) {
            const { field, order } = this._lastSortOption
            this._publicEventList = Util.orderBy(value, [field], [order])
            this.dispatchEvents(C_EVENT_IDS.C_ON_EVENT_LIST_DATA_CHANGED, value)
        }
    }

    get detectList() {
        return this._detectList
    }
    set detectList(value) {
        this._detectList = value
    }

    get appInitialized() {
        return this._appInitialized
    }

    get eventAttachmentMetaDataMap() {
        return this._eventAttachmentMetaDataMap
    }
    get searchEventsPayload() {
        return this._searchEventsPayload
    }
    get resetSearchEventsPayload() {
        return {
            keywords: '',
            dateStart: moment.utc(this._eventMinDate).format('MM/DD/YYYY'),
            dateEnd: moment.utc(this._eventMaxDate).format('MM/DD/YYYY'),
            latitude: '',
            latitudeDelta: '1.0',
            longitude: '',
            longitudeDelta: '1.0',
            detectedByMap: { 'GLM-16': false, 'GLM-17': false, 'GLM-18': false, 'Stereo': false},
            confidenceRatingMap: { 'low': false, 'medium': false, 'high': false },
            lightcurveStructureMap: { 'marginal': false, 'minimal': false, 'good': false, 'very good': false },
            nearbyLightningActivityMap: { 'isolated': false, 'not isolated': false, 'not assessed': false },
            duration1: '',
            durationOperator: '>',
            duration2: '',
            publicOnly: true,
            advancedSearch: false
        }
    }

    get serverConfig() {
        return this._serverConfig
    }
    get eventMinDate() {
        return this._eventMinDate
    }
    get eventMaxDate() {
        return this._eventMaxDate
    }
    get timeoutId() {
        return this._timeoutId
    }
    set timeoutId(value) {
        this._timeoutId = value
    }
    get eventsTableState() {
        return this._eventsTableState
    }
    set eventsTableState(value) {
        this._eventsTableState = value
    }
    get isFullscreenSupported() {
        const rootElem = document.querySelector('div')
        return (rootElem.requestFullscreen ||
            rootElem.mozRequestFullScreen ||
            rootElem.webkitRequestFullscreen ||
            rootElem.msRequestFullscreen)
    }
    get isFullscreen() {
        return this._isFullscreen
    }
    set suppressPopperClosing(value) {
        this._suppressPopperClosing = value
    }
    get suppressPopperClosing() {
        // read once
        const retVal = this._suppressPopperClosing
        this._suppressPopperClosing = false
        return retVal
    }
    get totalNumberOfEvents() {
        return this._totalNumberOfEvents
    }
    set totalNumberOfEvents(value) {
        this._totalNumberOfEvents = value
    }
    get currentScale() {
        return this._currentScale
    }
    set currentScale(value) {
        this._currentScale = value
    }
    //
    // dispatchers...
    //
    openMainDrawer = () => {
        console.log('DEBUG: AppStateManager - openMainDrawer')
        this.dispatchEvents(C_EVENT_IDS.C_ON_OPEN_MAIN_DRAWER)    
    }

    closeMainDrawer = () => {
        this.dispatchEvents(C_EVENT_IDS.C_ON_CLOSE_MAIN_DRAWER)    
    }

    toggleMainDrawer = () => {
        this.dispatchEvents(C_EVENT_IDS.C_ON_TOGGLE_MAIN_DRAWER)    
    }

    updateAttachment = (index, range) => {
        this.dispatchEvents(C_EVENT_IDS.C_ON_UPDATE_ATTACHMENT, {index: index, range: range})
    }

    removeAttachment = (index) => {
        this.dispatchEvents(C_EVENT_IDS.C_ON_REMOVE_ATTACHMENT, index)    
    }

    //service account
    getServiceAccount = (useCache, cb) => {
        if (useCache && this._serviceAccountList.length > 0) {
            return cb(null, this._serviceAccountList)
        }
        CommManager.getServiceAccount().then(res => {
            this._serviceAccountList = res.data.data
            cb && cb(null, res.data.data)
        }).catch(err => {
            cb && cb(err)
        })
    }

    saveServiceAccount = (payload, cb) => {
        CommManager.saveServiceAccount(payload).then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data })
            } else {
                cb && cb({ success: false, error: response })
            }
        }).catch((error) => {
            cb({ success: false, error: error })
        })
    }

    deleteServiceAccount = (payload, cb) => {
        CommManager.deleteServiceAccount(payload).then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data })
            } else {
                cb && cb({ success: false, error: response })
            }
        }).catch((error) => {
            cb({ success: false, error: error })
        })
    }

    getAdminTags = (cb) => {
        CommManager.getTags().then((response) => {
            if (response.data) {
                cb && cb({ success: true, data: response.data.data })
            } else {
                cb && cb({success: false, error: response })
            }
        }).catch((error) => {
            cb({success: false, error: error})
        })
    }

    saveAdminTags = (payload, cb) => {
        CommManager.saveTags(payload).then((response) => {
            if (response.data) {
                this._adminTags = response.data.data
                cb && cb(null, { success: true, data: response.data.data })
            } else {
                cb && cb({success: false, error: response }, null)
            }
        }).catch((error) => {
            cb({success: false, error: error})
        })
    }
}

const appStateManager = new AppStateManager()

export default appStateManager