import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import BaseComponent from '../components/_Base'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
// jss
import { root, container } from '../assets/jss/index'
import { Typography } from '@mui/material';

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      "& div.container": {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }
  },
  toolbar: theme.mixins.toolbar,
})

class SessionTimeout extends BaseComponent {

  componentWillMount() {
    this.props.changeTheme('baseTheme')
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classNames('full-screen', classes.root)}>
        <div className={classes.container}>
          <div className='app-bar'>
            <MainAppBar />
          </div>
          <div className={classes.toolbar}></div>
          <div className='content'>
            <div className='container' style={{flexDirection: 'column'}}>
              <Typography variant='h3'>Session Expired</Typography>
              <Typography variant='h6'>
                Your session is expired. &nbsp; Click <a href='/admin'>here</a> to restart.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SessionTimeout.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(SessionTimeout))
