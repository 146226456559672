import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import AppState from '../../managers/AppStateManager'
import { C_EVENT_IDS } from '../../variables/common'
import classNames from 'classnames'

const styles = theme => ({
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonProgress: {
        color: 'white',
    },
    buttonDisabled: {
        backgroundColor: theme.palette.primary.main + ' !important'
    }
})

class ButtonProgress extends React.Component {

    reset = () => {
        this.setState({
            loading: false
        })
    }

    _handleButtonClick = (e) => {
        const { onClick } = this.props
        this.setState(
            {
                loading: true,
            },
            () => {
                if (typeof onClick === 'function') {
                    onClick(e, this)
                } else {
                    setTimeout(() => {
                        this.reset()
                    }, 1000)
                }
            }
        )
    }

    handleAjaxEnded = () => {
        this.setState({
            loading: false
        })
    }
    handleAjaxError = () => {
        this.setState({
            loading: false
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        if (!this.props.onClick) {
            AppState.addEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ENDED, this.handleAjaxEnded)
            AppState.addEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ERROR, this.handleAjaxError)
        }
    }

    componentWillUnmount() {
        if (!this.props.onClick) {
            AppState.removeEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ENDED, this.handleAjaxEnded)
            AppState.removeEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ERROR, this.handleAjaxError)
        }
    }


    render() {

        const { loading } = this.state
        const { classes, classNameRoot, buttonTitle, className, disabled, onClick, children, keepColor, ...rest } = this.props

        const cls = (loading && keepColor) ? { disabled: classes.buttonDisabled } : {}

        return (
            <div className={classNames(classes.root, classNameRoot)}>
                <Button
                    {...rest}
                    classes={cls}
                    className={className}
                    disabled={disabled || loading}
                    onClick={this._handleButtonClick}
                >   &nbsp;
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    {!loading && children}
                </Button>
            </div>
        )
    }
}

ButtonProgress.propTypes = {
    classes: PropTypes.object.isRequired,
    keepColor: PropTypes.bool,
}

ButtonProgress.defaultProps = {
    keepColor: true
}

export default withStyles(styles)(ButtonProgress)
