import React from 'react'

// @mui/material components
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'

import BaseComponent from '../components/_Base'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'

// jss
import { root, container } from '../assets/jss/index'
import { Typography } from '@mui/material'

import FileUpload from '../components/FileUpload/FileUpload'
import { C_DEFAULTS } from '../variables/common'

import { Redirect } from 'react-router-dom'
import AppState from '../managers/AppStateManager'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: 10,
  },
  tableContainer: {
    width: '100%',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  netCdfMetaData: {
    fontSize: '0.9em',
    color: 'white',
    paddingRight: 10,
    "& span": {
      fontWeight: 'bold',
      textDecoration: 'underline'
    }
  },
  toolbar: theme.mixins.toolbar,
  flexNone: {
    flex: 'none'
  },
  flexAuto: {
    flex: 'auto 1 1'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: 8,
  },
})

class NCDFViewer extends BaseComponent {

  onFileuploadSuccess = (data) => {
    this.setState({
      netCdfData: data
    })
  }

  onFileuploadError = (error) => {
    this.setState({
      jsonData: JSON.stringify(error, null, 3)
    })
  }

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }
    // extend base state....
    this.state = {
      ...baseState,
      netCdfData: null,
      isReady: false,
    }
  }

  componentDidMount() {
    
    super.componentDidMount()

    AppState.preInit((error, data) => {

      if (error) {
        return console.log('DEBUG:error', error)
      }
      const config = data.config
      this.setState({
        // production or development
        isProduction: config.environment === 'production' ? true : false,
      })
    });
    
  }

  render() {
    const { classes, ...rest } = this.props
    const { netCdfData, isProduction } = this.state
    const satellite = netCdfData && netCdfData.satellite ? `${netCdfData.satellite.latitude.toFixed(2)} / ${netCdfData.satellite.longitude.toFixed(2)} / ${netCdfData.satellite.height.toFixed(2)}km` : ''

    if (isProduction) {
      return (<Redirect to="/forbidden" />);
    }
    return (
      <div className={classNames('full-screen', classes.root)}>
        <div className={classes.container}>
          <div className='app-bar'>
            <MainAppBar {...rest} />
          </div>
          <div className={classes.toolbar}></div>
          <div className='content'>
            <Typography className={classes.flexNone} variant="h5">NetCDF Viewer</Typography>
            <FileUpload className={classes.flexNone} style={{ marginBottom: 10 }} onSuccess={this.onFileuploadSuccess} onError={this.onFileuploadError} />
            {netCdfData &&
              [<Paper key={1} className={classes.tableContainer}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell style={{ backgroundColor: 'dimgray' }} colSpan={6}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography className={classes.netCdfMetaData}><span>{netCdfData.title}</span></Typography>
                          <Typography className={classes.netCdfMetaData}>Start Time:<span>{moment.utc(netCdfData.startTime).format(C_DEFAULTS.C_DATE_FORMAT_STRING)}</span></Typography>
                          <Typography className={classes.netCdfMetaData}>End Time:<span>{moment.utc(netCdfData.endTime).format(C_DEFAULTS.C_DATE_FORMAT_STRING)}</span></Typography>
                          <Typography className={classes.netCdfMetaData}>Duration:<span>{netCdfData.duration}</span></Typography>
                          <Typography className={classes.netCdfMetaData}>Platform:<span>{netCdfData.platformId}</span></Typography>
                          <Typography className={classes.netCdfMetaData}>Satellite (lat/lon/height):<span>{satellite}</span></Typography>
                          <Typography className={classes.netCdfMetaData}># of Data Points:<span>{netCdfData.geoData.length}</span></Typography>
                        </div>
                      </CustomTableCell>
                    </TableRow>
                    <TableRow>
                      <CustomTableCell align="left">Index</CustomTableCell>
                      <CustomTableCell align="left">Time</CustomTableCell>
                      <CustomTableCell align="center">Time Offset ({netCdfData.geoData[0].timeUnit})</CustomTableCell>
                      <CustomTableCell align="left">Unit</CustomTableCell>
                      <CustomTableCell align="left">Location (lat/lon)</CustomTableCell>
                      <CustomTableCell align="left">Energy</CustomTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Paper>,
              <div key={2} className={classes.flexAuto} style={{ overflowY: 'auto', width: '100%', height: '50vh' }}>
                <Paper className={classes.tableContainer}>
                  <Table className={classes.table}>
                    <TableBody>
                      {netCdfData.geoData.map((row, idx) => (
                        <TableRow className={classes.row} key={idx}>
                          <CustomTableCell align="left">{++idx}</CustomTableCell>
                          <CustomTableCell align="left" component="th" scope="row">
                            {moment.utc(row.time).format(C_DEFAULTS.C_DATE_FORMAT_STRING)}
                          </CustomTableCell>
                          <CustomTableCell align="center">{row.timeOffset.toPrecision(5)}</CustomTableCell>
                          <CustomTableCell align="center">{row.timeUnit === 's' ? 'seconds' : 'milliseconds'}</CustomTableCell>
                          <CustomTableCell align="center">{`${row.location.coordinates[1].toPrecision(7)} / ${row.location.coordinates[0].toPrecision(7)}`}</CustomTableCell>
                          <CustomTableCell align="center">{row.energy.toPrecision(7)}</CustomTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
              ]
            }
            {!netCdfData && <div className={classes.flexAuto}></div>}
          </div>
        </div>
      </div>
    )
  }
}

NCDFViewer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(NCDFViewer))
