import React, { Component } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
import withRoot from './withRoot'

// routes
import indexRoutes from "./routes/index.js"

class App extends Component {
  render() {
    return (
      <HashRouter>
          <Switch>
            {indexRoutes.map((prop, key) => {
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.to} key={key} />
              return <Route path={prop.path} key={key} exact={true} strict={true} render={(props) => <prop.component {...props} {...this.props} title={prop.title} />} />
            })}
          </Switch>
      </HashRouter>
    )
  }
}

export default withRoot(App)
