import React from 'react'

// @mui/icons-material components
import PropTypes from 'prop-types';
import GridContainer from "../components/Grid/GridContainer.jsx"
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import IconButton from '@mui/material/IconButton'
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined'

import classNames from 'classnames'

import BaseComponent from '../components/_Base'
import Splash from '../components/Splash/Splash'
import MainAppBar from '../components/Main/MainAppBar'
import GroupBox from '../components/GroupBox/GroupBox'
import withSharedFeatures from './withSharedFeatures'
import AppState from '../managers/AppStateManager'

// jss
import { root, container } from '../assets/jss/index'
import { Typography } from '@mui/material';

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: "100%"
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    width: 100,
  },
  groupBox: {
    width: 430,
    height: 'auto',
    padding: '20px 30px'
  },
})

class UserRole extends BaseComponent {

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }
    // extend base state....
    this.state = {
      ...baseState,
      isReady: false,
      selectedUserName: null,
    }
  }


  handleBack = event => {
    this.setState({
      showUsers: true,
      showRoleSelection: false
    })
  }

  handleSelectUser = event => {
    const { users, selectedUserName } = this.state
    const user = users.find(u => u.username === selectedUserName)
    this.setState({
      selectedUser: user,
      showUsers: false,
      showRoleSelection: true
    })
  }

  handleNextPage = name => e => {
    const { selectedUserName } = this.state
    AppState.checkSession((err, session) => {
      if (err) {
        // this.props.history.push('/sessiontimeout')
        window.location.href = '/admin'
      } else if (session) {
        AppState.selectUser({ username: selectedUserName, selectedRole: name }, (err, res) => {
          if (err) {
            this.props.history.push('/sessiontimeout')
          } else if (res) {
            if (name === 'admin') {
              name = 'settings'
            }
            this.props.history.push('/admin/' + name)
          } else {
            this.props.history.push('/sessiontimeout')
          }
        })
      } else {
        window.location.href = '/admin'
      }
    })
  }

  handleChange = username => event => {
    AppState.selectUser({ username: username, selectedRole: '' }, (err, res) => {
      if (res) {
        this.setState({
          selectedUserName: username
        })
      } else {
        this.props.history.push('/sessiontimeout')
      }
    })
  }

  _getBackgroundColor = (role) => {
    const { themeMap } = this.props
    const theme = themeMap[role.toLowerCase() + 'Theme']
    return theme.palette.primary.main 
  }

  componentWillMount() {
    this.props.changeTheme('baseTheme')
  }

  componentDidMount() {

    super.componentDidMount()

    AppState.preInit((error, data) => {

      if (error) {
        return console.log('DEBUG:error', error)
      }
      const config = data.config
      // console.log('DEBUG: UserRole - config', config)
      if (config.environment === 'development') {
        AppState.getUsers(true, (err, data) => {
          if (err) {
            console.log('DEBUG:error', err)
          } else {
            this.setState({
              isReady: true,
              showUsers: true,
              users: data,
              isProduction: false
            })
          }
        })

      } else if (config.environment === 'production' || config.environment === 'staging') {

        AppState.checkSession((err, session) => {
          if (err) {
            this.props.history.push('/sessiontimeout')
          } else if (session) {
            if (AppState.currentUser && AppState.selectedRole) {
              let role = AppState.selectedRole.toLowerCase()
              if (role === 'admin') {
                role = 'settings'
              }
              this.props.history.push('/admin/' + role)
            } else if (AppState.currentUser) {
              this.setState({
                isReady: true,
                showUsers: false,
                selectedUser: AppState.currentUser,
                selectedUserName: AppState.currentUser.username,
                showRoleSelection: true,
                isProduction: true
              })
            } else {
              this.props.history.push('/sessiontimeout')
            }
          } else {
            window.location.href = '/admin'
          }
        })
      }
    })
  }

  render() {
    const { classes, ...rest } = this.props
    const { isReady, users, selectedUserName, selectedUser, showUsers, showRoleSelection, isProduction } = this.state

    return (
      <div className={classNames('full-screen', classes.root)}>
        {!isReady && (
          <GridContainer direction="row" justify="center" alignItems="center" className={classes.gridContainer}>
            <Splash />
          </GridContainer>
        )}
        {isReady && (
          <div className={classes.container}>
            <div className='app-bar'>
              <MainAppBar {...rest} />
            </div>

            {showUsers &&
              <div className='content'>
                <GroupBox withHeader={false} className={classes.groupBox}>
                  <Typography variant="h4">User Role Selection</Typography>
                  <div style={{ marginTop: 20, paddingLeft: 15 }}>
                    {users.map((user, key) => {
                      return (
                        <div key={key}>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={selectedUserName === user.username}
                                onChange={this.handleChange(user.username)}
                                value={user.username}
                                name="radio-button-demo"
                              />
                            }
                            label={user.username}
                          />
                        </div>
                      )
                    })}
                  </div>

                  <div style={{ textAlign: 'right', margin: 8 }}>
                    <Button variant="contained" color="primary" disabled={selectedUserName ? false : true} className={classes.button} onClick={this.handleSelectUser}>
                      Next
                    </Button>
                  </div>

                </GroupBox>
              </div>
            }
            {showRoleSelection && selectedUser &&
              <div className='content'>
                <GroupBox withHeader={false} className={classes.groupBox}>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!isProduction &&
                      <IconButton onClick={this.handleBack} size="large">
                        <ArrowLeftIcon />
                      </IconButton>
                    }
                    <Typography variant="h4">User Role Selection</Typography>
                  </div>
                  <Typography style={{ color: 'gray', fontStyle: 'italic', textAlign: 'center' }}>Authorized User: {selectedUserName}</Typography>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20, alignItems: 'center' }}>
                    {selectedUser.roles.map((role, key) => {
                      const r = role.toLowerCase()
                      const lStyle = { height: 50, width: 200, backgroundColor: this._getBackgroundColor(r) }
                      return (
                        <div key={key}>
                          <Button variant="contained" color="primary" className={classes.button} style={lStyle} onClick={this.handleNextPage(r)}>{role}</Button>
                        </div>
                      )
                    })}
                  </div>
                </GroupBox>

              </div>
            }
          </div>
        )}
      </div>
    );
  }
}

UserRole.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(UserRole))
