import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'

// @mui/material components & api
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { darken, lighten } from '@mui/material/styles';
import Button from '@mui/material/Button'

// React components & functions
import GroupBox from '../GroupBox/GroupBox'
import BaseComponent from '../_Base'
import Splash from '../Splash/Splash'
import AppState from '../../managers/AppStateManager'
import withSharedFeatures from '../../views/withSharedFeatures'
import { C_MODE } from '../../variables/common'
import ServiceAccountFormEntry from './ServiceAccountFormEntry';

// jss
import { root, container } from '../../assets/jss/index'



const styles = (theme) => ({
    root: {
        ...root,
        fontSize: '0.85rem',
        width: 960,
    },
    container: {
        ...container,
        display: 'flex',
        flexDirection: 'column',
    },
    titleText: {
        fontSize: '1rem',
        marginBottom: 15,
        marginTop: 25,
    },
    groupBox: {
        height: 'auto',
        padding: 0,
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
          padding: 0,
        }
    },
    table: {
        '& .table-row': {
          width: '100%'
        },
        '& .table-row:hover': {
          backgroundColor: lighten('#f8f8ff', 0.075),
        }
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid ' + darken('#f8f8ff', 0.065),
        padding: 15,
    },
    colHeader: {
        fontSize: '0.9em',
        fontWeight: 'bold',
        color: 'gray',
    },
    applicationIdCol: {
        flex: 'none',
        width: 180,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    orgIdentityCol: {
        flex: 'none',
        width: 150,
        overflow: 'hidden',
        paddingLeft: 20,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    tableCol: {
        flex: 'none',
        width: 130,
        overflow: 'hidden',
        paddingLeft: 20,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    actionButtonsCol: {
        flex: 'none',
        width: 165,
        display: 'flex',
        justifyContent: 'space-around',
        marginLeft: 19,
    }
})

class ServiceAccountList extends BaseComponent {
    
    constructor(props){
        super(props)
        // pattern of extending state from the base class
        const baseState = { ...this.state }
        this.state = {
            ...baseState,
            serviceAccounts: [],
            editMode: C_MODE.C_VIEW_MODE,
            selectedId: null
        }
    }

    _handleOnClickAdd = () => {
        this.setState({
            editMode: C_MODE.C_ADD_MODE,
        })
    }

    _handleOnCancelEntry = () => {
        this.setState({
          editMode: C_MODE.C_VIEW_MODE,
          selectedId: null,
        })
    }

    _handleOnClickEdit = id => e => {
        this.setState({
            editMode: C_MODE.C_EDIT_MODE,
            selectedId: id
        })
    }

    _handleOnSaveEntry = (serviceAccount) => {
        const { editMode, serviceAccounts } = this.state
        
        if (editMode === C_MODE.C_ADD_MODE) {
            serviceAccounts.push(serviceAccount)
        } else {
            //find service account index within the serviceAccounts array
            const idx = serviceAccounts.findIndex( u => u._id === serviceAccount._id)
            //replace existing service account with updated service account value
            if (idx !== -1) {
                serviceAccounts.splice(idx, 1, serviceAccount)
            }
        }

        this.setState({
            serviceAccounts: serviceAccounts,
            selectedId: null,
            editMode: C_MODE.C_VIEW_MODE,
        }, () => {
            // this.showMessage('Saved....', user)
            this.showMessage('Service Account has been successfully saved', 'info', true)
        })

    }

    _handleOnClickRemove = (id, applicationId) => e => {
        const payload = {
            data: {
                "id": id
            }  
        }

        AppState.deleteServiceAccount(payload, (success, error) => {
            if (!success) {
              this.showMessage(`Error on removing ${applicationId}`, 'error', true)
            } else {
                this.showMessage(`Removed ${applicationId}`, 'info', true)
                this.setState({
                    serviceAccounts: this.state.serviceAccounts.filter((account) => account._id !== id)
                })
            }
        })
    }

    componentDidMount() {
        AppState.getServiceAccount(false, (err, data) => {
            if (err) {
              console.log('DEBUG:error', err)
            } else {
              this.setState({
                isReady: true,
                serviceAccounts: data,
              })
            }
        })
    }

    render() {
        const { classes, ...rest } = this.props
        const { isReady, serviceAccounts, selectedId, editMode } = this.state
        const isRowAdd = editMode === C_MODE.C_ADD_MODE
        const isRowEdit = (id) => {
            return editMode === C_MODE.C_EDIT_MODE && id === selectedId
        }
        let isEditMode = false

        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    {!isReady && (<Splash showLogo={false} />)}
                    {isReady && (
                        [<Typography key={1} className={classes.titleText} >Service Account</Typography>,
                        <GroupBox key={2} withHeader={false} className={classes.groupBox} cardContent={classes.cardContent}>
                            <div className={classes.table}>
                                <div className={classes.flexItemRow} style={{backgroundColor: darken('#f8f8ff', 0.075)}}>
                                    <div className={classNames(classes.applicationIdCol, classes.colHeader)}>Application Id</div>
                                    <div className={classNames(classes.tableCol, classes.colHeader)}>Public Key</div>
                                    <div className={classNames(classes.tableCol, classes.colHeader)}>Secret</div>
                                    <div className={classNames(classes.tableCol, classes.colHeader)}>Organization</div>
                                    <div className={classNames(classes.orgIdentityCol, classes.colHeader)}>Organization Identity</div>
                                </div>
                                {serviceAccounts && serviceAccounts.map((serviceAccount, key) => {
                                    const isEdit = isRowEdit(serviceAccount._id)
                                    const rowStyle = isEdit ? { padding: 0 } : { padding: 15 }
                                    if (isEdit) {
                                        isEditMode = isEdit
                                    }

                                    return (
                                        <div key={key} className={classNames(classes.flexItemRow, 'table-row')} style={rowStyle}>

                                            {isEdit && (
                                                <ServiceAccountFormEntry onSaveEntry={this._handleOnSaveEntry} onCancelEntry={this._handleOnCancelEntry} serviceAccount={serviceAccount} {...rest} />
                                            )}
                                            {!isEdit && ([
                                            <div key={1} className={classes.applicationIdCol}>{serviceAccount.applicationId}</div>,
                                            <div key={2} className={classes.tableCol}>{serviceAccount.publicKey}</div>,
                                            <div key={3} className={classes.tableCol}>{serviceAccount.secret}</div>,
                                            <div key={4} className={classes.tableCol}>{serviceAccount.org}</div>,
                                            <div key={5} className={classes.orgIdentityCol}>{serviceAccount.orgIdentity}</div>,
                                            <div key={6} className={classes.actionButtonsCol}>
                                                <Button variant="outlined" onClick={this._handleOnClickEdit(serviceAccount._id)}>Edit</Button>
                                                <Button variant="outlined" onClick={this._handleOnClickRemove(serviceAccount._id, serviceAccount.applicationId)}>Remove</Button>
                                            </div>])}
                                        </div>
                                    )
                                })}

                                {!isEditMode && (
                                    <div className={classes.flexItemRow} style={{ backgroundColor: '#f8f8ff', justifyContent: 'flex-end', paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                                        {isRowAdd && (
                                            <ServiceAccountFormEntry onSaveEntry={this._handleOnSaveEntry} onCancelEntry={this._handleOnCancelEntry} {...rest}/>
                                        )}
                                        {!isRowAdd && (
                                            <Button variant="outlined" onClick={this._handleOnClickAdd} style={{ marginRight: 17 }}>Add</Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </GroupBox>]
                    )}
                </div>
            </div>
        )
    }
}

ServiceAccountList.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(ServiceAccountList))