import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import { Typography } from '@mui/material';
import CommManager from '../../managers/CommManager'
import Util from '../../utils/Util'

const styles = theme => ({
    root: {
        flex: 'none',
        height: 60,
        overflow: 'hidden',
        border: '1px solid whitesmoke',
        fontSize: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 8px 0px 8px',
    },
    container: {
        border: '2px dashed #000000',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 5px',
        backgroundColor: 'whitesmoke',
        borderRadius: 5,
        outline: 'none',
    },
    disableColor: {
        color: 'darkgray',
        borderColor: 'darkgray'
    }
})

class FileUpload extends React.Component {

    uploadFile = (file) => {
        const { onSuccess, onError, getFormData } = this.props
        let config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({
                    percentComplete: percentCompleted
                })
            }
        }
        const formData = getFormData ? getFormData() : null
        const commManagerUpload = formData ? CommManager.uploadFile(file, formData, config)
                                           : CommManager.uploadFileForViewer(file, config)

        commManagerUpload.then((res) => {
            if (res.data) {
                this.debounceReset(() => {
                    onSuccess && onSuccess(res.data)
                })
            } else {
                this.setState({
                    files: [],
                    accepted: false
                }, () => {
                    onError && onError(res)
                })
            }
        }).catch((err) => {
            this.setState({
                files: [],
                accepted: false
            }, () => {
                const { error } = err.response.data;
                onError && onError(error)
            })
        })
    }

    reset = (cb) => {
        this.setState({
            files: [],
            accepted: null
        }, () => {
            if (typeof cb === 'function') {
                cb()
            }
        })
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        console.log('DEBUG:', acceptedFiles, rejectedFiles)
    }

    onDropAccepted = (files) => {
        this.setState({ files, accepted: true, onEnter: false }, () => {
            this.uploadFile(files[0])
        })
    }

    onDropRejected = (fileRejections) => {
        const { onError } = this.props;
        this.setState({ files: fileRejections, accepted: false, onEnter: false });
        if (onError) {
            fileRejections.forEach((rejection) => {
                rejection.errors.forEach((error) => {
                    onError(error.message);
                });
            });
        }
    }

    onDragEnter = () => {
        this.setState({ onEnter: true })
    }
    onDragLeave = () => {
        this.setState({ onEnter: false })
    }
    onCancel = () => {
        this.setState({
            files: [],
            accepted: null,
            onEnter: false
        })
    }

    validateFilename = (file) => {
        const format = /OR_GLM-L2-LCFA_(G16|G17|G18)_s(\d{4}\d{3}\d{7})_e(\d{4}\d{3}\d{7})_c(\d{4}\d{3}\d{7}).nc/
        if (!format.test(file.name)) {
            return {
                code: "bad-filename",
                message: "Filename does not conform to GLM standard naming convention"
            };
        }
        return null;
    }

    constructor(props) {
        super(props)
        this.state = {
            files: [],
            accepted: null
        }
    }

    componentDidMount() {
        this.debounceReset = Util.debounce(this.reset, 2000)
    }

    render() {
        const { classes, disabled, error, style } = this.props
        const { files, accepted, onEnter, percentComplete } = this.state
        const containerClassName = disabled ? classNames(classes.container, classes.disableColor, 'select-none') :
                                              classNames(classes.container, 'select-none', 'pointer')
        const localLabelStyle = disabled ? { color: 'darkgray' } : {}
        let localContainerStyle = onEnter ? { backgroundColor: 'gold' } : {}
        if (error) {
            localContainerStyle = { borderColor: 'red' }
        }
        return (
            <div className={classes.root} style={style}>
                {accepted === null && files.length === 0 &&
                    <Dropzone
                        disabled={disabled}
                        accept=".nc, .h5"
                        onDropAccepted={this.onDropAccepted}
                        onDropRejected={this.onDropRejected}
                        onDragEnter={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                        onFileDialogCancel={this.onCancel}
                        validator={this.validateFilename}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div style={localContainerStyle} className={containerClassName} {...getRootProps()}>
                                <input {...getInputProps()} multiple={false} />
                                <div style={localLabelStyle}>Drop a netCDF file here, or click to select</div>
                            </div>
                        )}
                    </Dropzone>
                }
                {accepted === false && files.length > 0 &&
                    <div className={classNames(classes.container, 'select-none')} style={{ borderColor: 'red', borderStyle: 'solid' }}>
                        <Typography style={{ flex: 1 }}>The file you selected is not supported! <span style={{ fontWeight: 'bold' }}>"{files[0].name || files[0].file.name}"</span></Typography>
                        <Button color='primary' variant='contained' size='small' style={{ textTransform: 'capitalize', minHeight: 25, height: 25, padding: '0px 10px', marginLeft: 5, marginRight: 5 }} onClick={this.reset}>Select another file</Button>
                    </div>
                }
                {accepted === false && files.length === 0 &&
                    <div className={classNames(classes.container, 'select-none')} style={{ borderColor: 'red', borderStyle: 'solid' }}>
                        <Typography style={{ flex: 1 }}>The file you selected did not meet the criteria you entered!</Typography>
                        <Button color='primary' variant='contained' size='small' style={{ textTransform: 'capitalize', minHeight: 25, height: 25, padding: '0px 10px', marginLeft: 5, marginRight: 5 }} onClick={this.reset}>Select another file</Button>
                    </div>
                }
                {accepted === true && files.length > 0 &&
                    <div className={classNames(classes.container, 'select-none')} style={{ flexDirection: 'column', borderColor: 'green', borderStyle: 'solid', borderRadius: 5 }}>
                        <Typography style={{ fontWeight: 'bold' }}>{files[0].name}</Typography>
                        <Typography>Uploading file please wait...{percentComplete}%</Typography>
                    </div>
                }
            </div>
        )
    }
}

FileUpload.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FileUpload)
