import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import ButtonProgress from '../ButtonProgress/ButtonProgress'
import classNames from 'classnames'
import _Base from '../_Base'

const styles = theme => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        width: 360,
        maxHeight: 435,
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        padding: '15px 10px 15px 20px',
        '& h2': {
            color: theme.palette.primary.contrastText,
            fontSize: '100%',
        }
    },
    dialogContent: {
        padding: 15,
        overflow: 'hidden',
    },
    dialogActions: {
        margin: '15px 15px',
        justifyContent: 'flex-end'
    }
})

let showConfirmationFunc = () => {}
let hideConfirmationFunc = () => {}

class ConfirmationDialog extends _Base {

    constructor(props) {
        super(props)
        
        this.state = {
            open: false
        }
    }

    handleActionClick = (action) => (e) => {
        const { onClose } = this.state
        this.setState({
            open: false
        }, () => {
            onClose && onClose(action)
        })
    }

    hideConfirmation = () => {
        this.setState({
            open: false
        })
    }

    showConfirmation = (options) => {
        const { titleNode, contentNode, actionNode, okLabel, cancelLabel, onClose, dialogCss } = options

        this.setState({
            open: true,
            titleNode,
            contentNode,
            okLabel,
            cancelLabel,
            actionNode,
            dialogCss,
            onClose,
        })
    }

    componentDidMount() {
        super.componentDidMount()

        showConfirmationFunc = this.showConfirmation.bind(this)
        hideConfirmationFunc = this.hideConfirmation.bind(this)
    }

    render() {
        const { classes, children, ...other } = this.props
        const { open, titleNode, contentNode, actionNode, dialogCss } = this.state
        let { okLabel, cancelLabel } = this.state
        okLabel = okLabel || 'Ok'
        cancelLabel = cancelLabel || 'Cancel'

        let defaultActions = (
            [
                <ButtonProgress style={{ width: 100 }} key={2} variant='contained' onClick={this.handleActionClick('ok')} keepColor color="primary">
                    {okLabel}
                </ButtonProgress>,
                <Button style={{ width: 100 }} key={1} variant='outlined' onClick={this.handleActionClick('cancel')} color="primary">
                    {cancelLabel}
                </Button>
            ]
        )
        if (actionNode) {
            defaultActions = actionNode
        }

        return (
            <Dialog
                open={open}
                disableEscapeKeyDown
                maxWidth={false}
                aria-labelledby="confirmation-dialog-title"
                classes={{
                    paper: classNames(classes.paper,dialogCss),
                }}
                {...other}>
                <DialogTitle id="confirmation-dialog-title" classes={{root: classes.dialogTitle}}>{titleNode}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {contentNode}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {defaultActions}
                </DialogActions>
            </Dialog>
        );
    }
}

ConfirmationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
}

export function showConfirmation(options) {
    showConfirmationFunc(options)
}

export function hideConfirmation(options) {
    hideConfirmationFunc(options)
}

export default withStyles(styles)(ConfirmationDialog)