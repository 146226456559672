import React from "react";
// @mui/material components
import { withStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import classNames from "classnames";

const style = {
  grid: {
    // margin: "0 -15px !important",
    //margin: "0 -15px",
    margin: 0,
    flexGrow: 1,
    height: '100vh',
    // width: "unset",
  }
};

function GridContainer(props) {
  const { classes, children, className, ...rest } = props;
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" {...rest} className={classNames(className, classes.grid)}>
      {children}
    </Grid>
  );
}

export default withStyles(style)(GridContainer);
