import React, { Component } from 'react'

// @mui/icons-material components
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning'

import NASALogoImage from "../assets/img/nasa-logo.svg"

const styles = theme => ({
    root: {
        fontSize: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh'
    },
    messageContent: {
        display: 'flex',
        fontSize: 40,
        color: 'yellow',
        alignItems: 'center',
    }
});


class Whoops404 extends Component {

    render() {

        const { classes } = this.props
        // set title for 508 compliance
        document.title = this.props.title;
        return (
            <div className={classes.root}>
                <div style={{ marginBottom: 20 }}>
                    <img style={{ height: 150, width: 150 }} src={NASALogoImage} alt="" />
                </div>
                
                <div className={classes.messageContent}>
                    <WarningIcon fontSize='inherit' />
                    <Typography variant='h5' style={{padding: 10}}>Oops! File Not Found</Typography>
                </div>
            </div>
        )
    }
}

Whoops404.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Whoops404)
