import React from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Slider from 'react-slick'
// import Util from '../../utils/Util'

import '../../assets/css/slick.css'

const styles = theme => ({
    root: {
        fontSize: '100%',
        width: '100%',
        padding: '5px 35px 20px',
    },

})

// let _slider = null
// let _event = null

const ImageSlider = (props) => {
    const { classes, children, event, ...rest } = props
 
    const sliderSettings = { 
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                }
              },
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
              }
            }
          ]
    }
    return (
        <div className={classes.root}>
            <Slider {...sliderSettings} {...rest} >
                {children}
            </Slider>
        </div>
    )
}

ImageSlider.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
}

export default withStyles(styles)(ImageSlider)
