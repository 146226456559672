import React, { useState } from "react";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Linkify from "linkify-react";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const EventFormView = (props) => {
    const { event, classes } = props;
    const {
        brightness,
        eventDate,
        eventTime,
        duration,
        groundTrack,
        latitude,
        longitude,
        latitudeDelta,
        longitudeDelta,
        beginningAltitude,
        eventName,
        detectedBy,
        confidenceRating,
        lightcurveStructure,
        nearbyLightningActivity,
        howFound,
        otherDetectingSources,
        otherInformation,
        eventDescription,
        isManuallyGenerated,
        pdfFilename,
        humanClassification,
        isPipelineIssue
    } = event;
    const options = { defaultProtocol: 'https', target: "_blank"};

    return (
        <div>
            <div className={classes.flexItemRow}>
                <FormControlLabel
                    control={<Typography>{eventDate}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Date"
                    labelPlacement="top"
                    key="date"
                />
                <FormControlLabel
                    control={<Typography>{eventTime}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Time"
                    labelPlacement="top"
                    key="time"
                />
                <FormControlLabel
                    control={<Typography>{duration.toFixed(3)}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Duration"
                    labelPlacement="top"
                    key="duration"
                />
                <FormControlLabel
                    control={<Typography>{latitude.toFixed(2)}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Latitude"
                    labelPlacement="top"
                    key="latitude"
                />
                <FormControlLabel
                    control={<Typography>{longitude.toFixed(2)}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Longitude"
                    labelPlacement="top"
                    key="longitude"
                />
                <FormControlLabel
                    control={<Typography>{latitudeDelta.toFixed(2)}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Δ&nbsp;Latitude"
                    labelPlacement="top"
                    key="deltaLatitude"
                />
                <FormControlLabel
                    control={<Typography>{longitudeDelta.toFixed(2)}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Δ&nbsp;Longitude"
                    labelPlacement="top"
                    key="deltaLongitude"
                />
                <FormControlLabel
                    control={<Typography>{beginningAltitude ? beginningAltitude.toFixed(2) : ""}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Beginning Altitude (km)"
                    labelPlacement="top"
                    key="beginningAltitude"
                />
            </div>
            <div className={classes.flexItemRow} style={{ alignItems: "start" }}>
                { eventName && 
                    <FormControlLabel
                        control={<Typography>{eventName}</Typography>}
                        className={classes.formControl}
                        classes={{ label: classes.label }}
                        label="Name"
                        labelPlacement="top"
                        key="name"
                    />
                }
                <FormControlLabel
                    control={<Typography>{detectedBy}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Detected By"
                    labelPlacement="top"
                    key="detectedBy"
                />
                <FormControlLabel
                    control={<Typography>{confidenceRating}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Confidence Rating"
                    labelPlacement="top"
                    key="confidenceRating"
                />
                <FormControlLabel
                    control={<Typography>{lightcurveStructure}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Lightcurve Structure"
                    labelPlacement="top"
                    key="lightcurveStructure"
                />
                <FormControlLabel
                    control={<Typography>{nearbyLightningActivity}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Nearby Lightning Activity"
                    labelPlacement="top"
                    key="nearbyLightningActivity"
                />
                <FormControlLabel
                    control={<Typography>{`${isPipelineIssue}`}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Pipeline Issue"
                    labelPlacement="top"
                    key="isPipelineIssue"
                />
                    
                {howFound && Object.keys(howFound).map((satName, idx) => {
                    return (
                        <FormControlLabel
                            control={<Typography>{howFound[satName].join(", ")}</Typography>}
                            className={classes.formControl}
                            classes={{ label: classes.label }}
                            label={`How Found: ${satName}`}
                            labelPlacement="top"
                            key={"howFound" + idx}
                        />
                    );
                })}
                {brightness && Object.keys(brightness).map((satName, idx) => {
                    return (
                        <FormControlLabel
                            control={<Typography>{brightness[satName]["category"]}</Typography>}
                            className={classes.formControl}
                            classes={{ label: classes.label }}
                            label={`Brightness: ${satName}`}
                            labelPlacement="top"
                            key={"brightness" + idx}
                        />
                    );
                })}
                {groundTrack && Object.keys(groundTrack).map((satName, idx) => {
                    return (
                        <FormControlLabel
                            control={<Typography>{groundTrack[satName]["category"]}</Typography>}
                            className={classes.formControl}
                            classes={{ label: classes.label }}
                            label={`Ground Track: ${satName}`}
                            labelPlacement="top"
                            key={"groundTrack" + idx}
                        />
                    );
                })}
                <FormControlLabel
                    control={<Typography>{humanClassification}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Human Classification"
                    labelPlacement="top"
                    key="humanClassification"
                />
                <FormControlLabel
                    control={<Typography>{otherDetectingSources}</Typography>}
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    label="Other Detecting Sources"
                    labelPlacement="top"
                    key="otherDetectingSources"
                />
            </div>
            {otherInformation && (
                <div className={classes.flexItemRow}>
                    <div style={{ width: "100%", overflow: "auto", padding: 8 }}>
                        <div
                            style={{
                                color: "rgba(0, 0, 0, 0.54)",
                                fontSize: "0.8rem",
                                marginBottom: 5,
                            }}
                        >
                            Other Information
                        </div>
                        <Typography>
                            <Linkify as="span" options={options}>
                                {otherInformation}
                            </Linkify>
                        </Typography>
                    </div>
                </div>
            )}
            {eventDescription && (
                <div className={classes.flexItemRow}>
                    <div style={{ width: "100%", overflow: "auto", padding: 8 }}>
                        <div
                            style={{
                                color: "rgba(0, 0, 0, 0.54)",
                                fontSize: "0.8rem",
                                marginBottom: 5,
                            }}
                        >
                            Description
                        </div>
                        <Typography>
                            <Linkify as="span" options={options}>
                                {eventDescription}
                            </Linkify>
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventFormView;
