import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'

// jss
import { root, container } from '../assets/jss/index'
import Globals from '../variables/globals'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    color: 'black',
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content-page": {
      flex: 1,
      overflowY: 'auto',
      padding: '40px 40px',
      '& div.table-wrap': {
        background: 'none'
      },
      '& a': {
        color: 'blue',
      }
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: "100%"
  },
  title: {
    fontSize: 24,
    fontWeight: 'normal',
    lineHeight: 1.25,
  },
  toolbar: theme.mixins.toolbar,
  bc_link: {
    width: 100,
    fontSize: '1rem',
    marginBottom:10,
    display: 'flex',
    color: '#rgba(0,0,0,0.6)',
    '&:hover': {
      color: 'black'
    }
  },
  bc_icon: {
    marginRight: theme.spacing(0.3),
    width: 20,
    height: 20,
  },
})

const HowTo = (props) => {
  const { classes, ...rest } = props
  const viewName = 'HowTo'
  Globals.viewsAnimationTimeoutDict[viewName] = Globals.viewsAnimationTimeoutDict[viewName] === undefined ? 2000 : 0
  const aniTimeout = Globals.viewsAnimationTimeoutDict[viewName]

  return (
    <div className={classNames('full-screen', classes.root)}>
      <div className={classes.container}>
        <div className='app-bar'>
          <MainAppBar showBackButton={true} {...rest} />
        </div>
        <div className={classes.toolbar}></div>
        <Fade in={true} timeout={aniTimeout}>
          <div className='content-page' style={{ padding: '10px 28px' }}>

            <Link to="/info" className={classes.bc_link}>
              <ArrowBackIcon className={classes.bc_icon} />
               Back
            </Link>

            <h1>How to use this website</h1>
            <p>
              The website provides a variety of interfaces to examine, search and download the data.   
              The three primary interfaces are the home page, the event list page, and the event detail page.
            </p>

            <h2>Home Page</h2>
            <p>The home page includes a map of events, a bar chart of the number of events over time, and a search interface.&nbsp; On the left hand side is a chart of the geographic location of each bolide candidate in the database.&nbsp; The dots are color coded to specify whether the event was detected by GOES East (GLM-16), GOES West (GLM-17, GLM-18), or by both.&nbsp; The intensity of the color reflects the confidence rating of the event.&nbsp; A legend of these colors can be found by clicking on the “Show Legend” button at the upper right hand corner of the map of events.&nbsp; Details for each event can be accessed by hovering over the dot.&nbsp; The hover pop-up includes a link to the event detail page.&nbsp; The bar chart on the right shows the number of events as a function of time.&nbsp; Specific years can be requested via the drop down menu.</p>
            <p>Directly below the map are two ways to search for events by date.&nbsp; The date range can be modified by moving the dots on the slider bar or by entering the desired dates in the text boxes.&nbsp; &nbsp;The map and bar chart will be updated to show only the events that fall within the specified date range.&nbsp; The event list view for all events that are within the requested date range can be requested by either clicking the "Show Details" button at the bottom right or the "Event List" option at the top right.&nbsp; &nbsp;To reset the home page to show all events, click the box next to "Reset all dates".</p>
            <p>In order to search for events using additional criteria, click "Show Advanced Search".&nbsp; This will provide options to search by satellite, confidence rating, location, duration, and keyword.&nbsp; Location searches are constrained by specifying a latitude or longitude and a range around that value to include in the search.&nbsp; Any text associated with individual events can be queried by the keyword search.&nbsp; This text is visible on the event detail page.&nbsp; &nbsp;For instance, in order to identify events that were also observed by USG sensors (as listed at&nbsp;<a target="_blank" href="https://cneos.jpl.nasa.gov/fireballs/" className="external-link" rel="noopener noreferrer nofollow">https://cneos.jpl.nasa.gov/fireballs/</a>), the keyword USG could be specified.&nbsp; &nbsp;Phrases can be used as keywords if they are enclosed in quotes, such as "not isolated".&nbsp; Once all desired search criteria are specified, click the "Apply" button to perform the search.&nbsp; The map and bar chart will update.&nbsp; A list of all events meeting the search criteria can be requested&nbsp;by either clicking the "Show Details" button at the bottom right or the "Event List" option at the top right.&nbsp; &nbsp;To reset the home page to show all events, click on the "Reset" button.</p>
            <p>An additional search interface can be accessed by clicking on the search icon in the top right corner.&nbsp; This search interface includes all the same options as the advanced search interface.&nbsp; In addition to updating the plots to include only the selected events, a table showing the number of events by satellite and by confidence rating matching the criteria can be accessed by clicking the down arrow next to total number of selected events&nbsp;</p>

            <h2>Event List Page</h2>
            <p>The events list page can be accessed by clicking the “Events List” button at the top of the home page or by clicking the “Show Details” button in the search section of the home page. The events list page contains a list of events that meet a given search criteria. The top of the events list page contains thumbnail plots of the geographic location , the measured energy as a function of time, and the ground track location of a selected event. Some events are detected by both GOES East (GLM-16) and GOES West (GLM-17, GLM-18) or in multiple netCDF files.&nbsp; In those cases, there will be a plot of energy as a function of time and ground track for each underlying netCDF file.&nbsp; Below the thumbnail plots is the tabular list of GLM events.&nbsp; For each event the date and time, geodetic latitude, geodetic longitude, platform, and basic information are listed.&nbsp; By default the last event is listed first, but the list can be sorted by any column from either largest to smallest or smallest to largest by clicking the column of interest. The event detail page can be accessed for any event by clicking on the eye icon.&nbsp; A CSV file containing the data for an event can be downloaded by clicking on the cloud icon.&nbsp;&nbsp;In the case an event is detected in by both GLM instruments or in multiple netCDF files, there will be multiple CSV files.&nbsp; The default name of the CSV files reflects the name of the original GLM L2 netCDF file.&nbsp;</p>
            <p>Searches can be performed while on the event list page by selecting the search icon in the top right corner.&nbsp; This search interface includes all the same options available in the advanced search interface on the home page.</p>
            
            <h2>Event Detail Page</h2>
            <p>An event detail page includes all the known details about a candidate bolide event.&nbsp; &nbsp;The quantities displayed in the black bar were entered by the subject matter expert to identify the event for inclusion in the database.&nbsp; As a result, the time, lat and long shown in the black bar are approximate.&nbsp; Immediately under the black bar are several pieces of additional information entered by the subject matter expert to provide additional context for each event such as confidence rating, other sources of data for the event, and additional comments.&nbsp; Below that, a card is shown for each GLM level 2 netCDF file containing data about the event.&nbsp; The header of these cards shows the name of the netcdf file.&nbsp; The time, duration, and location reported in the file are also shown.&nbsp; The plots show the geographic location, the ground track, and the light curve.&nbsp; The reported energies, location, and time are all calculated assuming the signal is due to lightning.&nbsp; Additional information about corrections which may be needed can be found <a href="#/info/about">here</a>.&nbsp; Hovering over points in the ground track and light curve plots will yield additional information.&nbsp; A CSV file containing the data from extracted from the netCDF file can be downloaded by clicking on the cloud icon.&nbsp;</p>
            <p><span>Contact Us</span>&nbsp;<span>✉</span></p>
            <p><a href="mailto:ARC-bolide-data@mail.nasa.gov" className="external-link" rel="nofollow">ARC-bolide-data@mail.nasa.gov</a>&nbsp;</p>

            <p><br /></p>
            <p><br /></p>
            <p><br /></p>

          </div>
        </Fade>
      </div>

    </div>
  )
}

HowTo.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(HowTo))
