import React from 'react'
import PropTypes from 'prop-types';

// @mui/material components & api
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField'

// React components & functions
import BaseComponent from '../_Base'
import { C_MODE } from '../../variables/common'
import ButtonProgress from '../ButtonProgress/ButtonProgress'
import AppState from '../../managers/AppStateManager'

const styles = theme => ({
    root: {
        fontSize: '100%',
        backgroundColor: '#f8f8ff',
        width: '100%',
    },
    container: {
        padding: '10px 10px 10px 10px',
        width: '100%',
        display: 'flex',
    },
    flexItemRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 5,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 8,
    },
    inputLabel: {
        backgroundColor: '#f8f8ff',
        paddingLeft: 3,
        paddingRight: 3,
    },
})

class ServiceAccountFormEntry extends BaseComponent {
    constructor(props){
        super(props)
        this.state = this._generateInitialState()
    }

    _saveApplicationIdData = (cb) => {
        const {
            serviceAccount,
            applicationId,
            secret,
            publicKey,
            org,
            orgIdentity,
            editMode } = this.state

        // save service account
        if (editMode === C_MODE.C_ADD_MODE){
            const payload = {
                isNew: editMode === C_MODE.C_ADD_MODE,
                id: serviceAccount ? serviceAccount._id : null,
                applicationId: applicationId,
                secret: secret,
                publicKey: publicKey,
                org: org,
                orgIdentity: orgIdentity
            }
            AppState.saveServiceAccount(payload, cb)
        }            

        //update service account
        if (editMode !== C_MODE.C_ADD_MODE){
            const payload = {
                isNew: editMode === C_MODE.C_ADD_MODE,
                id: serviceAccount ? serviceAccount._id : null,
                applicationId: applicationId,
                // secret: secret,
                publicKey: publicKey,
                org: org,
                orgIdentity: orgIdentity
            }
            AppState.saveServiceAccount(payload, cb)
        }   
        
    }

    _onClickButtonCancel = () => {
        const { onCancelEntry } = this.props
        this.setState({
            ...this._generateInitialState()
        }, () => {
            onCancelEntry && onCancelEntry()
        })
    }

    _handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        })
    }

    _onClickButtonSave = (event, button) => {
        const { applicationId } = this.state
        const { onSaveEntry } = this.props

        const save = () => {
            this._saveApplicationIdData((res) => {
                if (!res.data.success) {
                    this.showMessage("Error on saving the account", 'error', true)
                    button.reset()
                    return
                }
                
                const serviceAccount = res.data.data
                // // this.showMessage('User has been successfully saved', 'info', true)
                onSaveEntry && onSaveEntry(serviceAccount)
            })
        }

        save()
    }

    _generateInitialState = () => {
        const selectedServiceAccount = this.props.serviceAccount
        const editMode = selectedServiceAccount ? C_MODE.C_EDIT_MODE : C_MODE.C_ADD_MODE

        let applicationId, publicKey, secret, org, orgIdentity, applicationIdError, secretError

        if(selectedServiceAccount){
            applicationId = selectedServiceAccount.applicationId
            publicKey = selectedServiceAccount.publicKey ? selectedServiceAccount.publicKey : ''
            secret = selectedServiceAccount.secret ? selectedServiceAccount.secret : ''
            org = selectedServiceAccount.org ? selectedServiceAccount.org : ''
            orgIdentity = selectedServiceAccount.orgIdentity ? selectedServiceAccount.orgIdentity : ''
        } else {
            applicationId = ''
            publicKey = ''
            secret = ''
            org = ''
            orgIdentity = ''
        }

        applicationIdError = false
        secretError = false

        const state = {
            serviceAccount: selectedServiceAccount,
            editMode: editMode,
            applicationId: applicationId,
            publicKey: publicKey,
            secret: secret,
            org: org,
            orgIdentity:  orgIdentity,
            applicationIdError: applicationIdError,
            secretError: secretError,
        }

        return state

    }

    render(){
        const { classes } = this.props
        let {
            applicationId,
            publicKey,
            secret,
            org,
            orgIdentity,
            applicationIdError,
            secretError,
            serviceAccount
        } = this.state
        const buttonDisabled = applicationIdError || secretError

        return(
            <div className={classes.root}>
                <div className={classes.container} style={{ flexDirection: 'column' }}>
                    <div className={classes.flexItemRow}>
                        <TextField
                            autoFocus={true}
                            style={{ flex: 'none', width: 287 }}
                            label="Application ID"
                            className={classes.textField}
                            value={applicationId}
                            error = {applicationIdError}
                            onChange={this._handleChange('applicationId')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                            InputProps={{
                                inputProps: { maxLength: 25 }
                            }}
                        />
                        <TextField
                            autoFocus={true}
                            style={{ flex: 'none', width: 287 }}
                            disabled = {serviceAccount}
                            label="Secret"
                            className={classes.textField}
                            value={secret}
                            onChange={this._handleChange('secret')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                        />
                        <TextField
                            autoFocus={true}
                            style={{ flex: 'none', width: 287 }}
                            label="Public Key"
                            className={classes.textField}
                            value={publicKey}
                            onChange={this._handleChange('publicKey')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                        />
                    </div>
                    <div className={classes.flexItemRow}></div>
                    <div className={classes.flexItemRow}>
                        <TextField
                            autoFocus={true}
                            style={{ flex: 'none', width: 287 }}
                            label="Organization"
                            className={classes.textField}
                            value={org}
                            onChange={this._handleChange('org')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                        />
                        <TextField
                            autoFocus={true}
                            style={{ flex: 'none', width: 287 }}
                            label="Organization Identity"
                            className={classes.textField}
                            value={orgIdentity}
                            onChange={this._handleChange('orgIdentity')}
                            margin="dense"
                            variant="outlined"
                            InputLabelProps={{
                                classes: {
                                    outlined: classes.inputLabel
                                }
                            }}
                        />
                    </div>
                    <div className={classes.flexItemRow}></div>
                    <div className={classes.flexItemRow} style={{marginRight: 8, justifyContent: 'flex-end'}}>
                        <ButtonProgress variant="outlined" disabled={buttonDisabled} onClick={this._onClickButtonSave}>Save</ButtonProgress>
                        <Button variant="outlined" style={{marginLeft: 5}} onClick={this._onClickButtonCancel}>Cancel</Button>
                    </div>
                </div>
            </div>
        )
    }
}

ServiceAccountFormEntry.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ServiceAccountFormEntry)
