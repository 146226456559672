//
// Base class for all Managers...
//

class Base {

    constructor() {
        this._events = new Map()
    }

    addEventListener = (topic, handler) => {
        var handlers = this._events.get(topic)
        var update = false
        if (!handlers) {
            handlers = [handler]
            update = true
        } else {
            var idx = handlers.indexOf(handler)
            if (idx === -1) {
                handlers.push(handler)
                update = true
            }
        }
        if (update) {
            this._events.set(topic, handlers)
        }
    }

    removeEventListener = (topic, handler) => {
        var handlers = this._events.get(topic)
        if (handlers && handlers instanceof Array) {
            var idx = handlers.indexOf(handler)
            if (idx !== -1) {
                handlers.splice(idx, 1)
            }
            if (handlers.length > 0) {
                this._events.set(topic, handlers)
            } else {
                this._events.delete(topic)
            }
        }
    }

    dispatchEvents = (topic, data) => {
        const handlers = this._events.get(topic)
        if (handlers && handlers.length > 0) {
            handlers.forEach((f) => {
                f(data)
            })
        }
    }
}

export default Base