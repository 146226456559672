import React , {useState} from 'react'
import { makeStyles } from '@mui/styles'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Collapse from '@mui/material/Collapse'
import AppState from '../../managers/AppStateManager'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        fontSize: '100%',
    },
    heading: {
        fontSize: '0.8rem'
    },
    secondaryHeading: {
        fontSize: '0.8rem',
        color: theme.palette.text.secondary,
    },
    headerBox: {
        display: 'flex',
        padding: 10,
        border: '1px solid #dcdcdc',
        backgroundColor: 'whitesmoke',
        marginBottom: 5,
        borderRadius: 3,
        fontSize: '1rem',
        '& .text': {
            flex: 1,
        },
        '& .count': {
            flex: 'none',
            width: 40
        }
    },
    expansionPanel: {
        fontSize: '100%',
        '& 	.MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0px',
            minHeight: 'unset',
        },
    },
    tableDetail: {
        fontSize: '0.8rem',
        width: '100%',
        '& td, & th': {
            padding: 5
        },
        '& tr td:nth-child(2)': {
            verticalAlign: 'middle',
            width:'22%', 
            fontWeight: 500,
            textAlign: 'start'
        },
        '& tr td:nth-child(n+3)': {
            verticalAlign: 'middle',
            width:'17%',
            fontWeight: 500,
            textAlign: 'end'
        },
        '& tr th:nth-child(1)': {
            width:'10%'
        },
        '& tr th:nth-child(2)': {
            width:'22%'
        },
        '& tr th:nth-child(n+3)': {
            width:'17%',
            textAlign: 'end',
        },
        '& tbody tr:nth-child(7)': {            
            backgroundColor: 'whitesmoke',
            borderTop: '2px solid gray',
            '& td': {
                fontWeight: 'bold'
            }
        },
        '& tbody tr': {
            borderTop: '1px solid whitesmoke',
        },
    },
    breakdownExpandTable: {
        fontSize: '0.8rem',
        width: '100%',
        '& td': {
            padding: 5
        },
        '& tbody tr td:nth-child(1)': {
            width: '10%',
            color: '#0A3D91'
        },
        '& tbody tr td:nth-child(2)': {
            width: '22%',
            color: '#0A3D91',
            textAlign:'start'
        },
        '& tbody tr td:nth-child(n+3)': {
            width: '17%',
            color: '#0A3D91',
            textAlign:'end'
        },
    }
}))

function Row(props){
    const { name, breakdowns, row } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles()
    
    return (
        <React.Fragment>
            <tr>
                <td>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> :  <KeyboardArrowRightIcon />}
                    </IconButton>
                </td>
                <td>{name}</td>
                <td>{row.low}</td>
                <td>{row.medium}</td>
                <td>{row.high}</td>
                <td>{row.total}</td>
            </tr>
            <tr>
                <td style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, backgroundColor:'rgba(63,80,181,0.2)'}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <table className={classes.breakdownExpandTable}>
                            <tbody>
                                {breakdowns.map((breakdown) => (
                                    <tr key={`${name}-${breakdown}`}> 
                                        <td>&nbsp;</td>  
                                        <td>{breakdown}</td>
                                        <td>{row[breakdown].low}</td>
                                        <td>{row[breakdown].medium}</td>
                                        <td>{row[breakdown].high}</td>
                                        <td>{row[breakdown].total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default function ControlledExpansionPanels({events}) {
    const classes = useStyles()

    const generateStatisticData = () => {
        let possibleStereo = ['GLM-16,GLM-17', 'GLM-16,GLM-18', 'GLM-16,GLM-17,GLM-18']
        let possibleGoesEast = ['GLM-16']
        let possibleGoesWest = ['GLM-17', 'GLM-18', 'GLM-17,GLM-18']

        let statData = {
            'GOES-EAST': { 
                low: 0, 
                medium: 0, 
                high: 0, 
                total: 0,
                'GLM-16': { low: 0, medium: 0, high: 0, total: 0 },
            },
            'GOES-WEST': { 
                low: 0, 
                medium: 0, 
                high: 0, 
                total: 0,
                'GLM-17': { low: 0, medium: 0, high: 0, total: 0 },
                'GLM-18': { low: 0, medium: 0, high: 0, total: 0 },
            },
            'Stereo': { 
                low: 0, 
                medium: 0, 
                high: 0, 
                total: 0,
                'GLM-16': { low: 0, medium: 0, high: 0, total: 0 },
                'GLM-17': { low: 0, medium: 0, high: 0, total: 0 },
                'GLM-18': { low: 0, medium: 0, high: 0, total: 0 },
            },
            'Total': { low: 0, medium: 0, high: 0, total: 0 }
        }

        events.forEach(e => {
            if (e.detectedBy.length > 0) {
                let key = e.detectedBy 
                if(possibleStereo.includes(e.detectedBy)){
                    key = 'Stereo'
                }
                if (possibleGoesEast.includes(e.detectedBy)){
                    key = 'GOES-EAST'
                }
                if (possibleGoesWest.includes(e.detectedBy)){
                    key = 'GOES-WEST'
                }


                let d = statData[key]
                if (!d) {
                    statData[key] = { 'low': 0, 'medium': 0, 'high': 0 }
                    d = statData[key]
                }
                
                if (e.confidenceRating.length > 0) {
                    const bolides = e.detectedBy.split(",");

                    bolides.map(b => {
                        const currentDetectList = AppState.detectList

                        if(currentDetectList.includes(b) || key === 'Stereo'){
                            d[b][e.confidenceRating] += 1
                            d[b]['total'] += 1
                        }

                    })
                    d[e.confidenceRating] += 1
                }

            }
        })

        let lowTotal = 0
        let mediumTotal = 0
        let highTotal = 0
        Object.keys(statData).forEach(k => {
            let sd = statData[k]
            sd.total = sd['low'] + sd['medium'] + sd['high']
            lowTotal += sd['low']
            mediumTotal += sd['medium']
            highTotal += sd['high']
            statData[k] = sd
        })
        statData['Total'] = { 'low': lowTotal, 'medium': mediumTotal, 'high': highTotal, 'total': lowTotal + mediumTotal + highTotal }
        return statData
    }
    const statData = generateStatisticData()
    
    return (
        <div className={classes.root}>
            <Accordion className={classes.expansionPanel}>
                <AccordionSummary
                    expandIcon={events.length > 0 ? <ExpandMoreIcon /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Total number of detections found based on the current search criteria...</Typography>
                    <Chip style={{ marginLeft: 20, marginRight: 10, fontSize: '100%' }} color="secondary" label={statData['Total']['total']} />

                </AccordionSummary>
                < AccordionDetails style={{ borderTop: '1px solid #dedede' }}>
                    {events.length > 0 &&
                        <React.Fragment>
                            <table className={classes.tableDetail}>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>&nbsp;</th>
                                        <th>low</th>
                                        <th>med</th>
                                        <th>high</th>
                                        <th>total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Row name={'GOES-EAST'} breakdowns={['GLM-16']} row={statData['GOES-EAST']} />
                                    <Row name={'GOES-WEST'} breakdowns={['GLM-17','GLM-18']} row={statData['GOES-WEST']} />
                                    <Row name={'Stereo'} breakdowns={['GLM-16','GLM-17','GLM-18']} row={statData['Stereo']} />
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>Total</td>
                                        {Object.keys(statData['Total']).map((prop, key) => {
                                            const d = statData['Total'][prop]
                                            return (
                                                <td key={key}>{d}</td>
                                            )
                                        })}
                                    </tr>
                                </tbody>
                            </table>
                        </React.Fragment>
                    }
                </AccordionDetails>
            </Accordion>
        </div >
    );
}
