import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/reset.css'
import './assets/css/index.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

window.BOLIDES_APP_SERVICE_ROOT_URL = "";
window.BOLIDES_APP_IMAGE_SERVER_URL = "/img/";
window.BOLIDES_APP_CSV_SERVER_URL = "/csv/";
window.BOLIDES_APP_PDF_SERVER_URL = "/pdfs/";

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
