import Base from './_Base'
import axios from 'axios'

// axios.defaults.baseURL = window.BOLIDES_APP_SERVICE_ROOT_URL

class CommManager extends Base {

    constructor() {
        super()
        this._baseServiceUrl = axios.defaults.baseURL
        this._token = null
    }

    get baseServiceUrl() {
        return this._baseServiceUrl
    }

    getToken =() => {
        return new Promise((resolve, reject) => {
            if (this._token) {
                return resolve(this._token)
            }
            axios.get('/csrf').then((res) => {
                this._token = res.data.csrf_token
                axios.defaults.headers['x-csrf-token'] = this._token
                resolve(this._token)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    getServerConfig = () => {
        axios.defaults.headers['client-confirm'] = 'true';
        return axios.get('/server/config')
    }

    checkSession = () => {
        return axios.post('/service/session')
    }

    login = (payload) => {
        return axios.post('/service/user/login', payload)
    }

    logout = () => {
        return axios.get('/service/user/logout')
    }
    getUsers = () => {
        return axios.get('/service/user')
    }
    selectUser = (payload) => {
        return axios.post('/service/user/selectuser', payload)
    }
    selectRole = (payload) => {
        return axios.post('/service/user/selectrole', payload)
    }

    getAllEvents = () => {
        return axios.get("/service/event")
    }

    getSmeEvents = () => {
        return axios.get("/service/event/sme")
    }

    getReviewerEvents = () => {
        return axios.get("/service/event/reviewer")
    }

    getPublicEvents = () => {
        return axios.get("/service/event/public")
    }

    getEvent = (id) => {
        return axios.get("/service/event/" + id)
    }

    getEventByDate = (date) => {
        date = date.replace(/[/,:,\s]/g, '_')
        return axios.get("/service/event/date/" + date)
    }

    getUserByUsername = (userName) => {
        const payload = { username: userName }
        return axios.post("/service/user/username", payload)
    }

    getUserByEmail = (email) => {
        const payload = { email: email }
        return axios.post("/service/user/email", payload)
    }

    submitEvent = (payload) => {
        return axios.post('/service/event/submit', payload)
    }

    rejectEvent = (payload) => {
        return axios.post('/service/event/reject', payload)
    }

    publishEvent = (payload) => {
        return axios.post('/service/event/publish', payload)
    }

    changeEventStatus = (payload) => {
        return axios.post('/service/event/changestatus', payload)
    }

    searchEvents = (payload) => {
        return axios.post('/service/event/search', payload)
    }
    saveEvent = (payload) => {
        if(payload.isNew) {
            return axios.put('/service/event', payload)
        }
        return axios.post('/service/event', payload)
    }
    saveUser = (payload) => {
        if(payload.isNew) {
            return axios.put('/service/user', payload)
        }
        return axios.post('/service/user', payload)
    }
    getEventGeoData = (payload) => {
        return axios.post('/service/event/refreshgeodata', payload)
    }

    updateEvent = (payload) => {
        return axios.post('/service/event', payload)
    }

    regenerateEventFiles = (payload) => {
        return axios.post('/service/event/regeneratestaticfiles', payload)
    }

    deleteEvent = (id) => {
        const payload = { data: { id: id } }
        return axios.delete('/service/event', payload)
    }

    deleteUser = (id) => {
        const payload = { data: { id: id } }
        return axios.delete('/service/user', payload)
    }

    uploadFile = (fileObj, formData, progressConfig) => {
        let data = new FormData()

        if (formData) {
            Object.keys(formData).forEach((key) => {
                data.append(key, formData[key])
            })
        }
        data.append('file', fileObj)
        return axios.post('/service/upload', data, progressConfig)
    }

    uploadFileForViewer = (fileObj, progressConfig) => {
        let data = new FormData()
        data.append('file', fileObj)
        return axios.post('/service/upload/viewer', data, progressConfig)
    }

    exportMapImage = (options) => {
        return axios.post('/service/imgexport', options)
    }

    getServerResource = (url) => {
        //return axios.get(url)
        return axios({
            url: url,
            method:'GET',
            responseType: 'text', 
        })
    }

    //service account
    getServiceAccount = () => {
        return axios.get('/service/serviceaccount')
    }

    saveServiceAccount = (payload) => {
        if(payload.isNew) {
            return axios.post('/service/serviceaccount', payload)
        }
        return axios.put('/service/serviceaccount', payload)
    }

    deleteServiceAccount = (payload) => {
        return axios.delete('/service/serviceaccount', payload)
    }

    //admin tags
    getTags = () => {
        return axios.get('/service/admin/tags');
    }

    saveTags = (payload) => {
        return axios.post('/service/admin/tags', payload)
    }

}

const commManager = new CommManager()


export default commManager