import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { alpha } from '@mui/material/styles'
import { withStyles } from '@mui/styles'
import Drawer from '@mui/material/Drawer'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import InformationIcon from '@mui/icons-material/InfoOutlined'
import LinearProgress from '@mui/material/LinearProgress'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import EventNoteIcon from '@mui/icons-material/EventNote'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import SearchIcon from '@mui/icons-material/Search'
import NASALogoImage from '../../assets/img/nasa-logo.svg'

// _Base component....
import _Base from '../_Base'
import AppState from '../../managers/AppStateManager'
import { C_EVENT_IDS } from '../../variables/common'
import EventSearchForm from '../../components/EventSearchForm/EventSearchForm'

// jss
import { root } from "../../assets/jss/index.js"

const drawerWidth = 540
const styles = theme => ({
  root: {
    ...root,
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer - 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    color: 'white'
  },
  logoAppNamePanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: 325,
    userSelect: 'none',
    cursor: 'pointer',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: 1,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbarLogo: {
    height: 50,
    width: 50,
  },
  tooltipPopper: {
    opacity: 1,
  },
  toolTip: {
    backgroundColor: '#fec32d',
    color: 'black',
    padding: 10,
    fontSize: '1.2em',
    opacity: 1,
  },
  toolbar: theme.mixins.toolbar,
  breadCrumb: {
    userSelect: 'none',
    color: 'whitesmoke'
  },
  bc_item: {
    alignItems: 'center',
    '& a > svg': {
      marginTop: 3,
      marginLeft: 5
    },
    '& p > svg': {
      marginTop: 3,
      marginLeft: 5
    }
  },
  bc_link: {
    display: 'flex',
    color: '#cdcdcd',
    textDecoration: 'underline',
    '&:hover': {
      color: 'white'
    }
  },
  bc_text: {
    display: 'flex',
    color: 'white',
  },
  bc_icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  }
})

class MainAppBar extends _Base {

  closeDrawer = () => {
    this.setState({
      openDrawer: false,
    })
  }

  handleOpenDrawer = () => {
    this.setState({
      openDrawer: true,
    }, () => {
      AppState.openMainDrawer()
    })
  }
  handleRedirectReadMe = () => {
    this.props.history.push('/about')
  }
  handleCloseDrawer = () => {
    this.setState({
      openDrawer: false
    }, () => {
      AppState.closeMainDrawer()
    })
  }
  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }
  handleMenuLogout = () => {
    AppState.logout(() => {
      this.handleMenuClose()
    })
  }
  handleLogoClick = () => {
    this.props.history.push('/')
  }
  handleMenuChangeRole = (name) => e => {
    name = name.toLowerCase()
    switch (name) {
      case 'sme':
        this.handleMenuClose()
        this.props.history.push('/admin/sme')
        break
      case 'reviewer':
        this.handleMenuClose()
        this.props.history.push('/admin/reviewer')
        break
      case 'admin':
        this.handleMenuClose()
        this.props.history.push('/admin/settings')
        break
      default:
        AppState.changeRole(() => {
          this.handleMenuClose()
        })
    }
  }

  handleOnUserLoggedIn = (res) => {
    this.setState({
      currentUser: AppState.currentUser
    })
  }

  handleOnUserLoggedOut = () => {
    this.setState({
      currentUser: AppState.currentUser
    }, () => {
      this.props.history.push('/admin')
    })
  }
  handleOnUserChangeRole = () => {
    this.setState({
      currentUser: AppState.currentUser
    }, () => {
      this.props.history.push('/admin')
    })
  }
  handleOnSetModuleName = (name) => {
    this.setState({
      moduleName: name
    })
  }
  // handleOnScreenModeChanged = (fullscreen) => {
  //   console.log('DEBUG: MainAppBar fullscreen', fullscreen)
  //   this.setState({
  //     fullscreen: fullscreen
  //   })
  // }
  handleAjaxStarted = () => {
    this.setState({
      isBusy: true
    })
  }
  handleAjaxEnded = () => {
    this.setState({
      isBusy: false
    })
  }
  handleAjaxError = () => {
    this.setState({
      isBusy: false
    })
  }
  redirectHome = () => {
    this.props.history.push('/')
  }
  handleBreadcrumbClick = (path) => (event) => {
    event.preventDefault()
    let prevPath = this.props.location && this.props.location.state ? this.props.location.state.prevPath : ''
    if (path === '/search') {
      if (window.location.href.indexOf('ref=main') !== -1) {
        prevPath = '/'
      }
      if (prevPath !== '') {
        path = prevPath
      }
      if (path === '/search') {
        const pl = { pathname: '/search', state: { prevPath: this.props.location.pathname } }
        return this.props.history.push(pl)
      }
    }
    this.props.history.push(path)
  }

  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      moduleName: '',
      displayLayout: AppState.displayLayout,
      fullscreen: AppState.isFullscreen,
      isBusy: false
    }
  }

  componentDidMount() {

    AppState.addEventListener(C_EVENT_IDS.C_ON_USER_LOGGED_IN, this.handleOnUserLoggedIn)
    AppState.addEventListener(C_EVENT_IDS.C_ON_USER_LOGGED_OUT, this.handleOnUserLoggedOut)
    AppState.addEventListener(C_EVENT_IDS.C_ON_USER_CHANGE_ROLE, this.handleOnUserChangeRole)
    AppState.addEventListener(C_EVENT_IDS.C_ON_SET_MODULE_NAME, this.handleOnSetModuleName)

    AppState.addEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_STARTED, this.handleAjaxStarted)
    AppState.addEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ENDED, this.handleAjaxEnded)
    AppState.addEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ERROR, this.handleAjaxError)
  }

  componentWillUnmount() {
    AppState.removeEventListener(C_EVENT_IDS.C_ON_USER_LOGGED_IN, this.handleOnUserLoggedIn)
    AppState.removeEventListener(C_EVENT_IDS.C_ON_USER_LOGGED_OUT, this.handleOnUserLoggedOut)
    AppState.removeEventListener(C_EVENT_IDS.C_ON_USER_CHANGE_ROLE, this.handleOnUserChangeRole)
    AppState.removeEventListener(C_EVENT_IDS.C_ON_SET_MODULE_NAME, this.handleOnSetModuleName)

    AppState.removeEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_STARTED, this.handleAjaxStarted)
    AppState.removeEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ENDED, this.handleAjaxEnded)
    AppState.removeEventListener(C_EVENT_IDS.C_ON_AJAX_REQUEST_ERROR, this.handleAjaxError)
  }


  render() {
    const { anchorEl, moduleName, openDrawer, isBusy } = this.state
    const { classes, showSearchButton, isPublic, history, location } = this.props
    const isMenuOpen = Boolean(anchorEl)

    // let { isPublic } = props
    const currentUser = (isPublic === true) ? null : AppState.currentUser
    const selectedRole = (isPublic === true) ? '' : AppState.selectedRole
    let pathName = location ? location.pathname : ''
    if (pathName.indexOf('/eventdetail/') !== -1) {
      pathName = '/eventdetail'
    }
    let showSelectedRoleMenuItem = false
    if (currentUser) {
      const path = location ? location.pathname : ''
      showSelectedRoleMenuItem = (!(selectedRole.toLowerCase() === 'sme' && path === '/admin/sme') &&
        !(selectedRole.toLowerCase() === 'reviewer' && path === '/admin/reviewer') &&
        !(selectedRole.toLowerCase() === 'admin' && path === '/admin/settings'))
    }
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {(currentUser && selectedRole) &&
          [<MenuItem key={1} onClick={this.handleMenuChangeRole('CHANGE')}>Change User Role</MenuItem>,
          <div key={2}>
            {showSelectedRoleMenuItem &&
              <MenuItem onClick={this.handleMenuChangeRole(selectedRole)}>
                <span style={{ textTransform: 'capitalize' }}>{selectedRole}</span>
              </MenuItem>
            }
          </div>]
        }
      </Menu>
    )
    let profileText = []
    if (selectedRole) {
      profileText.push('(' + selectedRole.toUpperCase() + ') ')
    }
    if (currentUser) {
      profileText.push(currentUser.username)
    }

    let breadCrumbItems = []
    let breadCrumbItemDic = {
      HomeLink: (<Link
        key={1}
        color="inherit"
        href="/"
        onClick={this.handleBreadcrumbClick('/')}
        className={classes.bc_link}
        underline="hover">
        <HomeIcon className={classes.bc_icon} />
                    Home
      </Link>),
      HomeText: (<Typography key={1} color="textPrimary" className={classes.bc_text}>
        <HomeIcon className={classes.bc_icon} />
                    Home
      </Typography>),
      EventListLink: (<Link
        key={2}
        color="inherit"
        href="/search"
        onClick={this.handleBreadcrumbClick('/search')}
        className={classes.bc_link}
        underline="hover">
        <EventNoteIcon className={classes.bc_icon} />
                  Event List
      </Link>),
      EventListText: (<Typography key={2} color="textPrimary" className={classes.bc_text}>
        <EventNoteIcon className={classes.bc_icon} />
                  Event List
      </Typography>),
      AboutLink: (<Link
        key={3}
        color="inherit"
        href="/info"
        onClick={this.handleBreadcrumbClick('/info')}
        className={classes.bc_link}
        underline="hover">
        <InformationIcon className={classes.bc_icon} />
                    About
      </Link>),
      AboutText: (<Typography key={3} color="textPrimary" className={classes.bc_text}>
        <InformationIcon className={classes.bc_icon} />
                    About
      </Typography>),
      LoginUserLink: (<div key={4} className={classes.sectionDesktop}>
        <Typography style={{ color: 'inherit' }}>{profileText.join(' ')}</Typography>
        <IconButton
          aria-owns={isMenuOpen ? 'material-appbar' : undefined}
          aria-haspopup="true"
          aria-label='account-role-switch'
          onClick={this.handleProfileMenuOpen}
          color="inherit"
          size="large">
          <AccountCircleIcon />
        </IconButton>
      </div>),
      LoginUserText: (<Typography key={4} color="textPrimary" className={classes.bc_text}>
        {profileText.join(' ')}
        <AccountCircleIcon className={classes.bc_icon} />
      </Typography>),
      ArrowBackLink: (<Link
        key={5}
        color="inherit"
        href="/search"
        onClick={this.handleBreadcrumbClick('/search')}
        className={classes.bc_link}
        underline="hover">
        <ArrowBackIcon className={classes.bc_icon} />
                      Back
      </Link>),
    }
    let showDrawerButton = true
    let showLoggedInUser = true
    switch (pathName) {
      case '/':
        breadCrumbItems.push(breadCrumbItemDic.HomeText)
        breadCrumbItems.push(breadCrumbItemDic.EventListLink)
        breadCrumbItems.push(breadCrumbItemDic.AboutLink)
        break
      case '/search':
        breadCrumbItems.push(breadCrumbItemDic.HomeLink)
        breadCrumbItems.push(breadCrumbItemDic.EventListText)
        breadCrumbItems.push(breadCrumbItemDic.AboutLink)
        break
      case '/info':
        breadCrumbItems.push(breadCrumbItemDic.HomeLink)
        breadCrumbItems.push(breadCrumbItemDic.EventListLink)
        breadCrumbItems.push(breadCrumbItemDic.AboutText)
        break
      case '/info/about':
      case '/info/howto':
      case '/info/latestnews':
        breadCrumbItems.push(breadCrumbItemDic.HomeLink)
        breadCrumbItems.push(breadCrumbItemDic.EventListLink)
        breadCrumbItems.push(breadCrumbItemDic.AboutLink)
        break
      case '/admin':
      case '/admin/sme':
      case '/admin/reviewer':
      case '/admin/settings':
        breadCrumbItems.push(breadCrumbItemDic.HomeLink)
        breadCrumbItems.push(breadCrumbItemDic.EventListLink)
        breadCrumbItems.push(breadCrumbItemDic.AboutLink)
        break
      case '/eventdetail':
        showDrawerButton = false
        showLoggedInUser = false
        breadCrumbItems.push(breadCrumbItemDic.ArrowBackLink)
        break
      default:
        breadCrumbItems.push(breadCrumbItemDic.HomeText)
        breadCrumbItems.push(breadCrumbItemDic.EventListLink)
        breadCrumbItems.push(breadCrumbItemDic.AboutLink)
    }
    if (showLoggedInUser && profileText.length > 0) {
      if (pathName === '/admin') {
        breadCrumbItems.push(breadCrumbItemDic.LoginUserText)
      } else {
        breadCrumbItems.push(breadCrumbItemDic.LoginUserLink)
      }
    }
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} enableColorOnDark>
          <Toolbar>
            <div className={classes.logoAppNamePanel} onClick={this.handleLogoClick}>
              <img className={classes.toolbarLogo} src={NASALogoImage} alt='' />
              <Typography className={classes.title} variant="h5" color="inherit" noWrap>
                Bolides
              </Typography>
            </div>
            <div style={{ userSelect: 'none' }}>
              {moduleName && (
                <Typography variant="h6" color="inherit" noWrap>
                  {moduleName}
                </Typography>
              )}
              &nbsp;
            </div>
            <div className={classes.grow} />

            <Breadcrumbs className={classes.breadCrumb} classes={{ li: classes.bc_item }} aria-label="breadcrumb">
              {breadCrumbItems.map((prop, key) => {
                return (prop)
              })}
            </Breadcrumbs>

            {(showDrawerButton || showSearchButton) &&
              <div style={{ marginLeft: 20 }}>
                <ol className='MuiBreadcrumbs-ol' style={{ display: 'flex', flexDirection: 'row'}}> 
                  <li style={{ display: 'flex', userSelect: 'none', fontSize: '1.1em' }}>/</li>
                  <li style={{ display: 'flex', marginLeft: 8, userSelect: 'none', marginRight: 8, alignItems:'center'}}>
                    <Typography color="textPrimary" className={classes.bc_link} style={{ cursor: 'pointer' }} onClick={this.handleOpenDrawer}>
                      <SearchIcon className={classes.bc_icon} style={{ marginTop: 4 }} />
                      Search
                    </Typography>
                  </li>
                </ol>
                {/* <IconButton
                  color="inherit" onClick={this.handleOpenDrawer}>
                  <MenuIcon />
                </IconButton> */}
              </div>
            }
          </Toolbar>

          {isBusy &&
            <LinearProgress color="secondary" />
          }

          <Drawer
            anchor='right'
            variant="temporary"
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            open={openDrawer}
            onClose={this.closeDrawer}>
            <EventSearchForm history={history} location={location} />
          </Drawer>
        </AppBar>
        {renderMenu}

      </div>
    );
  }
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  showBackButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
}

MainAppBar.defaultProps = {
  showBackButton: false,
  showSearchButton: false,
}
export default withStyles(styles)(MainAppBar)
