import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import moment from 'moment'

import ButtonProgress from '../ButtonProgress/ButtonProgress'
import _Base from '../_Base'
import Util from '../../utils/Util'
import EventFormAttachmentItem from './EventFormAttachmentItem'
import AppState from '../../managers/AppStateManager'
import EventDataAndImages from '../Public/EventDataAndImages'
import EventDataAndCsvs from '../Public/EventDataAndCsvs'

const styles = theme => ({
    root: {
        fontSize: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 0,
        padding: 0,
        marginTop: 10
    },
    titleText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        lineHeight: 1,
        transform: 'scale(0.75)'
    },
})

class EventFormAttachments extends _Base {

    _handleOnClickRegenerateFiles = (e, button) => {
        const {getFormData, onEventStatusRefresh } = this.props
        if (typeof getFormData === 'function') {
            let { eventAttachments, getFormData } = this.props
            let fd = getFormData()
            // clear geoData before sending ... might reach post request size limitations...
            eventAttachments.forEach((a) => {
                const id = a._id ? a._id : a.tempId
                a.geoData = []
                a.mapMetaData = AppState.eventAttachmentMetaDataMap.get(id)
            })

            // const dt = `${fd.eventDate} ${fd.eventTime}`
            const datetime = moment.utc(fd.datetime).valueOf()

            const payload = {
                id: fd.eventId,
                name: fd.eventName,
                description: fd.description,
                datetime: datetime,
                duration: fd.duration,
                latitude: fd.latitude,
                longitude: fd.longitude,
                latitudeDelta: fd.latitudeDelta,
                longitudeDelta: fd.longitudeDelta,
                isManuallyGenerated: fd.isManuallyGenerated,
                eventAttachments: eventAttachments,
            }

            AppState.regenerateEventFiles(payload, (res) => {
                const done = () => {
                    const message = () => {
                        if (res.success) {
                            this.showMessage('Event static files have been successfully regenerated!')
                        } else {
                            this.showMessage(res.error, 'error', true)
                        }
                        button.reset()
                    }
                    if (this.refEventDataAndImages) {
                        this.refEventDataAndImages.refreshImages(message)
                    } else {
                        message()
                    }
                }

                done()
             
                onEventStatusRefresh && onEventStatusRefresh()
                
            })
        }
    }

    setRefEventDataAndImages = (node) => {
        this.refEventDataAndImages = node
    }

    constructor(props) {
        super(props)
        this.state = {
            eventAttachments: props.eventAttachments,
            eventRefreshAttachments: props.eventRefreshAttachments,
            event: props.event,
        }
    } 

    componentDidUpdate(previousProps, previousState) {
        if (!Util.isEqualByValue(previousProps.eventAttachments, this.props.eventAttachments)) {
            this.setState({
                eventAttachments: this.props.eventAttachments,
                eventRefreshAttachments: null
            })
        } else if (!Util.isEqualByValue(this.state.eventAttachments, this.props.eventAttachments)) {
            this.setState({
                eventAttachments: this.props.eventAttachments,
                eventRefreshAttachments: null
            })
        } else if (!Util.isEqualByValue(previousProps.eventRefreshAttachments, this.props.eventRefreshAttachments)) {
            this.setState({
                eventRefreshAttachments: this.props.eventRefreshAttachments
            })
        }
    }

    render() {
        const { classes, showTitle, showDownload, allowRegenerate, isManuallyGenerated, status, ...rest } = this.props
        const { eventAttachments, eventRefreshAttachments, event } = this.state
        let showImages = true;
        if (eventAttachments.length === 0 ) {
            return null
        }
        if (allowRegenerate) {
            if (event.isManuallyGenerated === false && event.status === "INITIAL") {
                showImages = false;
            }
        }
        return (
            <div className={classes.root}>
                {showTitle &&
                    <div className={classes.titleContainer}>
                        <Typography className={classes.titleText}>NetCDF File(s)</Typography>
                    </div>
                }
                {eventAttachments.map((event, id) => (
                    <EventFormAttachmentItem key={id} eventAttachment={event} eventRefreshAttachments={eventRefreshAttachments} attachmentIndex={id} attachmentsCount={eventAttachments.length} getFormData={this.props.getFormData} showDownload={showDownload} isManuallyGenerated={isManuallyGenerated} status={status} />
                ))}
                {allowRegenerate && showImages &&
                    <div style={{ margin: '20px 10px' }}>
                        {isManuallyGenerated === true && <ButtonProgress variant="outlined" onClick={this._handleOnClickRegenerateFiles}>Regenerate Static Files</ButtonProgress>}
                        <div style={{backgroundColor: 'black', borderRadius: 5, marginTop:10, padding: '30px 10px 10px 10px'}}>
                            <Typography style={{color: 'white'}}>Image Files</Typography>
                            <EventDataAndImages setRef={this.setRefEventDataAndImages} event={event} displayMode='plain'  {...rest} />
                            <Typography style={{color: 'white'}}>CSV File(s)</Typography>
                            <EventDataAndCsvs event={event} {...rest} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

EventFormAttachments.propTypes = {
    classes: PropTypes.object.isRequired,
    showTitle: PropTypes.bool,
    showDownload: PropTypes.bool,
    allowRegenerate: PropTypes.bool,
}

EventFormAttachments.defaultProps = {
    showTitle: true,
    showDownload: false,
    allowRegenerate: false,
}

export default withStyles(styles)(EventFormAttachments)
