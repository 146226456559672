import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles';
import Fade from '@mui/material/Fade'
import GridContainer from "../components/Grid/GridContainer.jsx"
import Grid from '@mui/material/Grid'
import classNames from 'classnames'
import moment from 'moment'
import { Link } from 'react-router-dom'
import BaseComponent from '../components/_Base'
import Splash from '../components/Splash/Splash'
import MainAppBar from '../components/Main/MainAppBar'
import EventsWorldMap from '../components/EventsWorldMap/EventsWorldMap'
import EventsChart from '../components/EventsChart/EventsChart'
import EventsFilterControl from '../components/EventsFilterControl/EventsFilterControl'
import withSharedFeatures from './withSharedFeatures'
import AppState from '../managers/AppStateManager'
import Globals from '../variables/globals'
import { C_EVENT_IDS, C_MODULE_NAME } from '../variables/common'

// jss
import { root, container, displayNone } from '../assets/jss/index'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
    color: 'whitesmoke'
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content": {
      flex: 1,
      color: 'whitesmoke',
      "& .content-page": {
        color: 'whitesmoke',
      }
    }
  },
  toolbar: theme.mixins.toolbar,
  gridContainer: {
    flexGrow: 1,
    height: "100%",
    margin: 0
  },
  gridItem: {
    width: '100%',
    padding: '10px 28px'
  },
  displayNone: {
    ...displayNone
  }
})

class Main extends BaseComponent {

  constructor(props) {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }

    this.viewName = 'Main'
    Globals.viewsAnimationTimeoutDict[this.viewName] = Globals.viewsAnimationTimeoutDict[this.viewName] === undefined ? 2000 : 0

    this.state = {
      ...baseState,
      isReady: true, // AppState.publicEventList || AppState.publicEventList.length > 0,
      events: AppState.publicEventList,
      showHideAdvSearch: true,
      poly: [],
    }
    AppState.currentModuleName = C_MODULE_NAME.C_PUBLIC_PAGE
    document.title = props.title;
  }

  toUTCDateInMs = (dt) => moment.utc(dt).valueOf()

  handleOnShowDetail = () => {
    AppState.publicEventList = this.state.events
    const pl = { pathname: '/search', state: { prevPath: this.props.location.pathname } }
    this.props.history.push(pl)
  }

  handleOnSearchResult = (events) => {
    this.setState({
      events: events
    })
  }

  handleOnShowHideAdvSearch = (show) => {
    this.setState({
      showHideAdvSearch: show
    })
  }

  handleOnPolygonChange = (poly) => {
    this.setState({
      poly: poly
    })
  }

  componentWillMount() {
    this.props.changeTheme('baseTheme')
  }

  componentDidMount() {
    AppState.addEventListener(C_EVENT_IDS.C_ON_SEARCH_EVENTS, this.handleOnSearchResult)
  }

  componentWillUnmount() {
    AppState.removeEventListener(C_EVENT_IDS.C_ON_SEARCH_EVENTS, this.handleOnSearchResult)
  }

  render() {
    const { classes, ...rest } = this.props
    const { sv } = this.props.match.params
    const { isReady, events, showHideAdvSearch, poly } = this.state
    const aniTimeout = Globals.viewsAnimationTimeoutDict[this.viewName]

    return (
      <div className={classNames('full-screen', classes.root)}>
        {!isReady && (
          <GridContainer>
            <Splash showLogo={false} />
          </GridContainer>
        )}
        {isReady && (
          <div className={classes.container} style={{ overflow: 'auto' }}>
            <div className='app-bar'>
              <MainAppBar module={sv} {...rest} />
            </div>
            <div className={classes.toolbar}></div>
            <Fade in={isReady} timeout={aniTimeout}>
              <div className='content'>
                <GridContainer style={{ height: '100%' }} justify="flex-start" wrap="wrap">
                  <Grid item style={{ flex: 'none' }} classes={{ item: classes.gridItem }}>
                    <div className="content-page" style={{ padding: 0, color:'smokewhite' }}>
                      <h1>Bolide Detections from Geostationary Lightning Mapper</h1>
                      <p style={{color: 'rgba(255,255,255,0.75)'}}>
                        The Geostationary Lightning Mapper (GLM) aboard the GOES East and GOES West satellites, is designed to capture natural lightning activity, but it is also capable of detecting bright meteors, called bolides. GLM's large coverage area allows it to capture unprecedented numbers of meteors and its data is publicly accessible.   
                        More background about this data, hints on how to use this website, and the latest news and updates can all be found <Link style={{color: 'white', fontWeight: 'bold'}} to="/info">here</Link>. 
                      </p>
                    </div>
                  </Grid>
                  <Grid item style={{ flex: 3, margin: 0, padding: '0 28px' }} classes={{ item: classes.gridItem }}>
                    <Grid container direction="row" style={{ height: '100%', backgroundColor: '#000' }}>
                      <Grid item style={{ flex: 3, backgroundColor: '#212121', minWidth: 350 }}>
                        <EventsWorldMap enableInteraction showHideAdvSearch={showHideAdvSearch} events={events} poly={poly} {...rest} />
                      </Grid>
                      <Grid item style={{ flex: 2, backgroundColor: '#121212', border: '1px solid #454545', margin: '0 0 10px 10px', borderRadius: 5, minWidth: 350 }}>
                        <EventsChart events={events} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ flex: 1, margin: '0 0 10px 0', padding: '0 28px' }} classes={{ item: classes.gridItem }}>
                    <Grid container direction="row" style={{ backgroundColor: '#232323' }}>
                      <Grid item style={{ flex: 1 }}>
                        <EventsFilterControl events={events} onShowDetail={this.handleOnShowDetail} onSearchResult={this.handleOnSearchResult} onShowHideAdvSearch={this.handleOnShowHideAdvSearch} onPolygonChange={this.handleOnPolygonChange} />
                      </Grid>
                    </Grid>
                  </Grid> 
                  {/* <Grid item style={{flex: 'none'}} classes={{item: classes.gridItem}}>
                    <div className="content-page1" style={{ padding: 0, fontSize: '0.8em' }}>
                      <p><span style={{ fontSize: 20, letterSpacing: '-0.008em' }}>References</span></p>
                      <p><span style={{ letterSpacing: 0 }}><span className="sb_contribution" style={{ color: 'rgb(0,0,0)', textDecoration: 'none' }}><span className="sb_authors aug" style={{ color: 'rgb(34,34,34)' }}><span className="sb_author au"><span className="ce_surname snm">GOES-R Series Product Definition and User's Guide (PUG),&nbsp;<a target="_blank" className="external-link" href="https://www.goes-r.gov/products/docs/PUG-L2+-vol5.pdf" style={{ textDecoration: 'none' }} rel="noopener noreferrer nofollow">volume 5</a></span></span></span></span></span></p>
                      <p><span style={{ letterSpacing: 0 }}><span className="sb_contribution" style={{ color: 'rgb(0,0,0)', textDecoration: 'none' }}><span className="sb_authors aug" style={{ color: 'rgb(34,34,34)' }}><span className="sb_author au"><span className="ce_surname snm"><a target="_blank" href="https://www.goes-r.gov/downloads/resources/documents/GOES-RSeriesDataBook.pdf" className="external-link" rel="noopener noreferrer nofollow">GOES-R Series Data Book</a></span></span><span className="x" style={{ color: 'black' }}>,&nbsp;</span></span><span className="sb_title atl"><span className="sb_maintitle">CDRL PM-14 Rev A. May 2019. NOAA-NASA</span><span className="x" style={{ color: 'black' }}>,&nbsp;</span></span></span><span className="sb_comment" style={{ color: 'rgb(34,34,34)', textDecoration: 'none' }}>Available at</span><span className="sb_host" style={{ color: 'rgb(0,0,0)', textDecoration: 'none' }}><span className="x" style={{ color: 'black' }}>.&nbsp;</span><span className="sb_e-host">https://www.goes-r.gov/downloads/resources/documents/GOES-RSeriesDataBook.pdf.</span></span></span></p>
                      <p className="p1"><a target="_blank" href="https://doi.org/10.1016/j.atmosres.2013.01.006" className="external-link" rel="noopener noreferrer nofollow">Goodman et al. 2013</a>; "The GOES-R Geostationary Lightning Mapper (GLM)"&nbsp; Atmospheric Research 125/126:34–49</p>
                      <p><a target="_blank" href="http://doi.org/10.1111/maps.13137" className="external-link" rel="noopener noreferrer nofollow">Jenniskens et al, 2018</a>; "Detection of meteoroid impacts by the Geostationary Lightning Mapper on the GOES-16 satellite", <em>Meteoritics &amp; Planetary Science</em>,&nbsp; 53(12), 2445</p>
                      <p><a target="_blank" href="https://doi.org/10.3390/s19051008" className="external-link" rel="noopener noreferrer nofollow">Rumpf et al. 2019</a>; “An Algorithmic Approach for Detecting Bolides with the Geostationary Lightning Mapper”, <em>Sensors,</em>&nbsp;<em>19</em>(5), 1008</p>
                    </div>
                  </Grid> */}
                </GridContainer>
              </div>
            </Fade>
          </div>
        )}
      </div>
    )
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(Main))
