import React, { useRef, useEffect, useReducer } from "react"
// @mui/icons-material components
import { makeStyles } from '@mui/styles'
// import MaterialTable from 'material-table'
import MaterialTable from "material-table";
import CloudIcon from '@mui/icons-material/CloudDownloadOutlined'
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined'

import moment from 'moment'
import { C_DEFAULTS } from '../../variables/common'
import Util from '../../utils/Util'
import EventAttachmentsDownload from '../../components/EventAttachmentsDownload/EventAttachmentsDownload'
import AppState from '../../managers/AppStateManager'
// jss
import { root } from '../../assets/jss/index'
import { Typography } from "@mui/material";

const useStyles = makeStyles(theme => ({
    root: {
        ...root,
        margin: 0,
        padding: 0,
        backgroundColor: 'red',
        maxWidth: '100%',
        height: 300,
    },
    container: {
        '& .MuiTablePagination-toolbar': {
            backgroundColor: 'whitesmoke'
        },
        '& .contact': {
            height: 35,
            whiteSpace: 'nowrap',
            marginLeft: 15,
            display: 'flex',
            alignItems: 'center',
            '& .envelope': {
                fontSize: '2em'
            }
        },
        '& .MuiTableRow-hover:hover': {
            backgroundColor: 'whitesmoke !important'
        },
        '& .MuiTableCell-root': {
            padding: '8px 16px',
        },
        '& .MuiTableCell-root.MuiTableCell-footer': {
            padding: 0,
        },
    },
    tableActionButtonRoot: {
        borderRadius: 0,
    }
}))

function EventsTable({ events, event, onSelectedEvent, history }) {
    const classes = useStyles()
    const rootRef = useRef()
    const containerRef = useRef()
    const tableRef = useRef()
    // const dimensions = useResizeObserver(rootRef)
 
    const pageSizeOptions = [5, 10, 25] // 50] //, 100]
     
    // figure out page size based on the number of events....
    const defaultPageSize = () => {
        let ps = 25
        let start = 0
        let end = 0
        const noOfEvents = events ? events.length : 0
        for (let i = 0; i < pageSizeOptions.length; i++) {
            if (i === 0) {
                start = 0
                end = pageSizeOptions[i]
            } else {
                start = pageSizeOptions[i-1] + 1
                end = (i === pageSizeOptions.length-1) ? noOfEvents : pageSizeOptions[i]
            }
            //console.log('DEBUG: defaultPageSize - start, end, noOfEvents', start,end, noOfEvents)
            if (noOfEvents >= start && noOfEvents <= end) {
                ps = pageSizeOptions[i]
                //console.log('DEBUG: defaultPageSize found,', start, ps, end, noOfEvents)
             }
        }
        return ps
    }

    const pSize = defaultPageSize()
    //console.log('DEBUG: defaultPageSize', pSize, events.length)
    if (AppState.eventsTableState) {
        if (pSize !== AppState.eventsTableState.pageSize) {
            AppState.eventsTableState.pageSize = pSize
        }      
    }

    // simulating Class component's setState via useReducer
    var initialState = AppState.eventsTableState || {
        selectedRow: null,
        anchorEl: null,
        pageSize: pSize,
        pageNo: 0,
    }

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        initialState
    )

    const addContactUs = () => {
        const footerToolbar = containerRef.current.querySelector('.MuiTablePagination-toolbar')
        const contact = footerToolbar.querySelector('.contact')
        if (!contact) {
            const spacer = footerToolbar.querySelector('.MuiTablePagination-spacer')
            const html = `<div class="contact">
                            Contact&nbsp;us&nbsp;at&nbsp;<a href="mailto:ARC-bolide-data@mail.nasa.gov" rel="nofollow">ARC-bolide-data@mail.nasa.gov</a>&nbsp;&nbsp;&nbsp;
                            <span class='envelope'>&#9993;</span>
                         </div>`
            spacer.insertAdjacentHTML('beforebegin', html)
        }
    }

    const autoResizeTable = () => {
        let _boundingRect = rootRef.current.parentElement.getBoundingClientRect()
        let ws = Util.getWindowSize()
        let newHeight = ws.height - _boundingRect.top
        if (newHeight < 200) newHeight = 200
        rootRef.current.style.height = newHeight + 'px'
        containerRef.current.style.height = newHeight + 'px'
        tableRef.current.tableContainerDiv.current.style.overflowY = 'visible'
        tableRef.current.tableContainerDiv.current.style.height = (newHeight - 53) + 'px' // 53 table footer's height
    }

    useEffect(() => {
        addContactUs()
        autoResizeTable()

        window.addEventListener('resize', autoResizeTable)
        return () => {
            window.removeEventListener('resize', autoResizeTable)
        }
    }, [])

    useEffect(() => {
        //console.log('DEBUG: defaultPageSize - state change', state)
        AppState.eventsTableState = {...state}
    }, [state])

    const handleOnRowClick = (selectedRow) => {
        // console.log('DEBUG: EventsTable - handleOnRowClick', selectedRow)
        if (selectedRow && selectedRow._id) {
            AppState.currentEventId = selectedRow._id
            onSelectedEvent && onSelectedEvent(selectedRow)
        }
    }

    const handleOnClickDownloadCSV = (event, rowData) => {
        const selectedEvent = rowData // events.find( e => e._id === rowData._id)
        //console.log('DEBUG: EventsTable - handleOnClickDownloadCSV', selectedEvent)
        AppState.currentEventId = rowData._id
        setState({ selectedRow: selectedEvent, anchorEl: event.currentTarget })
    }

    return (
        <div ref={rootRef} className={classes.root}>
            <div ref={containerRef} className={classes.container}>
                <MaterialTable
                    style={{ borderRadius: 0, transition: 'none' }}
                    tableRef={tableRef}
                    columns={[
                        {
                            field: 'datetime',
                            title: 'Date & Time',
                            type: 'datetime',
                            render: rowData => <Typography style={{ whiteSpace: 'nowrap', fontSize: '0.875rem' }}>{moment.utc(rowData.datetime).format(C_DEFAULTS.C_DATE_FORMAT_STRING)}</Typography>,
                        },
                        {   field: 'latitude', 
                            title: 'Latitude',
                            render: rowData => <Typography style={{ whiteSpace: 'nowrap', fontSize: '0.875rem' }}>{(rowData.latitude.toFixed(1))}</Typography>,
                         },
                        {   field: 'longitude', 
                            title: 'Longitude',
                            render: rowData => <Typography style={{ whiteSpace: 'nowrap', fontSize: '0.875rem' }}>{(rowData.longitude.toFixed(1))}</Typography>,
                        },
                        { field: 'platform', title: 'Platform' },
                        { field: 'name', title: 'Info' },
                    ]}
                    data={events}

                    onRowClick={((event, selectedRow) => {
                            setState({ selectedRow: selectedRow })
                            handleOnRowClick(selectedRow)
                    })}

                    onPageChange={(pageNo) => {
                        setState({pageNo: pageNo})
                    }}

                    onRowsPerPageChange={(pageSize) => {
                        //console.log('DEBUG: EventsTable', pageSize)
                        setState({pageSize: pageSize})
                    }}

                    actions={[
                        {
                            icon: () => (<CloudIcon />),
                            tooltip: 'Download CSV',
                            onClick: handleOnClickDownloadCSV
                        },
                        {
                            icon: () => (<EyeIcon />),
                            tooltip: 'Show Details',
                            onClick: (event, rowData) => {
                                setState({ selectedRow: rowData })
                                setTimeout(() => {
                                    history.push('/eventdetail/' + rowData._id)
                                }, 100)
                            }
                        },
                    ]}
                    options={{
                        toolbar: false,
                        headerStyle: {
                            backgroundColor: 'whitesmoke',
                        },
                        actionsCellStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                        },
                        actionsColumnIndex: -1,
                        detailPanelColumnAlignment: 'right',
                        initialPage: state.pageNo,
                        pageSize: state.pageSize,
                        pageSizeOptions: pageSizeOptions,
                        rowStyle: rowData => {
                            return {
                                backgroundColor: ((state.selectedRow && state.selectedRow._id === rowData._id) || 
                                                  (AppState.currentEventId && AppState.currentEventId === rowData._id)) ? '#EEE' : '#FFF'
                            }
                        }
                    }}
                />
                {state.selectedRow &&
                    <EventAttachmentsDownload 
                        attachments={state.selectedRow.attachments}
                        open={Boolean(state.anchorEl)}
                        anchorEl={state.anchorEl}
                        onClose={() => setState({ anchorEl: null })} 
                    />}
            </div>
            

        </div>
    )
}

export default EventsTable
