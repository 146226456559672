// @mui/icons-material
import Person from "@mui/icons-material/Person"
import LibraryBooks from "@mui/icons-material/LibraryBooks"
import BubbleChart from "@mui/icons-material/BubbleChart"

// core components/views
import MainView from '../views/Main'
import UserRole from '../views/UserRole'
// import PublicView from '../views/Public'
import SearchView from '../views/Search'
import EventDetailView from '../views/EventDetail'
import SmeView from '../views/Sme'
import ReviewerView from '../views/Reviewer'
import NcdfViewerView from '../views/NCDFViewer'
import HelpView from '../views/Help'
import SettingsView from '../views/Settings'
import InfoView from '../views/Info'
import AboutView from '../views/About'
import HowToView from '../views/HowTo'
import LatestNewsView from '../views/LatestNews'
import Whoops404View from '../views/Whoops404'
import ForbiddenView from '../views/Forbidden'
import SessionTimeoutView from '../views/SessionTimeout'
 
const mainRoutes = [
  {
    path: "/search",
    sidebarName: "Search",
    navbarName: "Search",
    icon: Person,
    component: SearchView,
    title: "Search"
  },
  {
    path: "/admin/sme",
    sidebarName: "SME",
    navbarName: "SME",
    icon: Person,
    component: SmeView,
    title: "Sme"
  },
  {
    path: "/admin/reviewer",
    sidebarName: "Reviewer",
    navbarName: "Reviewer",
    icon: Person,
    component: ReviewerView,
    title: "Reviewer"
  },
  {
    path: "/admin/settings",
    sidebarName: "Settings",
    navbarName: "Settings",
    icon: Person,
    component: SettingsView,
    title: "Admin Settings"
  },
  {
    path: "/admin",
    sidebarName: "User Role",
    navbarName: "User Role",
    icon: Person,
    component: UserRole,
    title: "Admin"
  },
  {
    path: "/eventdetail/:eventId",
    sidebarName: "Event Detail",
    navbarName: "Event Detail",
    icon: Person,
    component: EventDetailView,
    title: "Event Detail"
  },
  {
    path: "/ncdfviewer",
    sidebarName: "NetCDF Viewer",
    navbarName: "NetCDF Viewer",
    icon: Person,
    component: NcdfViewerView,
    title: "NCDF Viewer"
  },
  {
    path: "/help",
    sidebarName: "Help",
    navbarName: "Help",
    icon: "content_paste",
    component: HelpView,
    title: "Help"
  },
  {
    path: "/info",
    sidebarName: "Info",
    navbarName: "Info",
    icon: LibraryBooks,
    component: InfoView,
    title: "Info"
  },
  {
    path: "/info/about",
    sidebarName: "About",
    navbarName: "About",
    icon: LibraryBooks,
    component: AboutView,
    title: "About"
  },
  {
    path: "/info/howto",
    sidebarName: "HowTo",
    navbarName: "HowTo",
    icon: LibraryBooks,
    component: HowToView,
    title: "How To"
  },
  {
    path: "/info/latestnews",
    sidebarName: "Latest News",
    navbarName: "Latest News",
    icon: LibraryBooks,
    component: LatestNewsView,
    title: "Latest"
  },
  {
    path: "/settings",
    sidebarName: "Settings",
    navbarName: "Settings",
    icon: BubbleChart,
    component: SettingsView,
    title: "Settings"
  },
  {
    path: "/forbidden",
    sidebarName: "Forbidden",
    navbarName: "Forbidden",
    icon: BubbleChart,
    component: ForbiddenView,
    title: "Forbidden"
  },
  {
    path: "/sessiontimeout",
    sidebarName: "Session",
    navbarName: "Session",
    icon: BubbleChart,
    component: SessionTimeoutView,
    title: "Session"
  },
  {
    path: "/",
    sidebarName: "Main",
    navbarName: "Main",
    icon: Person,
    component: MainView,
    title: "Bolide Home"
  },
  { redirect: true, path: "*", to: "/", navbarName: "Redirect" },
  {
    path: "*",
    sidebarName: "Resource not found!",
    navbarName: "Resource not found!",
    icon: BubbleChart,
    component: Whoops404View,
    title: "Not Found"
  },
]

export default mainRoutes
