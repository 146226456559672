import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Fade from '@mui/material/Fade'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import MainAppBar from '../components/Main/MainAppBar'
import withSharedFeatures from './withSharedFeatures'
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos'
import Typography from '@mui/material/Typography'

// jss
import { root, container } from '../assets/jss/index'
import Globals from '../variables/globals'

const styles = (theme) => ({
  root: {
    ...root,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    color: 'black',
  },
  container: {
    ...container,
    margin: 0,
    paddingRight: 0,
    paddingLeft: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    "& div.app-bar": {
      flex: 'none',
    },
    "& div.content-page": {
      flex: 1,
      overflowY: 'auto',
      padding: '40px 40px',
      '& div.table-wrap': {
        background: 'none'
      },
      '& a': {
        color: 'blue',
      },
    }
  },
  gridContainer: {
    flexGrow: 1,
    height: "100%"
  },
  title: {
    fontSize: 24,
    fontWeight: 'normal',
    lineHeight: 1.25,
  },
  toolbar: theme.mixins.toolbar,
  bc_link: {
    width: 100,
    fontSize: '1rem',
    marginBottom:10,
    display: 'flex',
    color: '#rgba(0,0,0,0.6)',
    '&:hover': {
      color: 'black'
    }
  },
  bc_icon: {
    marginRight: theme.spacing(0.3),
    width: 20,
    height: 20,
  },
})

const LatestNews = (props) => {
  const { classes, ...rest } = props
  const viewName = 'LatestNews'
  Globals.viewsAnimationTimeoutDict[viewName] = Globals.viewsAnimationTimeoutDict[viewName] === undefined ? 2000 : 0
  const aniTimeout = Globals.viewsAnimationTimeoutDict[viewName]

  return (
    <div className={classNames('full-screen', classes.root)}>
      <div className={classes.container}>
        <div className='app-bar'>
          <MainAppBar showBackButton={true} {...rest} />
        </div>
        <div className={classes.toolbar}></div>
        <Fade in={true} timeout={aniTimeout}>
          <div className='content-page' style={{ padding: '10px 28px' }}>

            <Link to="/info" className={classes.bc_link}>
              <ArrowBackIcon className={classes.bc_icon} />
               Back
            </Link>

            <h1>News and Updates</h1>

            <h2>
              May 11, 2020
            </h2>

            <Typography className={classes.cardContentText} variant="body2" component="p">
              We've released a new version of <a href="https://neo-bolide.ndc.nasa.gov" target="_blank" rel="noopener noreferrer">neo-bolide.ndc.nasa.gov</a>. Changes in this version include:
             </Typography>
            <ul>
              <li>New maps and charts to examine the data set</li>
              <li>Advanced search capabilities</li>
              <li>A bug fix to correct the satellite location specified in the downloadable files for stereo events.</li>
            </ul>

            <p><br /></p>
            <p><br /></p>
            <p><br /></p>

          </div>
        </Fade>
      </div>

    </div>
  )
}

LatestNews.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(LatestNews))
