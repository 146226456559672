// Custom Highcharts wrapper that wraps HC 'transformToLatLon' function, which was causing the onHover error
// Ref: https://www.highcharts.com/forum/viewtopic.php?t=48802
// Ref2: https://stackblitz.com/edit/highcharts-angular-map-with-proj4-djuxcv?file=src%2Fapp%2FcustomWrap.ts

export default function (Highcharts) {
    const H = Highcharts;
    H.wrap(
        H.Chart.prototype,
        'transformToLatLon',
        function (proceed, point, transform) {
            if (!isNaN(point.x) && !isNaN(point.y)) {
                return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
            }
        }
    )
}