/**
 * Re-navigates GLM event geodetic coordinates from the original lightning
 * ellipsoid defined by e1 and p1 to the new lightning ellispoid defined by
 * e2 and p2.  This re-navigation calculation uses a ray-ellipsoid
 * intersection calculation in ECEF (Earth-Centered, Earth-Fixed)
 * coordinates, similar to the calculation made in the original navigation
 * of GLM events. As of September 2018, e1 = 14 km and p1 = 6 km.
 * this code was given to NASA by Lockheed Martin (Clem Tillier)
 *
 * @param {*} rlon Original navigated event longitude (deg)
 * @param {*} rlat Original navigated event latitude (deg)
 * @param {*} e1
 * Lightning ellipsoid altitude, equatorial, to which lat1 an lon1
 * were originally navigated. Specified as km altitude above Earth
 * ellipsoid equatorial radius. Original GLM value was 16 km.
 * @param {*} p1
 * Lightning ellipsoid altitude, polar, to which lat1 and lon1 were
 * originally navigated. Specified as km altitude above Earth
 * ellipsoid polar radius. Original GLM value was 6 km.
 * @param {*} e2 New lightning ellipsoid altitude, equatorial.
 * @param {*} p2 New lightning ellipsoid altitude, polar
 *
 * @returns An object containing calculated x, y, z coords
 */

import { getEnergies, getPlatformKey } from "../utils/Attachments";


function _renavigate(sat_lon, rlon, rlat, e1, p1, e2, p2) {
    const sat_radius = 42164.0;
    const eer = 6378.137;
    const eff = 3.35281e-3;
    const pi = Math.PI;
    const dtr = pi / 180.0;

    // Input sanity checks
    if (e1 < p1) {
        // warning polar 1 altitude greater than equatorial 1 altitude
        console.warn("_renavigate: polar 1 altitude greater than equatorial 1 altitude. exiting.");
        return;
    }

    if (e2 < p2) {
        // warning polar 2 altitude greater than equatorial 2 altitude
        console.warn("_renavigate: polar 2 altitude greater than equatorial 2 altitude. exiting.");
        return;
    }

    /**
     * Step 2: convert input longitude and latitude to ECEF (Earth centered,
     *   Earth fixed) vectors.
     *  ECEF x passes through prime meridian at equator (lat = lon = 0).
     *  ECEF y passes through equator at 90 deg east.
     *  ECEF z passes through the north pole.
     *  calculate original lightning ellipsoid equatorial and polar radii
     *  compute the original lightning ellipsoid's flattening factor
     *  convert to geocentric latitude from geodetic latitude
     */
    const re1 = eer + e1;
    const rp1 = (1.0 - eff) * eer + p1;
    const ff1 = (re1 - rp1) / re1;
    const rlatprime = Math.atan((1.0 - ff1) ** 2 * Math.tan(dtr * rlat));

    // compute the x,y,z ECEF coordinates on the original lightning ellipsoid

    const rmag = (re1 * (1.0 - ff1)) / Math.sqrt(1.0 - ff1 * (2.0 - ff1) * Math.cos(rlatprime) ** 2);

    // ellipsoid radius

    const x1 = rmag * Math.cos(rlatprime) * Math.cos(dtr * rlon);
    const y1 = rmag * Math.cos(rlatprime) * Math.sin(dtr * rlon);
    const z1 = rmag * Math.sin(rlatprime);

    //
    // Step 3: compute pointing vector d from satellite (independent of
    // ellipsoid).  This is simply the vector from the center of the Earth to
    // the lightning event, minus the vector from the center of the Earth to the
    // satellite-- and yields the vector from the satellite to the lightning
    // event.  This line of sight vector will later be intersected with the new
    // ellipsoid.
    //
    const Rx = sat_radius * Math.cos(dtr * sat_lon);
    const Ry = sat_radius * Math.sin(dtr * sat_lon);
    const Rz = 0.0;

    let dx = x1 - Rx;
    let dy = y1 - Ry;
    let dz = z1 - Rz;

    const unorm = Math.sqrt(dx * dx + dy * dy + dz * dz);

    dx = dx / unorm;
    dy = dy / unorm; 
    dz = dz / unorm;

    //
    // Step 4: compute the intersection of a ray parallel to (dx,dy,dz) and the
    // new ellipsoid.  This calculation is performed according to CDRL046
    // navigation design document, appendix J, using the quadratic formulation
    // of equations J-6 through J-20.
    // new ellipsoid parameters
    //
    const re2 = eer + e2;
    const rp2 = (1.0 - eff) * eer + p2;
    const ff2 = (re2 - rp2) / re2;

    const Q1 = dx * dx + dy * dy + (dz * dz) / (1.0 - ff2) ** 2;
    const Q2 = 2.0 * (Rx * dx + Ry * dy);

    //
    // Rz is zero, neglect + Rz*dz./((1-Fle)^2));
    //
    const Q3 = Rx * Rx + Ry * Ry - re2 * re2;

    //
    // Rz is zero, neglect + Rz*Rz./((1-Fle)^2)
    //
    const Q4 = Q2 * Q2 - 4.0 * Q1 * Q3;

    //
    // quadratic equation determinant (Equation J-15)
    // any members of Q4 that are negative cannot be navigated to (do not
    // intersect with) the new ellipsoid.  Set those to NaN, which will properly
    // propagate to the output where unnavigable lat2/lon2 values will be NaN.
    //
    if (Q4 < 0) {
        console.warn("unnavigable");
    }

    const D = (-Q2 - Math.sqrt(Q4)) / (2.0 * Q1);

    const x2 = Rx + D * dx;
    const y2 = Ry + D * dy;
    const z2 = Rz + D * dz;

    return { x: x2, y: y2, z: z2 };
}

/**
 * Calculate the distance value needed to determine categorical
 * "Ground Track" value for this event (Single-Pixel or Multi-Pixel)
 * @param {*} coordinates A list of latitude and longitude values for the event
 * @param {*} platformKey
 * A string representing the satellite which detected this event
 * ex: "GLM-16" or "GLM-17"
 * @returns The distance value in km
 */
function _getDistanceTracked(coordinates, platformKey) {
    const lat_i = coordinates[0].latitude;
    const lon_i = coordinates[0].longitude;
    const lat_f = coordinates[coordinates.length - 1].latitude;
    const lon_f = coordinates[coordinates.length - 1].longitude;

    /* Constants */
    const e1 = 14.0;
    const p1 = 6.0;
    const e2 = 50.0;
    const p2 = 50.0;

    const sat_lon = getSatelliteLongitude(platformKey);

    const { x: x1, y: y1, z: z1 } = _renavigate(sat_lon, lon_i, lat_i, e1, p1, e2, p2);
    const { x: x2, y: y2, z: z2 } = _renavigate(sat_lon, lon_f, lat_f, e1, p1, e2, p2);

    const dist = Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2 + (z1 - z2) ** 2);

    return dist;
}

/**
 * Categories for Ground Track and their
 * corresponding max distance values
 */
export const GROUND_TRACK_CATEGORIES = {
    "Single-Pixel": {
        max: 8.0,
    },
    "Multi-Pixel": {
        max: Number.POSITIVE_INFINITY,
    },
};

function getSatelliteLongitude(platformKey) {
    let sat_lon;

    switch (platformKey) {
        case "GLM-16":
            sat_lon = -75.0;
            break;
        case "GLM-17":
            sat_lon = -137.0;
            break;
        case "GLM-18":
            sat_lon = -137.0;
            break;
    }
    return sat_lon;
}

/**
 * Generate the "Ground Track" object for the given event
 * @param {*} attachments The hdf5 data uploaded for this particular event
 * @returns
 * A "Ground Track" object containing the category (Single-Pixel or Multi-Pixel)
 * and the distance value used to determine that category for each detecting satellite
 * i.e: { "GLM-16": { "category" : "Single-Pixel", "value" : 7.23454 } }
 */
export function generateGroundTrack(attachments) {
    const LATITUDE = 1;
    const LONGITUDE = 0;
    let groundTrack = {};
    attachments.forEach((a) => {
        const platformKey = getPlatformKey(a);
        groundTrack[platformKey] = {};

        // Extract the lat/long pairs into a list
        const coordinates = a.geoData.map((geo) => {
            return {
                latitude: geo.location.coordinates[LATITUDE],
                longitude: geo.location.coordinates[LONGITUDE],
            };
        });

        const distance = _getDistanceTracked(coordinates, platformKey);

        // Filter all ground track levels (single-pixel, multi-pixel)
        // which are greater than the calculated distance, and take the lowest one
        groundTrack[platformKey]["category"] = Object.keys(GROUND_TRACK_CATEGORIES)
            .filter((level) => distance < GROUND_TRACK_CATEGORIES[level].max)
            .shift();
        groundTrack[platformKey]["value"] = distance;
    });

    return groundTrack;
}
