import React from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

import classNames from 'classnames'

const styles = theme => ({
    root: {
        fontSize: '100%',
        width: '100%',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    cardPlain: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        boxShadow: 'none',
        border: 'none',
        borderRadius: 'unset',
    },
    cardHeader: {
        flex: 'none',
        padding: 0,
        backgroundColor: 'whitesmoke',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    },
    cardHeaderTitle: {
        fontSize: '100%',
        textAlign: 'center',
    },
    cardContent: {
        flex: 1,
        overflowY: 'auto',
        padding: 5,
    },
})


const GroupBox = (props) => {
    let { classes, groupTitle, children, boxType, cardHeader, cardHeaderTitle, cardContent, className, withHeader, flexible, ...rest } = props
    let card = boxType === 'plain' ? classes.cardPlain : classes.card
    return (
        <Card className={classNames(classes.root, card, className)} {...rest}>
            {withHeader &&
            <CardHeader className={classNames(classes.cardHeader, cardHeader)} classes={{ title: classNames(classes.cardHeaderTitle, cardHeaderTitle) }} title={groupTitle} />
            }
            <CardContent className={classNames(classes.cardContent, cardContent)}>
                {children}
            </CardContent>
        </Card>
    )
}

GroupBox.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    withHeader: PropTypes.bool,
}

GroupBox.defaultProps = {
    withHeader: true
}

export default withStyles(styles)(GroupBox)
