import React from 'react'

// @mui/material components
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import { darken, lighten } from '@mui/material/styles'
import classNames from 'classnames'
import BaseComponent from '../../components/_Base'
import Splash from '../../components/Splash/Splash'
import GroupBox from '../../components/GroupBox/GroupBox'
import withSharedFeatures from '../../views/withSharedFeatures'
import AppState from '../../managers/AppStateManager'
import UserFormEntry from './UserFormEntry'

// jss
import { root, container } from '../../assets/jss/index'
import { Typography } from '@mui/material';

import {
  C_MODE,
} from '../../variables/common'


const styles = (theme) => ({
  root: {
    ...root,
    fontSize: '0.85rem',
    width: 960,
  },
  container: {
    ...container,
    display: 'flex',
    flexDirection: 'column',
  },
  titleText: {
    fontSize: '1rem',
    marginBottom: 15,
    marginTop: 25,
  },
  flexItemRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid ' + darken('#f8f8ff', 0.065),
    padding: 15,
},
  group: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1),
    width: 100,
  },
  groupBox: {
    height: 'auto',
    padding: 0,
  },
  table: {
    '& .table-row': {
      width: '100%'
    },
    '& .table-row:hover': {
      backgroundColor: lighten('#f8f8ff', 0.075),
    }
  },
  cardContent: {
    padding: 0,
    "&:last-child": {
      padding: 0,
    }
  },
  colHeader: {
    fontSize: '0.9em',
    fontWeight: 'bold',
    color: 'gray',
  },
  userNameCol: {
    flex: 'none',
    width: 80,
  },
  firstNameCol: {
    flex: 'none',
    width: 110,
    textTransform: 'capitalize',
  },
  lastNameCol: {
    flex: 'none',
    width: 110,
    textTransform: 'capitalize',
  },
  emailCol: {
    flex: '1 1 auto',
    maxWidth: 250,
  },
  rolesCol: {
    flex: 'none',
    width: 170,
  },
  actionButtonsCol: {
    flex: 'none',
    width: 165,
    display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 19,
  }
})

class UserList extends BaseComponent {

  constructor(props)
  {
    super(props)
    // pattern of extending state from the base class
    const baseState = { ...this.state }
    // extend base state....
    this.state = {
      ...baseState,
      selectedId: null,
      users: [],
      editMode: C_MODE.C_VIEW_MODE,
    }
  }

 _handleOnClickEdit = id => e => {
    this.setState({
      editMode: C_MODE.C_EDIT_MODE,
      selectedId: id
    })
  }

  onCloseConfirmation = (cmd) => {
    const { selectedId } = this.state
    if (cmd === 'ok') {
      AppState.deleteUser(selectedId, (res) => {
        if (!res.success) {
          this.showMessage(res.error, 'error', true)
          return
        }
        // refresh list...
        AppState.getUsers(false, (err, data) => {
          if (!err) {
            this.setState({
              users: data,
              selectedId: null,
              editMode: C_MODE.C_VIEW_MODE
            }, () => {
              this.showMessage('User has been successfully deleted', 'info', true)
            })
          }
        })
      })
    }
  }

  _handleOnClickRemove = id => e => {
    const { showConfirmation } = this.props
    const { users } = this.state
    const user = users.find( u => u._id === id)
    const dialogOptions = {
      titleNode: <Typography style={{ color: 'white' }}>Remove User "{user.username}"</Typography>,
      contentNode: <div>Are you sure?</div>,
      okLabel: 'Yes',
      cancelLabel: 'No',
      onClose: this.onCloseConfirmation.bind(this)
    }
    // this.showMessage('_handleOnClickRemove - ' + id, 'info', true)
    this.setState({
      editMode: C_MODE.C_DELETE_MODE,
      selectedId: id
    }, () => {
      showConfirmation(dialogOptions)
    })
  }

  _handleOnClickAdd = () => {
    this.setState({
      editMode: C_MODE.C_ADD_MODE,
    })
  }

  _handleOnSaveEntry = (user) => {
    const { editMode, users } = this.state

    if (editMode === C_MODE.C_ADD_MODE) {
      users.push(user)
    } else {
      const idx = users.findIndex( u => u._id === user._id)
      if (idx !== -1) {
        users.splice(idx, 1, user)
      }
    }

    this.setState({
      users: users,
      selectedId: null,
      editMode: C_MODE.C_VIEW_MODE,
    }, () => {
      // this.showMessage('Saved....', user)
      this.showMessage('User has been successfully saved', 'info', true)
    })

  }

  _handleOnCancelEntry = () => {
    this.setState({
      editMode: C_MODE.C_VIEW_MODE,
      selectedId: null,
    })
  }

  componentDidMount() {
    AppState.getUsers(false, (err, data) => {
      if (err) {
        console.log('DEBUG:error', err)
      } else {
        this.setState({
          isReady: true,
          users: data,
        })
      }
    })
  }

  render() {
    const { classes, ...rest } = this.props
    const { isReady, users, selectedId, editMode } = this.state
    const isRowEdit = (id) => {
      return editMode === C_MODE.C_EDIT_MODE && id === selectedId
    }
    const isRowAdd = editMode === C_MODE.C_ADD_MODE
    let isEditMode = false
    return (
      <div className={classes.root}>
          <div className={classes.container}>
            {!isReady && (
              <Splash showLogo={false} />
            )}
            {isReady && (
              [<Typography key={1} className={classes.titleText} >Users</Typography>,
              <GroupBox key={2} withHeader={false} className={classes.groupBox} cardContent={classes.cardContent}>
                <div className={classes.table}>
                  <div className={classes.flexItemRow} style={{backgroundColor: darken('#f8f8ff', 0.075)}}>
                    <div className={classNames(classes.userNameCol, classes.colHeader)}>Username</div>
                    <div className={classNames(classes.firstNameCol, classes.colHeader)}>First Name</div>
                    <div className={classNames(classes.lastNameCol, classes.colHeader)}>Last Name</div>
                    <div className={classNames(classes.emailCol, classes.colHeader)}>Email</div>
                    <div className={classNames(classes.rolesCol, classes.colHeader)}>Roles</div>
                    <div className={classNames(classes.actionButtonsCol, classes.colHeader)}>&nbsp;</div>
                  </div>
                  {users.map((user, key) => {
                    const isEdit = isRowEdit(user._id)
                    const rowStyle = isEdit ? { padding: 0 } : { padding: 15 }
                    if (isEdit) {
                      isEditMode = isEdit
                    }
                    return (
                      <div key={key} className={classNames(classes.flexItemRow, 'table-row')} style={rowStyle}>

                        {isEdit && (
                          <UserFormEntry onSaveEntry={this._handleOnSaveEntry} onCancelEntry={this._handleOnCancelEntry} user={user} {...rest} />
                        )}
                        {!isEdit && (
                          [
                          <div key={1} className={classes.userNameCol}>{user.username}</div>,
                          <div key={2} className={classes.firstNameCol}>{user.firstname.toLowerCase()}</div>,
                          <div key={3} className={classes.lastNameCol}>{user.lastname.toLowerCase()}</div>,
                          <div key={4} className={classes.emailCol}>{user.email.toLowerCase()}</div>,
                          <div key={5} className={classes.rolesCol}>{user.roles.join(' , ')}</div>,
                          <div key={6} className={classes.actionButtonsCol}>
                            <Button variant="outlined" onClick={this._handleOnClickEdit(user._id)}>Edit</Button>
                            <Button variant="outlined" onClick={this._handleOnClickRemove(user._id)}>Remove</Button>
                          </div>
                          ]
                        )}
                      </div>
                    )
                  })}

                  {!isEditMode && (
                    <div className={classes.flexItemRow} style={{ backgroundColor: '#f8f8ff', justifyContent: 'flex-end', paddingLeft: 0, paddingRight: 0, paddingTop: 10, paddingBottom: 10 }}>
                      {isRowAdd && (
                        <UserFormEntry onSaveEntry={this._handleOnSaveEntry} onCancelEntry={this._handleOnCancelEntry} {...rest} />
                      )}
                      {!isRowAdd && (
                        <Button variant="outlined" onClick={this._handleOnClickAdd} style={{ marginRight: 17 }}>Add</Button>
                      )}
                    </div>
                  )}

                </div>
              </GroupBox>]
            )}
          </div>
      </div>
    )
  }
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withSharedFeatures(withStyles(styles)(UserList))
