import React from 'react'

// @mui/material components
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import CloudIcon from '@mui/icons-material/CloudDownloadOutlined'
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import moment from 'moment'

import BaseComponent from '../_Base'
import withSharedFeatures from '../../views/withSharedFeatures'
import ImageSlider from  '../ImageSlider/ImageSlider'
import EventAttachmentsDownload from '../../components/EventAttachmentsDownload/EventAttachmentsDownload'
import Util from '../../utils/Util'

// jss
import { root } from '../../assets/jss/index'
import { Typography } from '@mui/material'


const styles = (theme) => ({
    root: {
        ...root,
        margin: 0,
        padding: '0px 0px 10px 0px',
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar: theme.mixins.toolbar,
    flexNone: {
        flex: 'none'
    },
    flexAuto: {
        flex: 1,
    },
    detailPanel: {
        flex: 'none',
        display: 'flex',
        margin: '10px 10px 0px',
        justifyContent: 'space-between',
    },
    imageSliderPanel: {
        flex: '1 1 auto',
        height: '100%'
    },
    images: {
        height: 200,
        [theme.breakpoints.down('md')]: {
            height: 100
        },
        [theme.breakpoints.down('lg')]: {
            height: 150
        },
        [theme.breakpoints.up("md")]: {
            height: 200
        }
    },
    eventTitle: {
        fontSize: '100%',
        color: 'white',
        '& .sub-title': {
            fontSize: '80%',
            fontStyle: 'italic',
            color: 'darkgray',
            paddingLeft: 10,
        }
    },
    button: {
        color: 'white',
        textTransform: 'capitalize',
        margin: '0px 0px 0px 10px',
        padding: '2px 5px',
      },
})

class EventDataAndImages extends BaseComponent {

    constructor(props) {
        super(props)
        this.state = {
            anchorEl: null,
            images: [],
        }
    }

    _handleOnClickDownload = (e) => {
        this.setState({
            anchorEl: e.currentTarget,
        })
    }

    _handleOnClickDetails = (e) => {
        const { history, event }  = this.props
        history.push('/eventdetail/' + event._id)
    }

    _handleCloseDownloadPopup = () => {
        this.setState({
            anchorEl: null,
        })
    }

    refreshImages = (cb) => {
        this.setState({
            images: []
        }, () => {
            setTimeout(() => {
                this.setState({
                    images: Util.cloneDeep(this.props.event.images)
                }, () => {
                    cb()
                })
            }, 500)
        })
    }

    componentDidMount() {
        const { displayMode } = this.props
        if (displayMode === 'plain') {
            this.setState({
                images: Util.cloneDeep(this.props.event.images)
            })
        }
        this.props.setRef && this.props.setRef(this)
    }

    render() {
        const { event, classes, displayMode } = this.props
        const { anchorEl } = this.state
        let { images } = event
        
        if (displayMode === 'plain') {
            images = this.state.images
        } 

        const attachments = event ? event.attachments : []
        const openDownloadPopup = Boolean(anchorEl)
        
        return (
            <div className={classes.root}>
                {displayMode === 'full' && 
                    [<div key={1} className={classes.detailPanel}>
                        <div>
                            <Typography className={classes.eventTitle}>{moment.utc(event.datetime).format('MM/DD/YYYY HH:mm:ss')}<span className='sub-title'>{event.name}</span></Typography>
                        </div>
                        <div>
                            <Button variant='outlined' className={classes.button} title='Download CSV' onClick={this._handleOnClickDownload}>
                                <CloudIcon />&nbsp;&nbsp;Download CSV
                            </Button>
                            <Button variant='outlined' className={classes.button} title='View Details' onClick={this._handleOnClickDetails}>
                                <EyeIcon />&nbsp;&nbsp;View Details
                            </Button>
                        </div>
                    </div>,
                    <div key={2} className={classes.imageSliderPanel}>
                        <ImageSlider event={event}>
                            {images && images.map((img, idx) => (
                                <div key={idx} className='img-container'>
                                    <img className={classes.images} src={img.url} alt={img.name} />
                                </div>
                            ))}
                        </ImageSlider>
                    </div>,
                    <EventAttachmentsDownload key={3} attachments={attachments}
                        open={openDownloadPopup}
                        anchorEl={anchorEl}
                        onClose={this._handleCloseDownloadPopup}
                    />]
                }
                {displayMode === 'plain' &&
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}> 
                        {images && images.map((img, idx) => (
                            <div key={idx} className='img-container' style={{marginRight: 10, marginBottom: 10}}>
                                <img className={classes.images} src={img.url} alt={img.name}  />
                            </div>
                        ))}
                    </div>
                }
            </div>
        )
    }
}

EventDataAndImages.propTypes = {
    classes: PropTypes.object.isRequired,
    displayMode: PropTypes.string,
}

EventDataAndImages.defaultProps = {
    displayMode: 'full'
}

export default withSharedFeatures(withStyles(styles)(EventDataAndImages))
