import React from 'react'
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import { darken, lighten } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import indigo from '@mui/material/colors/indigo'
import blue from '@mui/material/colors/blue'
import teal from '@mui/material/colors/teal'
import red from '@mui/material/colors/red'
import orange from '@mui/material/colors/orange'

import GridContainer from "./components/Grid/GridContainer.jsx"
import Splash from './components/Splash/Splash'
import AppState from './managers/AppStateManager'

import BlockUI, { blockUI, unblock } from './components/BlockUI/BlockUI'

const themeDefault = createTheme()

const baseThemeObj = {
  // overrides: {
    // MuiButton: { // Name of the component ⚛ / style sheet
    //   root: { // Name of the rule
    //     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    //     borderRadius: 3,
    //     border: 0,
    //     color: 'white',
    //     height: 48,
    //     padding: '0 30px',
    //     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    //   },
    //}
  // },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    contrastThreshold: 3.1,
    tonalOffset: 0.07,
    primary: indigo,
    secondary: blue,
    common: {
      backgroundLight: lighten('#2196f3', 0.07),
      backgroundMain: '#2196f3',
      backgroundDark: darken('#2196f3', 0.07),
      backgroundContrastText: themeDefault.palette.getContrastText('#2196f3'),
    }
  }
}

let smeThemeObj = {
  // typography: {
  //   useNextVariants: true,
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Helvetica',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  // },
  palette: {
    contrastThreshold: 3.1,
    tonalOffset: 0.07,
    primary: indigo,
    secondary: blue,
    common: {
      backgroundLight: lighten('#2196f3', 0.07),
      backgroundMain: '#2196f3',
      backgroundDark: darken('#2196f3', 0.07),
      backgroundContrastText: themeDefault.palette.getContrastText('#2196f3'),
    }
  }
}

let reviewerThemeObj = {
  // typography: {
  //   useNextVariants: true,
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Helvetica',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  // },
  palette: {
    contrastThreshold: 3.1,
    tonalOffset: 0.07,
    primary: teal,
    secondary: indigo,
    common: {
      backgroundLight: lighten('#2196f3', 0.07),
      backgroundMain: '#2196f3',
      backgroundDark: darken('#2196f3', 0.07),
      backgroundContrastText: themeDefault.palette.getContrastText('#2196f3'),
    }
  }
}

let adminThemeObj = {
  palette: {
    contrastThreshold: 3.1,
    tonalOffset: 0.07,
    primary: red,
    secondary: orange,
    common: {
      backgroundLight: lighten('#f44336', 0.07),
      backgroundMain: '#f44336',
      backgroundDark: darken('#f44336', 0.07),
      backgroundContrastText: themeDefault.palette.getContrastText('#f44336'),
    }
  }
}

smeThemeObj = Object.assign({}, baseThemeObj, smeThemeObj)
reviewerThemeObj = Object.assign({}, baseThemeObj, reviewerThemeObj)
adminThemeObj = Object.assign({}, baseThemeObj, adminThemeObj)

const baseTheme = createTheme((baseThemeObj))
const smeTheme = createTheme((smeThemeObj))
const reviewerTheme = createTheme((reviewerThemeObj))
const adminTheme = createTheme((adminThemeObj))
const themeMap = {
  baseTheme: baseTheme,
  smeTheme: smeTheme,
  reviewerTheme: reviewerTheme,
  adminTheme: adminTheme,
}
const withRoot = (Component) => {
  class WithRoot extends Component {
    constructor(props) {
      super(props)
      this.state = {
        theme: baseTheme,
        isReady: false
      }
    }

    changeTheme = (themeName) => {
      const theme = themeMap[themeName]
      this.setState({
        theme: theme
      })
    }

    componentDidMount() {

      AppState.initialize((error, data) => {

        console.log('DEBUG:UI withRoot AppState.initialize', data)

        if (error) {
          console.log('DEBUG:error', error)
        } else if (data && data.length > 0) {
          setTimeout(() => {
            this.setState({
              isReady: true
            })
          })
        }
      })

    }

    render() {
      const { theme, isReady } = this.state
      return (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            {!isReady &&
              <GridContainer>
                <Splash />
              </GridContainer>
            }

            {isReady &&
              <Component {...this.props}
                changeTheme={this.changeTheme}
                themeMap={themeMap}
                blockUI={blockUI}
                unblock={unblock}
              />
            }

            <BlockUI />
          </ThemeProvider>
        </StyledEngineProvider>
      );
    }
  }
  return WithRoot
}

export default withRoot
