import React, {useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import Util from '../../utils/Util'
import { C_DEFAULTS } from '../../variables/common'

const useStyles = makeStyles({
    root: {
        flex: 2,
        fontSize: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
        overflowX: 'auto',
    },
    container: {
        width: '100%',
        height: 280,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }
})

const options = {
    title: null,
    credits: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    chart: {
        width: 10,
        height: 10,
        type: 'scatter',
        zoomType: 'x',
        animation: false,
    },
    navigator: {
        series: {
            type: 'scatter',
            lineWidth: 1,
            marker: {
                enabled: false
            }
        },
        outlineColor: '#999999',
        maskInside: false
    },
    plotOptions: {
        scatter: {
            lineWidth: 1,
            color: 'rgba(255,69,0, 0.4)',
            marker: {
                fillColor: 'rgba(255,69,0,0.8)',
                radius: 2,
            },
            states: {
                hover: {
                    enabled: true
                }
            },
            dataGrouping: {
                enabled: false,
                smoothed: true,
            },
        }
    },
    xAxis: {
        type: 'datetime',
        title: {
            enabled: true,
            text: 'UTC Date',
        },
        minTickInterval: 1000,
        tickInterval: 1000,
        labels: {
            format: '{value:%S}',
        },
        showFirstLabel: true,
        showLastLabel: true
    },
    yAxis: {
        title: {
            text: 'GLM Reported Lightning Energy (joules)',
        },
        opposite: true,
    },
    legend: {
        enabled: false
    },
    time: {
        useUTC: true
    },
    series: []
}

const optionsTrajectory = {
    title: null,
    credits: {
        enabled: false
    },
    rangeSelector: {
        enabled: false
    },
    chart: {
        width: 10,
        height: 10,
        type: 'scatter',
        zoomType: 'xy',
        animation: false,
    },
    navigator: {
        enabled: false
    },
    plotOptions: {
        scatter: {
            lineWidth: 1,
            color: 'rgba(255,69,0,0.4)',
            marker: {
                fillColor: 'rgba(255,69,0,0.8)',
                radius: 2,
            },
            states: {
                hover: {
                    enabled: true
                }
            },
            dataGrouping: {
                enabled: false,
                smoothed: true,
            },
        }
    },
    tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '',
        // pointFormat: '<span style="color:{point.color}">\u25CF</span> Latitude: {point.y} <br><span style="color:{point.color}">\u25CF</span> Longitude: {point.x}<br/>',
    },
    xAxis: {
        title: {
            text: 'Longitude',
        },
    },
    yAxis: {
        title: {
            text: 'Latitude',
        },
        opposite: true,
    },
    legend: {
        enabled: false
    },
    time: {
        useUTC: true
    },
    series: []
}

const seriesLabelMap = {
    'energy': 'GLM Reported Lightning Energy',
    'latitude': 'Latitude',
    'longitude': 'Longitude'
}

const axisKeys = {
    'EnergyChart': {
        xAxisKey: 'time',
        yAxisKey: 'energy'
    },
    'TrajectoryChart': {
        xAxisKey: 'longitude',
        yAxisKey: 'latitude'
    }

}

const generateData = (chartType, geoData) => {
    const { xAxisKey, yAxisKey } = axisKeys[chartType]
    let data = geoData.map((data, index) => {
        const dt = (chartType === 'EnergyChart') ? {
            x: data[xAxisKey],
            y: data[yAxisKey],
            time: data.time
        } : {
                x: data.location.coordinates[0],
                y: data.location.coordinates[1],
                time: data.time
            }
        return dt
    })
    data = Util.orderBy(data, ['time'], ['asc'])
    return data
}


const generateSeries = (chartType, geoData) => {
    const { yAxisKey } = axisKeys[chartType]
    const seriesData = generateData(chartType, geoData)
    return {
        type: 'scatter',
        name: seriesLabelMap[yAxisKey],
        showInLegend: false,
        data: seriesData,
        turboThreshold: 0,
        tooltip: {
            pointFormatter: function () {
                let t = `Latitude: ${this.y.toFixed(6)}<br />
                         Longitude: ${this.x.toFixed(6)}<br />
                         Date: ${moment.utc(this.time).format(C_DEFAULTS.C_DATE_FORMAT_STRING)} UTC`
                if (chartType === 'EnergyChart') {
                    t = `GLM Reported Lightning Energy: ${this.y} J<br>Date: ${moment.utc(this.x).format(C_DEFAULTS.C_DATE_FORMAT_STRING)} UTC`
                }
                return t
            }
        },
    }
}

const generateChartOptions = (chartType, geoData, dimension) => {
    const chartOptions = chartType === 'EnergyChart' ? Util.cloneDeep(options) : Util.cloneDeep(optionsTrajectory)
    const data = generateSeries(chartType, geoData)
    chartOptions.series = [data]
    chartOptions.chart = Object.assign({}, chartOptions.chart, {
        width: dimension.width,
        height: dimension.height
    })
    return chartOptions
}

function EventFormChart({chartType, geoData}) {
    const classes = useStyles()
    const containerRef = useRef()
    const chartRef = useRef()

    const [dimension, setDimension] = useState({height: 300, width: 400})
    // const [chartOptions, setChartOptions] = useState(generateChartOptions(chartType, geoData, dimension))
    const [chartOptions, setChartOptions] = useState(null)
    const [gData, setGData] = useState([])

    const localChartContainerStyle = chartType === 'EnergyChart' ? { height: 500 } : {}

    useEffect(() => {
        const _chart = chartRef.current
        const _container = containerRef.current

        // console.log('DEBUG: EventFormChart - useEffect()', chartType, geoData)
        const rect = _container.getBoundingClientRect()
        if (!Util.isEqualByValue(gData, geoData)) {
            const dim = {height: rect.height, width: rect.width}
            const cOpt = generateChartOptions(chartType, geoData, dim)
            // console.log('DEBUG: EventFormChart - useEffect() - options', rect, chartOptions)
            setChartOptions(cOpt)
            setGData(geoData)
            setDimension(dim)
            return
        }

        const updateChartDimension = () => {
            const chart = _chart.chart
            if (chart) {
                const rect = _container.getBoundingClientRect()
                chart.setSize(rect.width, rect.height)
                if (dimension.width !== rect.width || dimension.height !== rect.height) {
                    const dim = {height: rect.height, width: rect.width}
                    setDimension(dim)
                }
            }
        }
        
        // updateChartDimension()
        if (chartType === 'EnergyChart') {
            _chart.chart.xAxis[0].setExtremes(null, null)
        } else if (chartType === 'TrajectoryChart') {
            _chart.chart.xAxis[0].setExtremes(null, null)
            _chart.chart.yAxis[0].setExtremes(null, null)
        }

        const handleWindowResize = () => {
            updateChartDimension()
        }
        window.addEventListener("resize", handleWindowResize)
        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [chartOptions, chartType, geoData, gData, dimension])

    return (
        <div className={classes.root}>
            <div ref={containerRef} className={classes.container} style={localChartContainerStyle}>
                {chartType === 'EnergyChart' &&
                    <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={chartOptions}
                        constructorType={'stockChart'}
                    />
                }
                {chartType === 'TrajectoryChart' &&
                    <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                }
            </div>
        </div>
    )
}

EventFormChart.propTypes = {
    chartType: PropTypes.string
}

EventFormChart.defaultProps = {
    chartType: 'EnergyChart'
}
export default EventFormChart
